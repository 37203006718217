import { moonPayDefaultTheme } from '@moonpay-widget/ui-kit';
import {
  ThemeConfigV1Base,
  ThemeConfigV1Response,
} from '@moonpay-widget/ui-kit/src/theming/providers/ThemeProvider/types';

const getThemeConfig = async ({
  themeId,
}: {
  themeId?: string | null;
}): Promise<ThemeConfigV1Response | ThemeConfigV1Base> => {
  if (!themeId) {
    return moonPayDefaultTheme;
  }

  try {
    // we may want to use `process.env.REACT_APP_MOONPAY_API_REST_URL` if theme is not hosted on MoonPay servers
    // however we'll always use the same endpoint for now since all themes are hosted on MoonPay servers
    const themeBaseUrl = 'https://api.moonpay.com';
    const fullEndpointUrl = `${themeBaseUrl}/v1/account-theme/${themeId}`;

    const restClient = {
      get: async <T>() => {
        const response = await fetch(fullEndpointUrl);
        const json = await response.json();
        return json as T;
      },
    };

    // If the request is successful we will return that theme config
    const themeConfig = await restClient.get<ThemeConfigV1Response>();

    // TODO: add analytics like the widget

    return themeConfig;
  } catch (error) {
    // TODO: add analytics like the widget

    return moonPayDefaultTheme;
  }
};

export default getThemeConfig;
