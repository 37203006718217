// eslint-disable-next-line max-classes-per-file
import { Expose, Type } from 'class-transformer';
import { WalletNetwork } from '@moonpay/login-common';

export interface IGetWalletsResponse {
  wallets: WalletDetails[];
}

class WalletDetails {
  @Expose()
  public readonly address!: string;

  @Expose()
  public readonly network!: WalletNetwork;
}

export class GetWalletsResponse {
  @Expose()
  @Type(() => WalletDetails)
  public readonly wallets!: WalletDetails[];

  constructor(data: IGetWalletsResponse) {
    Object.assign(this, data);
  }
}
