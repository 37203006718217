import { MESSAGE_REQUEST_PARAMS_METADATA_KEY } from 'src/v2/lib/constants';

export const requestHasBody = (target: any, propertyKey: string | symbol) => {
  const bodyClass = Reflect.getMetadata(
    MESSAGE_REQUEST_PARAMS_METADATA_KEY,
    target,
    propertyKey,
  );

  return typeof bodyClass !== 'undefined';
};
