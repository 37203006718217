import { WalletNetwork } from '@moonpay/login-common';
import { EvmWalletErrors, MoonPayWalletError } from 'src/v2/common/errors';
import { EVM_CHAINS, SupportedEvmChain } from 'src/v2/evm/config';
import { EvmChainSpec } from 'src/v2/evm/types';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

export class EvmChainService {
  private readonly network = WalletNetwork.Ethereum;

  private readonly chainIdToChainNameMap: Record<number, string> = {
    1: 'ethereum',
    11155111: 'sepolia',
    80002: 'amoy',
    10016: 'localhost',
  };

  constructor(private readonly walletStorage: WalletStorage) {}

  public getChainNameByChainId(chainId: number): string {
    const chainIdIsSupport = Object.keys(this.chainIdToChainNameMap).includes(
      chainId.toString(),
    );

    if (!chainIdIsSupport) {
      throw new MoonPayWalletError(
        EvmWalletErrors.INVALID_EVM_CHAIN_ID(chainId),
      );
    }

    return this.chainIdToChainNameMap[chainId];
  }

  public getChainByChainId(chainId: number): EvmChainSpec {
    const chainName = this.chainIdToChainNameMap[chainId];
    if (!chainName) {
      throw new MoonPayWalletError(
        EvmWalletErrors.INVALID_EVM_CHAIN_ID(chainId),
      );
    }

    return EVM_CHAINS[chainName as SupportedEvmChain];
  }

  public updateActiveChainId(chainId: number): number {
    this.walletStorage.activeChainId.setActiveChainIdByNetwork(
      this.network,
      chainId,
    );
    return chainId;
  }
}
