export enum SegmentTrackEvent {
  approveConnectionCancelled = 'Approve Connection Cancelled',
  approveConnectionCompleted = 'Approve Connection Completed',
  approveTransactionCancelled = 'Approve Transaction Cancelled',
  approveTransactionCompleted = 'Approve Transaction Completed',
  signatureRequestApproved = 'Signature Request Approved',
  signatureRequestCancelled = 'Signature Request Cancelled',
  signTypedDataCancelled = 'Sign Typed Data Cancelled',
  signTypedDataSigned = 'Sign Typed Data Signed',
  switchNetworkCancelled = 'Switch Network Cancelled',
  switchNetworkCompleted = 'Switch Network Completed',
}
