// seaport documentation: https://docs.opensea.io/reference/seaport-overview#order-fulfillment

export const OPENSEA_FULFILL_BASIC_ORDER = 'fulfillBasicOrder';

// this is used when opensea is requesting a signature to approve a purchase
export const OPENSEA_FULFILL_ADVANCED_ORDER = 'fulfillAdvancedOrder';

// TODO: haven not figured out in which situation opensea sends this type
export const OPENSEA_FULFILL_ORDER = 'fulfillOrder';

export const OPENSEA_FULFILL_AVAILABLE_ADVANCED_ORDER =
  'fulfillAvailableAdvancedOrders';

export const SEAPORT_CONTRACT_1_1 =
  '0x00000000006c3852cbEf3e08E8dF289169EdE581';

export const SEAPORT_CONTRACT_1_2 =
  '0x00000000000006c7676171937C444f6BDe3D6282';

export const SEAPORT_CONTRACT_1_3 =
  '0x0000000000000aD24e80fd803C6ac37206a45f15';

export const SEAPORT_CONTRACT_1_4 =
  '0x00000000000001ad428e4906aE43D8F9852d0dD6';
