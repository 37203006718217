import {
  ValidationArguments,
  ValidationOptions,
  isString,
  registerDecorator,
} from 'class-validator';

export function IsPositiveBigIntString(
  validationOptions?: ValidationOptions & { allowZero?: boolean },
) {
  return function validate(object: any, propertyName: string) {
    registerDecorator({
      name: 'IsPositiveBigIntString',
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(value: any) {
          if (!value) {
            return false;
          }

          if (!isString(value)) {
            return false;
          }

          try {
            const bigintValue = BigInt(value);
            return validationOptions?.allowZero
              ? bigintValue >= 0
              : bigintValue > 0;
          } catch (error) {
            return false;
          }
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a valid a string representation of an integer`;
        },
      },
    });
  };
}
