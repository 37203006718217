import StorageUtils from 'src/utils/storage';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import logger from '../utils/logger';
import { QueryInfo } from './queries';

const MOONPAY_API_GRAPHQL_URL = process.env
  .REACT_APP_MOONPAY_API_GRAPHQL_URL as string;

const prepareBaseRequest = ({
  csrfToken,
  customerToken,
}: {
  csrfToken: string;
  customerToken?: string;
}) => {
  const headers: {
    'Content-Type': string;
    'X-CSRF-Token'?: string;
    Authorization?: string;
  } = {
    'Content-Type': 'application/json',
  };

  if (customerToken) {
    headers.Authorization = `Bearer ${customerToken}`;
  } else if (csrfToken) {
    headers['X-CSRF-Token'] = csrfToken;
  }

  return {
    headers,
    method: 'POST',
    credentials: 'include',
  } as Pick<RequestInit, 'method' | 'headers' | 'credentials'>;
};

const stringifyGraphQLQuery = (query: string) =>
  JSON.stringify({
    query,
  });

const executeGraphQLQuery = async <TData>(
  queryInfo: QueryInfo<[]>,
): Promise<{ data: TData; error: any }> => {
  const customerToken = await StorageUtils.get('customerToken');
  const csrfToken = (await WalletHelpers.getCsrfToken()) || '';
  const request = {
    ...prepareBaseRequest({ csrfToken, customerToken }),
    body: stringifyGraphQLQuery(queryInfo.query([])),
  };

  const response = await fetch(MOONPAY_API_GRAPHQL_URL, request);
  const responseJson = await response.json();

  const error = responseJson?.errors?.[0]?.message;
  const data = !error ? queryInfo.getter(responseJson.data as TData) : null;

  if (error) {
    logger.error('A GraphQL Error has occurred whilst executing a request', {
      error,
      queryInfo,
      request,
      responseJson,
    });
  }

  return {
    data,
    error,
  };
};

export default executeGraphQLQuery;
