import { Expose } from 'class-transformer';

export interface IBitcoinTransactionResponse {
  transactionHash: string;
}

export class BitcoinTransactionResponse {
  @Expose()
  public readonly transactionHash!: string;

  constructor(data: IBitcoinTransactionResponse) {
    Object.assign(this, data);
  }
}
