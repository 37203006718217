import { Container } from '@moonpay-widget/ui-kit';
import { type FC, type PropsWithChildren } from 'react';
import ColorSchemeProvider from 'src/contexts/ColorScheme/ColorSchemeProvider';

const PromptSheet: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <ColorSchemeProvider>
      <Container
        component="div"
        padding="0"
        showFooter
        style={{
          minHeight: '0px',
          maxWidth: '400px',
        }}
        className="!rounded-[24px] text-label-primary bg-system-background-primary w-full mx-auto"
      >
        {children}
      </Container>
    </ColorSchemeProvider>
  );
};

export default PromptSheet;
