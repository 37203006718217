import { Expose, Type } from 'class-transformer';

export interface IEvmSendTransactionResponse {
  txHash: `0x${string}`;
}

export class EvmSendTransactionResponse {
  @Type(() => String)
  @Expose()
  public readonly txHash!: `0x${string}`;

  constructor(data: Partial<IEvmSendTransactionResponse>) {
    Object.assign(this, data);
  }
}
