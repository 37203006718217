import { Label, Text } from '@moonpay-widget/ui-kit';
import { FC } from 'react';
import { formatTransactionCost } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { formatWalletAddress } from 'src/utils/formatters';

export type GenericSellTransactionProps = {
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  to: string;
  from: string;
  networkFee: string;
  currencyCode: string;
  receivingFiatAmount?: string;
  receivingFiatCurrencyCode?: string;
  receivingFiatTo?: string;
};

const getDecimalPlaces = (currencyCode: string) => {
  switch (currencyCode) {
    case 'ETH':
    case 'BTC':
      return 8;
    default:
      return 5;
  }
};

export const GenericSellTransaction: FC<GenericSellTransactionProps> = ({
  from,
  value,
  networkFee,
  currencyCode,
  receivingFiatAmount,
  receivingFiatCurrencyCode,
  receivingFiatTo,
}) => {
  const decimalPlaces = getDecimalPlaces(currencyCode);
  return (
    <>
      <div className="flex items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
        <Text as="div" className="flex my-4" variant="body">
          <div className="float-left w-1/2 text-subhead font-light">
            You are selling
          </div>
          <div className="float-right w-1/2 text-right text-subhead font-light">
            <b>{`${value} ${currencyCode}`}</b>
          </div>
        </Text>
        {receivingFiatAmount ? (
          <Text as="div" className="flex my-45" variant="body">
            <div className="float-left w-1/2 text-subhead font-light">
              You are receiving
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">
              <b>
                {receivingFiatAmount} {receivingFiatCurrencyCode}
              </b>
            </div>
          </Text>
        ) : null}
        <Text as="div" className="flex my-4" variant="body">
          <div className="float-left w-1/2 text-subhead font-light">
            Network fee
          </div>
          <div className="float-right w-1/2 text-right text-subhead font-light">
            <b>
              {formatTransactionCost(networkFee, decimalPlaces)} {currencyCode}
            </b>
          </div>
        </Text>
        <Text as="div" className="flex my-4" variant="body">
          <div className="float-left w-1/2 text-subhead font-light">From</div>
          <div className="float-right w-1/2 text-right text-subhead font-light">
            <b>
              MoonPay BTC (
              {formatWalletAddress({ address: from, fullAddress: false })})
            </b>
          </div>
        </Text>
        {receivingFiatTo ? (
          <Text as="div" className="flex my-4" variant="body">
            <div className="float-left w-1/2 text-subhead font-light">To:</div>
            <div className="float-right w-1/2 text-right text-subhead font-light">
              <b>{receivingFiatTo}</b>
            </div>
          </Text>
        ) : null}
      </div>
    </>
  );
};
