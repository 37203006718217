import { WalletNetwork } from '@moonpay/login-common';
import environment from 'src/environment';
import { ChainsData } from 'src/wallet/storage/ChainsStorage';
import { WalletHelpers } from '../helpers/WalletHelpers';

const {
  ethereumMainnetRpcProviderUrl,
  polygonMainnetRpcProviderUrl,
  polygonTestnetRpcProviderUrl,
  ethereumSepoliaRpcProviderUrl,
} = environment;

const DEFAULT_CHAINS: Partial<ChainsData> = {
  [WalletNetwork.Ethereum]: {
    '1': {
      chainId: '0x1',
      chainName: 'Ethereum Mainnet',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [ethereumMainnetRpcProviderUrl],
      blockExplorerUrls: ['https://etherscan.io'],
    },
    '137': {
      chainId: '0x89',
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [polygonMainnetRpcProviderUrl],
      blockExplorerUrls: ['https://polygonscan.com'],
    },
    '80001': {
      chainId: '0x13881',
      chainName: 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [polygonTestnetRpcProviderUrl],
      blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    },
    '11155111': {
      chainId: '0xaa36a7',
      chainName: 'Sepolia',
      nativeCurrency: {
        name: 'Sepolia Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [ethereumSepoliaRpcProviderUrl],
      blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    },
  },
  [WalletNetwork.Bitcoin]: {
    '0': {
      chainId: '0x0',
      chainName: 'Bitcoin Mainnet',
      nativeCurrency: {
        name: 'Bitcoin',
        symbol: 'BTC',
        decimals: 8,
      },
      rpcUrls: [],
    },
    '1': {
      chainId: '0x1',
      chainName: 'Bitcoin Testnet',
      nativeCurrency: {
        name: 'Bitcoin',
        symbol: 'BTC',
        decimals: 8,
      },
      rpcUrls: [],
    },
  },
  [WalletNetwork.Solana]: {
    '900': {
      chainId: '0x384',
      chainName: 'Solana Mainnet',
      nativeCurrency: {
        name: 'Solana',
        symbol: 'SOL',
        decimals: 9,
      },
      rpcUrls: [],
    },
    '901': {
      chainId: '0x385',
      chainName: 'Solana Devnet',
      nativeCurrency: {
        name: 'Solana',
        symbol: 'DSOL',
        decimals: 9,
      },
      rpcUrls: [],
    },
  },
};

if (WalletHelpers.isLocal() || WalletHelpers.isCI()) {
  DEFAULT_CHAINS[WalletNetwork.Ethereum] = {
    ...DEFAULT_CHAINS[WalletNetwork.Ethereum],
    '31337': {
      chainId: '0x7A69',
      chainName: 'EVM Emulator',
      nativeCurrency: {
        name: 'Local Ether',
        symbol: 'LETH',
        decimals: 18,
      },
      rpcUrls: ['http://127.0.0.1:8545'],
      blockExplorerUrls: ['https://www.youtube.com/watch?v=dQw4w9WgXcQ'],
    },
  };
}

export default DEFAULT_CHAINS;
