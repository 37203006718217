import { Icon } from '@moonpay-widget/ui-kit';
import { ChevronRightIcon } from '@moonpay-widget/ui-kit/src/icons';

const ActivityDetailRow = () => {
  return (
    <div className="flex flex-col items-start py-1 w-full cursor-pointer">
      <div
        className="flex flex-col w-full p-4 rounded-xl"
        style={{
          background: '#00000014',
        }}
      >
        <div className="flex justify-between w-full ">
          {/* Partner Icon */}
          <div className="flex space-x-2 items-center">
            <div
              className="w-8 h-8 mr-2 rounded-lg"
              style={{ backgroundColor: '#C38AFF33' }}
            />
            <div>Muppeth</div>
          </div>
          <div className="flex items-center space-x-2">
            2
            <Icon icon={ChevronRightIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ActivityDetails = () => {
  return (
    <div className="flex flex-col mb-2 space-y-1">
      <div className="flex flex-col items-start py-1 cursor-pointer">
        <div className="flex flex-col justify-start w-full">
          <ActivityDetailRow />
          <ActivityDetailRow />
        </div>
      </div>
    </div>
  );
};
