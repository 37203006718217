import {
  ClipboardListIcon,
  Icon,
  SignatureApprovalIcon,
  Text,
  TransactionApprovalIcon,
  WalletAddressIcon,
  WhiteCheckGreenCircle,
} from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';

const ConnectWallet: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        variant="subhead"
        className="justify-center text-left text-label-secondary mt-5 mb-2"
      >
        {t(strings.prompts.connectionRequest.subTitle)}
      </Text>
      <div className="px-4 pt-6 pb-2 mb-8 bg-system-background-secondary rounded-xl grid gap-y-4">
        <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
          <div className="flex justify-left">
            <Icon icon={WalletAddressIcon} className="w-5 h-5" />
            <Text variant="footnote" className="px-2">
              {t(strings.prompts.connectionRequest.permissions.viewAddress)}
            </Text>
          </div>
          <div className="flex ">
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>
        <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
          <div className="flex justify-left">
            <Icon icon={ClipboardListIcon} className="w-5 h-5" />
            <Text variant="footnote" className="px-2">
              {t(strings.prompts.connectionRequest.permissions.viewBalance)}
            </Text>
          </div>
          <div className="flex ">
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>
        <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
          <div className="flex justify-left">
            <Icon icon={TransactionApprovalIcon} className="w-5 h-5" />
            <Text variant="footnote" className="px-2">
              {t(
                strings.prompts.connectionRequest.permissions
                  .approveTransactions,
              )}
            </Text>
          </div>
          <div className="flex ">
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>
        <div className="pb-4 flex flex-row  justify-between">
          <div className="flex justify-left">
            <Icon icon={SignatureApprovalIcon} className="w-5 h-5" />
            <Text variant="footnote" className="px-2">
              {t(
                strings.prompts.connectionRequest.permissions.approveSignatures,
              )}
            </Text>
          </div>
          <div className="flex ">
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectWallet;
