import { ErrorManager } from 'src/utils/errorManager';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import logger from '../../../utils/logger';

const errorManager = new ErrorManager(__filename);

export enum BitcoinNetwork {
  mainnet = 'mainnet',
  testnet = 'testnet',
  regtest = 'regtest',
}

export enum AddressType {
  p2pkh = 'p2pkh',
  p2sh = 'p2sh',
  p2wpkh = 'p2wpkh',
  p2wsh = 'p2wsh',
  p2tr = 'p2tr',
}

export interface AddressInfo {
  bech32: boolean;
  network: BitcoinNetwork;
  address: string;
  type: AddressType;
}

export const addressTypes: {
  [key: number]: { type: AddressType; network: BitcoinNetwork };
} = {
  0x00: {
    type: AddressType.p2pkh,
    network: BitcoinNetwork.mainnet,
  },

  0x6f: {
    type: AddressType.p2pkh,
    network: BitcoinNetwork.testnet,
  },

  0x05: {
    type: AddressType.p2sh,
    network: BitcoinNetwork.mainnet,
  },

  0xc4: {
    type: AddressType.p2sh,
    network: BitcoinNetwork.testnet,
  },
};

function isBTCAddressValid(address: string, chainId: number): boolean {
  try {
    switch (chainId) {
      case 0:
        // Mainnet
        return WalletHelpers.isValidBTCAddress(address, BitcoinNetwork.mainnet);
      case 1:
        // Testnet
        return WalletHelpers.isValidBTCAddress(address, BitcoinNetwork.testnet);
      default:
        throw errorManager.getClientError(
          'isBTCAddressValid',
          `Invalid chainId`,
          { address, chainId },
        );
    }
  } catch (error) {
    logger.info('Error validating BTC address', {
      address,
      chainId,
    });

    return false;
  }
}

// chainId can only be 0 or 1
function isSegWitAddress(address: string, chainId: number): boolean {
  const isMainnet = chainId === 0;
  const isSegWitMainnet =
    address.startsWith('3') || // p2sh
    address.startsWith('bc1'); // bech32
  const isSegWitTestnet =
    address.startsWith('2') || // p2sh
    address.startsWith('tb1'); // bech32

  return isMainnet ? isSegWitMainnet : isSegWitTestnet;
}

export { isBTCAddressValid, isSegWitAddress };
