import {
  AccessCurrencyRequestIcon,
  SendAssetIcon,
} from '@moonpay-widget/ui-kit';
import Erc20Send from '../components/Erc20Send';
import {
  Erc20TransactionType,
  type Erc20Actions,
  type Erc20Approval,
  type Erc20Transfer,
} from '../erc20';
import { formatErc20Value } from '../utils';
import type { SendTransactionPromptReturnType } from './types';

const erc20TransferPrompt = ({
  action,
  networkFee,
  nativeCurrencySymbol,
}: {
  action: Erc20Transfer;
  networkFee: string;
  nativeCurrencySymbol: string;
}): SendTransactionPromptReturnType => {
  const props = {
    currencyCode: action.token.symbol,
    networkFee,
    to: action.to,
    value: formatErc20Value(action.token, action.value),
    token: action.token,
    nativeCurrency: nativeCurrencySymbol,
  };

  return {
    title: 'Send Tokens',
    component: <Erc20Send {...props} />,
    partnerLogo: SendAssetIcon,
    aboveButtonsText:
      'Please review the above before confirming as transactions cannot be reversed.',
    approveButtonText: 'Confirm and send',
  };
};

const erc20ApprovalPrompt = ({
  action,
  networkFee,
  nativeCurrencySymbol,
}: {
  action: Erc20Approval;
  networkFee: string;
  nativeCurrencySymbol: string;
}): SendTransactionPromptReturnType => {
  const props = {
    currencyCode: action.token.symbol,
    networkFee,
    to: action.spender,
    spender: action.spender,
    value: formatErc20Value(action.token, action.value),
    all:
      action.value.toHexString() ===
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
    token: action.token,
    nativeCurrency: nativeCurrencySymbol,
  };

  return {
    title: `Allow access to your ${action.token.name}`,
    message:
      'By granting permission, you are allowing this contract to access and transfer your funds.',
    approveButtonText: 'Allow access',
    component: <Erc20Send {...props} />,
    partnerLogo: AccessCurrencyRequestIcon,
    aboveButtonsText:
      'Only if you fully understand and trust the requesting site',
  };
};

export const erc20TransactionPrompt = (
  action: Erc20Actions,
  args: { networkFee: string; nativeCurrencySymbol: string },
): SendTransactionPromptReturnType | null => {
  if (action.type === Erc20TransactionType.TRANSFER) {
    return erc20TransferPrompt({ action, ...args });
  }
  if (action.type === Erc20TransactionType.APPROVAL) {
    return erc20ApprovalPrompt({ action, ...args });
  }
  return null;
};
