import { WalletNetwork } from '@moonpay/login-common';
import { FunctionComponent, SVGProps, useEffect, useState } from 'react';
import { MoreActionsPillBody } from 'src/messages/walletProxy/methods/openPill/components/MoreActions';
import PillBody from 'src/messages/walletProxy/methods/openPill/components/PillBody';
import {
  MenuItemHeader,
  PillHeader,
} from 'src/messages/walletProxy/methods/openPill/components/PillHeader';

import { Wallet as EthersWallet } from 'ethers';
import { SwitchNetworkPillBody } from 'src/messages/walletProxy/methods/openPill/components/SwitchNetwork';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

export type PillProps = {
  userWalletInfo: {
    address: string;
    balance: string;
    chainId: number;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
  };
  initialView?: PillView;
  walletStorage: WalletStorage;
  wallet: EthersWallet;
  network: WalletNetwork;
  origin: string;
};

export enum PillView {
  MAIN,
  SWITCH_NETWORK,
  MORE_ACTIONS,
}

const Pill: FunctionComponent<PillProps> = ({
  userWalletInfo: { address, balance, chainId, nativeCurrency },
  initialView = PillView.MAIN,
  walletStorage,
  wallet,
  origin,
  network,
}) => {
  const [view, setView] = useState<PillView>(initialView);
  const [networkLogo, setNetworkLogo] = useState<
    FunctionComponent<SVGProps<SVGSVGElement>>
  >(getChainIcon(network, String(chainId)));

  useEffect(() => {
    setView(PillView.MAIN);
  }, []);

  const changeView = (newView: PillView) => {
    setView(newView);
  };

  const changeNetworkLogo = (newChainId: number) => {
    const icon = getChainIcon(network, String(newChainId));
    setNetworkLogo(icon);
  };

  switch (view) {
    case PillView.MAIN:
      return (
        <>
          <PillHeader
            address={address}
            currentNetwork={chainId}
            currentNetworkLogo={networkLogo}
            changeViewCallback={changeView}
          />

          <PillBody balance={balance} nativeCurrency={nativeCurrency} />
        </>
      );
    case PillView.SWITCH_NETWORK:
      return (
        <>
          <MenuItemHeader
            changeViewCallback={changeView}
            title="Change Network"
          />
          <SwitchNetworkPillBody
            {...{
              walletStorage,
              wallet,
              origin,
              network,
              onNetworkChange: changeNetworkLogo,
            }}
          />
        </>
      );
    case PillView.MORE_ACTIONS:
      return (
        <>
          <MenuItemHeader
            changeViewCallback={changeView}
            title="Change Network"
          />
          <MoreActionsPillBody
            {...{
              walletStorage,
              wallet,
              origin,
              onNetworkChange: changeNetworkLogo,
            }}
          />
        </>
      );
    default:
      return null;
  }
};

export default Pill;
