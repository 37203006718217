import {
  ClipboardListIcon,
  ConnectionRequestIcon,
  Icon,
  SignatureApprovalIcon,
  Text,
  TransactionApprovalIcon,
  WalletAddressIcon,
  WhiteCheckGreenCircle,
} from '@moonpay-widget/ui-kit';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import PromptFooter from './prompt-footer';

export interface ApproveConnectionProps {
  onApprove: () => void;
  onReject: () => void;
}

export const ApproveConnectionPrompt: FC<ApproveConnectionProps> = ({
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={ConnectionRequestIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {t(strings.prompts.connectionRequest.title)}
        </Text>

        <Text
          variant="subhead"
          className="justify-center text-left text-label-secondary mt-5 mb-2"
        >
          {t(strings.prompts.connectionRequest.subTitle)}
        </Text>

        <div className="px-4 pt-6 pb-2 mb-8 bg-system-background-secondary rounded-xl grid gap-y-4">
          <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
            <div className="flex justify-left">
              <Icon icon={WalletAddressIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(strings.prompts.connectionRequest.permissions.viewAddress)}
              </Text>
            </div>
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
          <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
            <div className="flex justify-left">
              <Icon icon={ClipboardListIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(strings.prompts.connectionRequest.permissions.viewBalance)}
              </Text>
            </div>
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
          <div className="pb-4 flex flex-row  justify-between border-b border-divider-nonOpaque">
            <div className="flex justify-left">
              <Icon icon={TransactionApprovalIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(
                  strings.prompts.connectionRequest.permissions
                    .approveTransactions,
                )}
              </Text>
            </div>
            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
          <div className="pb-4 flex flex-row  justify-between">
            <div className="flex justify-left">
              <Icon icon={SignatureApprovalIcon} className="w-5 h-5" />
              <Text variant="footnote" className="px-2">
                {t(
                  strings.prompts.connectionRequest.permissions
                    .approveSignatures,
                )}
              </Text>
            </div>

            <Icon
              icon={WhiteCheckGreenCircle}
              className="w-5 h-5 flex items-end"
            />
          </div>
        </div>

        <PromptFooter
          approveButtonText={t(
            strings.prompts.connectionRequest.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.connectionRequest.footer.cancelButtonText,
          )}
          aboveButtonText={t(strings.prompts.connectionRequest.footer.title)}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
