import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';

const encryption = {
  async decrypt({
    encryptedPayload,
    key,
    iv,
  }: {
    encryptedPayload: string;
    key: string;
    iv: Buffer;
  }) {
    const ivHex = iv.toString('hex');
    const encPayloadBuffer = Buffer.from(encryptedPayload, 'hex');
    const encPayloadBase64 = encPayloadBuffer.toString('base64');

    const decrypted = CryptoJS.AES.decrypt(
      encPayloadBase64,
      CryptoJS.enc.Hex.parse(key),
      {
        iv: CryptoJS.enc.Hex.parse(ivHex),
      },
    );

    return Buffer.from(decrypted.toString(), 'hex');
  },

  async encrypt({
    payload,
    key,
    iv,
  }: {
    payload: string;
    key: Buffer;
    iv: Buffer;
  }) {
    const ivHex = iv.toString('hex');
    const encryptionKeyHex = key.toString('hex');
    const encrypted = CryptoJS.AES.encrypt(
      payload,
      CryptoJS.enc.Hex.parse(encryptionKeyHex),
      {
        iv: CryptoJS.enc.Hex.parse(ivHex),
      },
    );

    return Buffer.from(encrypted.toString(), 'base64');
  },
};

export default encryption;
