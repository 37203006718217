import {
  personalSignExecute,
  personalSignPrompt,
  personalSignReject,
  personalSignValidate,
} from 'src/messages/walletProxy/methods/personalSign/personalSign';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const personalSign: MethodImplementation = {
  validate: personalSignValidate,
  prompt: personalSignPrompt,
  execute: personalSignExecute,
  reject: personalSignReject,
};

export { personalSign };
