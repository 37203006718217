import {
  sendTransactionExecute,
  sendTransactionPrompt,
  sendTransactionReject,
} from 'src/messages/walletProxy/methods/sendTransaction/sendTransaction';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';
import { sendTransactionValidate } from './sendTransactionValidate';

const sendTransaction: MethodImplementation = {
  validate: sendTransactionValidate,
  prompt: sendTransactionPrompt,
  execute: sendTransactionExecute,
  reject: sendTransactionReject,
};

export { sendTransaction };
