import { WalletNetwork } from '@moonpay/login-common';
import { SolanaNetworkEnvironment } from 'src/v2/solana/types';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

export class SolanaNetworkEnvironmentManager {
  private readonly network = WalletNetwork.Solana;

  private readonly networkEnvironmentToChainIdMapping = {
    [SolanaNetworkEnvironment.Mainnet]: 900,
    [SolanaNetworkEnvironment.Devnet]: 901,
    [SolanaNetworkEnvironment.Local]: 902,
  };

  constructor(private readonly walletStorage: WalletStorage) {}

  public getChainIdByNetworkEnvironment(
    networkEnvironment: SolanaNetworkEnvironment,
  ): number {
    return this.networkEnvironmentToChainIdMapping[networkEnvironment];
  }

  public updateActiveChainId(
    networkEnvironment: SolanaNetworkEnvironment,
  ): number {
    const chainId = this.getChainIdByNetworkEnvironment(networkEnvironment);
    this.walletStorage.activeChainId.setActiveChainIdByNetwork(
      this.network,
      chainId,
    );

    return chainId;
  }

  private getActiveChainId(): number {
    return this.walletStorage.activeChainId.getActiveChainIdByNetwork(
      this.network,
    );
  }

  public getActiveNetworkEnvironment(
    defaultNetworkEnvironment: SolanaNetworkEnvironment = SolanaNetworkEnvironment.Devnet,
  ): SolanaNetworkEnvironment {
    const activeChainId = this.getActiveChainId();
    const networkEnvironment = (
      Object.keys(
        this.networkEnvironmentToChainIdMapping,
      ) as SolanaNetworkEnvironment[]
    ).find(
      (env) => this.networkEnvironmentToChainIdMapping[env] === activeChainId,
    );
    return networkEnvironment ?? defaultNetworkEnvironment;
  }
}
