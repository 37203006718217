import { IsPositiveBigIntString } from '../../common/decorators/is-positive-bigint-string';
import { IsSolanaAddress } from '../decorators/is-solana-address';

export class SolanaTransferTransactionRequest {
  @IsSolanaAddress()
  public readonly toWalletAddress!: string;

  @IsPositiveBigIntString()
  public readonly value!: string;
}
