import * as web3 from '@solana/web3.js';
import {
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from 'class-validator';

export function IsSolanaAddress(validationOptions?: ValidationOptions) {
  return function validate(object: any, propertyName: string) {
    registerDecorator({
      name: 'isSolanaAddress',
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(value: any) {
          if (!value) {
            return false;
          }

          try {
            const publicKey = new web3.PublicKey(value);
            return !!publicKey;
          } catch (error) {
            return false;
          }
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a valid Solana wallet address`;
        },
      },
    });
  };
}
