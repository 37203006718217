import * as Sentry from '@sentry/browser';
import logger from '../logger';

interface LoadFontProps {
  fontFamily?: string | null;
  fontUrl?: string | null;
}

export const loadFont = async ({ fontFamily, fontUrl }: LoadFontProps) => {
  if (!fontFamily || !fontUrl) {
    // Fallback to default font
    document.body.classList.add('font-sans');
    return;
  }

  const myFont = new FontFace(fontFamily, `url(${fontUrl})`);

  myFont.load().then(
    () => {
      document.fonts.add(myFont);

      const newStyle = document.createElement('style');

      newStyle.appendChild(
        document.createTextNode(
          `\
      @font-face {\
          font-family: ${fontFamily}\
          src: url(${fontUrl})\
      }\
      `,
        ),
      );

      document.body.appendChild(newStyle);

      document.body.style.fontFamily = fontFamily;
    },
    (err) => {
      Sentry.captureException(`Failed to load font from themeConfig: ${err}`);
      logger.error(`Failed to load font from themeConfig: ${err}`, {
        error: err,
        fontFamily,
        fontUrl,
      });

      // Fallback to default font
      document.body.classList.add('font-sans');
    },
  );
};
