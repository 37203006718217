import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  WalletMessageRequest,
  WalletNetwork,
  WalletNetworkToSymbolMap,
} from '@moonpay/login-common';
import { Wallet as EthersWallet, ethers } from 'ethers';
import { OnPromptChangeFunc } from 'src/messages/Message';
import addWalletMessageProxyEventListener from 'src/messages/walletProxy/addWalletMessageProxyEventListener';
import { isMethodAllowedForOrigin } from 'src/messages/walletProxy/isMethodAllowedForOrigin';
import { ethAccounts } from 'src/messages/walletProxy/methods/accounts';
import { addNetwork } from 'src/messages/walletProxy/methods/addNetwork';
import { chainId } from 'src/messages/walletProxy/methods/chainId';
import { exportMnemonic } from 'src/messages/walletProxy/methods/exportMnemonic';
import { getBalance } from 'src/messages/walletProxy/methods/getBalance';
import { openPill } from 'src/messages/walletProxy/methods/openPill';
import { personalSign } from 'src/messages/walletProxy/methods/personalSign';
import { btcSellTransaction } from 'src/messages/walletProxy/methods/sellTransaction/btcSellTransaction';
import { sendTransaction } from 'src/messages/walletProxy/methods/sendTransaction';
import { signTypedData } from 'src/messages/walletProxy/methods/signTypedData';
import { switchNetwork } from 'src/messages/walletProxy/methods/switchNetwork';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';
import {
  withPrompt,
  withoutPrompt,
} from 'src/messages/walletProxy/utils/withPrompt';
import { ErrorManager } from 'src/utils/errorManager';
import StorageUtils from 'src/utils/storage';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { WalletService } from 'src/wallet/services/walletService';
import { EthereumChainSpec } from 'src/wallet/storage/ChainsStorage';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import {
  AbstractWallet,
  Bitcoin,
  Ethereum,
  match,
} from 'src/wallet/types/Wallet';
import logger from '../../utils/logger';
import { SolanaMethods } from '../../v2/solana/types';
import { DEFAULT_ACTIVE_CHAINS } from '../../wallet/storage/defaultActiveChain';

const errorManager = new ErrorManager(__filename);

export type RegisterWalletMessageProxyHandlerParams = {
  walletStorage: WalletStorage;
  promptRef: React.RefObject<any>;
  onPromptChange: OnPromptChangeFunc;
  onSuccess: (response: any, id?: string) => void;
  onError: (error: any, id?: string) => void;
  origins: string[];
  autoApprove: boolean;
  mnemonic?: string;
  noModal: boolean;
};

export type HandleWalletMessageProxyMessageParams = WalletMessageRequest &
  RegisterWalletMessageProxyHandlerParams;

const ETH_RESTRICTED_METHODS = [
  'eth_coinbase',
  'eth_accounts',
  'eth_requestAccounts',
  'personal_sign',
  'eth_signTypedData',
  'eth_signTypedData_v4',
  'eth_sendTransaction',
  'personal_ecRecover',
  'eth_signTransaction',
  'eth_sign',
  'eth_signTypedData_v3',
  'eth_signTypedData_v1',
  'eth_requestAccounts',
  'wallet_switchEthereumChain',
  'wallet_addEthereumChain',
];

const BTC_RESTRICTED_METHODS = [
  'btc_latestBlock',
  'btc_accounts',
  'btc_sendTransaction',
  'btc_getBalance',
  'btc_chainId',
  'btc_switchNetwork',
  'btc_sellTransaction',
  // TODO: add balances, latest block, estimate gas (current implementations live in bitcoinsdk)
];

const V2_HANDLER_METHODS = [
  SolanaMethods.Connect,
  SolanaMethods.TransferTransaction,
  SolanaMethods.GetBalance,
  SolanaMethods.SignMessage,
  SolanaMethods.SwitchNetwork,
] as string[];

const ALL_NETWORKS_RESTRICTED_METHODS = [
  'wallet_disconnect',
  'export_mnemonic',
  'open_pill',
];

const RESTRICTED_METHODS = [
  // eth
  ...ETH_RESTRICTED_METHODS,

  // btc
  ...BTC_RESTRICTED_METHODS,

  // all networks
  ...ALL_NETWORKS_RESTRICTED_METHODS,
] as const;
type RestrictedMethod = typeof RESTRICTED_METHODS[number];

// DANGER: make sure changes make sense
const ALLOWED_HEADLESS_METHODS = [
  'eth_coinbase',
  'eth_accounts',
  'eth_requestAccounts',
];

const METHOD_IMPLEMENTATIONS: {
  [method in RestrictedMethod]?: MethodImplementation;
} = {
  // eth
  eth_coinbase: ethAccounts,
  eth_accounts: ethAccounts,
  eth_requestAccounts: ethAccounts,
  personal_sign: personalSign,
  eth_sign: personalSign,
  eth_signTypedData: signTypedData,
  eth_signTypedData_v4: signTypedData,
  eth_sendTransaction: sendTransaction,
  wallet_switchEthereumChain: switchNetwork,
  wallet_addEthereumChain: addNetwork,

  // btc
  // TODO: make sure ethAccounts is renamed correctly to accounts WAL-754
  btc_accounts: ethAccounts,
  btc_getBalance: getBalance,
  btc_chainId: chainId,
  btc_switchNetwork: switchNetwork,
  btc_sendTransaction: sendTransaction,
  btc_sellTransaction: btcSellTransaction,

  // all networks
  export_mnemonic: exportMnemonic,
  open_pill: openPill,
};

/*
 * In Memory "Cached" Variables
 */
const activeWalletAddresses: {
  [network: string]: { [chainId: number]: string | null };
} = {
  [WalletNetwork.Ethereum]: {
    1: null,
    137: null,
    80001: null,
    11155111: null,
  },
  [WalletNetwork.Bitcoin]: {
    0: null,
    1: null,
  },
  [WalletNetwork.Solana]: {
    900: null,
    901: null,
  },
};
const activeWallets: {
  [network: string]: { [chainId: number]: AbstractWallet | undefined };
} = {
  [WalletNetwork.Ethereum]: {
    1: undefined,
    137: undefined,
    80001: undefined,
    11155111: undefined,
  },
  [WalletNetwork.Bitcoin]: {
    0: undefined,
    1: undefined,
  },
  [WalletNetwork.Solana]: {
    900: undefined,
    901: undefined,
  },
};

export const walletMessageProxyHandler = async ({
  walletStorage,
  id,
  promptRef,
  onPromptChange,
  onSuccess,
  onError,
  request,
  mnemonic,
  autoApprove,
  network,
  noModal,
}: HandleWalletMessageProxyMessageParams): Promise<void> => {
  if (V2_HANDLER_METHODS.includes(request.method)) {
    logger.info('Received v2 handler method - ending execution', {
      ...request,
    });

    return;
  }

  try {
    if (!origin) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `origin caller is required for responses`,
      );
    }

    if (!network) {
      network = WalletNetwork.Ethereum;
    }

    if (!isMethodAllowedForOrigin({ method: request.method, origin })) {
      throw errorManager.getClientError(
        'walletMessageProxyHandler',
        `origin ${origin} is not allowed to call ${request.method}`,
      );
    }

    const networkSymbol = WalletNetworkToSymbolMap[network];

    // validate the network in the body is compatible with the method being called
    if (
      ETH_RESTRICTED_METHODS.includes(request.method) &&
      network !== WalletNetwork.Ethereum
    ) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `Method ${request.method} is not supported on ${network}`,
        { network, requestMethod: request.method },
      );
    }
    if (
      BTC_RESTRICTED_METHODS.includes(request.method) &&
      network !== WalletNetwork.Bitcoin
    ) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `Method ${request.method} is not supported on ${network}`,
        { network, requestMethod: request.method },
      );
    }

    const cryptoWallet = WalletService.cryptoWalletFactory(network);

    let activeChainId =
      walletStorage.activeChainId.getActiveChainIdByNetwork(network);
    let chain = walletStorage.chains.getChain(
      activeChainId.toString(),
      network,
    );
    if (!chain) {
      const defaultChainId = DEFAULT_ACTIVE_CHAINS[network];
      walletStorage.activeChainId.setActiveChainIdByNetwork(
        network,
        defaultChainId,
      );
      logger.warn(
        `Unsupported chainId ${activeChainId} received for network ${network}, defaulting it back to ${defaultChainId}`,
        { network, chainId: activeChainId, requestMethod: request.method },
      );

      activeChainId = defaultChainId;
      chain = walletStorage.chains.getChain(defaultChainId.toString(), network);
      if (!chain) {
        throw errorManager.getServerError(
          'walletMessageProxyHandler',
          `chain required to handle request, unsupported chainId ${activeChainId} for network ${network}`,
        );
      }
    }

    let response = null;

    const isRestrictedMethod = RESTRICTED_METHODS.includes(
      request.method as RestrictedMethod,
    );

    const isConnectionRequest = [
      `${networkSymbol}_accounts`,
      `${networkSymbol}_requestAccounts`,
    ].includes(request.method);

    if (isRestrictedMethod) {
      let wallet: AbstractWallet | undefined;

      // if we have an active wallet address, and the request is eth_accounts,
      // we can skip the connection check and just return the address
      if (
        activeWalletAddresses[network]?.[activeChainId] &&
        isConnectionRequest
      ) {
        onSuccess([activeWalletAddresses[network][activeChainId]], id);
        return;
      }

      // if we have an active wallet, we can skip restoring the wallet
      if (activeWallets[network]?.[activeChainId]) {
        wallet = activeWallets[network][activeChainId];
      } else if (mnemonic) {
        // TODO: This is used for jest test ONLY, should be deprecated later
        // once we no longer care
        wallet = (
          await cryptoWallet.createFromMnemonic(mnemonic, activeChainId)
        ).data;
      } else {
        wallet = await WalletService.restoreWallet(network, walletStorage);
      }

      if (!wallet) {
        throw errorManager.getServerError(
          'walletMessageProxyHandler',
          `wallet required to handle request`,
          { network, requestMethod: request.method },
        );
      }

      // only used for ethereum wallet requests
      // TODO: refactor this so that we don't do this for non evm networks
      const provider = WalletHelpers.getNetworkProvider(
        walletStorage.chains.value[WalletNetwork.Ethereum]![
          walletStorage.activeChainId.value[WalletNetwork.Ethereum]
        ],
      ) as any;

      const ethersWallet = ethers.Wallet.fromMnemonic(
        wallet.mnemonic.phrase,
      ).connect(provider);

      // if we are using an AbstractWallet, we need to connect it to the provider
      if (wallet.type === WalletNetwork.Ethereum) {
        match(wallet as AbstractWallet, {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Bitcoin: (_btcWallet: Bitcoin) => {
            //
          },
          Ethereum: async (ethWallet: Ethereum) => {
            ethWallet.wallet.connect(provider);
          },
        });
      }

      // if we don't have an active wallet address, set it to the first address
      // in the wallet after hydrating it
      if (!activeWalletAddresses[network]?.[activeChainId]) {
        activeWalletAddresses[network][activeChainId] = wallet.address;
      }

      // if we don't have an active wallet, set it to the wallet after hydrating it
      if (!activeWallets[network]?.[activeChainId]) {
        activeWallets[network][activeChainId] = wallet;
      }

      const isDisconnectRequest = request.method === 'wallet_disconnect';
      if (isDisconnectRequest) {
        // Cleanup all logged in artifacts
        await StorageUtils.clearCustomerTokens(); // clear cookies (invalidate API calls)
        await StorageUtils.clearWalletToken();
        activeWalletAddresses[network][activeChainId] = null; // remove active wallet address (wallet is not stored in memory)
        activeWallets[network][activeChainId] = undefined; // remove active wallet (wallet is not stored in memory)
        sessionStorage.removeItem('moonpay-wallet'); // remove encrypted wallet from session storage

        await walletStorage.connections.updateWalletConnection({
          address: wallet.address,
          chainId: activeChainId,
          origin,
          value: false,
          network,
        });

        onSuccess(true, id);
        return;
      }

      if (!autoApprove && !isConnectionRequest) {
        const isConnected =
          walletStorage.connections.checkWalletConnectionStatus({
            address: wallet.address,
            chainId: activeChainId,
            origin,
            network,
          });

        if (!isConnected) {
          throw new EthProviderRpcError(
            EthProviderRpcErrorCode.UserRejectedRequest,
          );
        }
      }

      // prompts
      const methodImpl =
        METHOD_IMPLEMENTATIONS[request.method as RestrictedMethod];
      if (!methodImpl) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.UnsupportedMethod,
          `Moonpay wallet does not support calling ${request.method}.`,
        );
      }

      // TODO: refactor so that we don't have to pass in ethers.wallet and domain wallet
      if (autoApprove && ALLOWED_HEADLESS_METHODS.includes(request.method)) {
        response = await withoutPrompt({
          request,
          walletStorage,
          wallet: ethersWallet as EthersWallet,
          abstractWallet: wallet as AbstractWallet,
          origin,
          method: methodImpl,
          network,
        });
      } else {
        response = await withPrompt({
          abstractWallet: wallet as AbstractWallet,
          method: methodImpl,
          network,
          noModal,
          onPromptChange,
          origin,
          promptRef,
          request,
          wallet: ethersWallet as EthersWallet,
          walletStorage,
        });
      }
    } else if (network === WalletNetwork.Ethereum) {
      const getNetworkProvider = (selectedChain: EthereumChainSpec) => {
        // TODO: Only the first rpc url is used here but you can also setup fallbacks
        const rpcUrl = selectedChain.rpcUrls[0];
        if (!rpcUrl) {
          throw errorManager.getServerError(
            'walletMessageProxyHandler',
            `rpc url required to get network provider`,
            { chain: selectedChain },
          );
        }
        return new ethers.providers.JsonRpcProvider({
          url: rpcUrl,
        });
      };

      const provider = getNetworkProvider(chain);
      // TODO: FIX
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      response = await provider.send(request.method, request.params!);
    }

    logger.info('Successfully called method', {
      method: request.method,
      params: request.params,
    });
    onSuccess(response, id);
  } catch (e: unknown) {
    logger.error(
      'Error calling method',
      {
        method: request.method,
        params: request.params,
      },
      e as Error,
    );

    onError(e, id);
  }
};

/* istanbul ignore next */
// REASON:
// This code is a proxy for other code and should be tested from there or moved.
const registerWalletMessageProxyHandler = (
  params: RegisterWalletMessageProxyHandlerParams,
) => {
  addWalletMessageProxyEventListener(
    (payload: WalletMessageRequest) =>
      walletMessageProxyHandler({
        ...params,
        ...payload,
      }),
    params.origins,
  );
};

export default registerWalletMessageProxyHandler;
