import {
  EthProviderRpcError,
  WalletMessage,
  WalletMessageResponse,
  WalletMessageType,
  WalletNetwork,
} from '@moonpay/login-common';
import getTargetOrigin from './getTargetOrigin';

type RequestReplyMessage = 'get-wallet' | 'create-wallet';

type FireAndForgetOutboundMessage = 'ready';

type ProxyMessage = 'proxy';

type MessageResult = 'success' | 'error';

type MessageType =
  | FireAndForgetOutboundMessage
  // eslint-disable-next-line prettier/prettier
  | `${RequestReplyMessage}-${MessageResult}`
  | `${ProxyMessage}-${RequestReplyMessage}-${MessageResult}`;

export interface Message<PayloadType = null> extends MessageEvent {
  type: MessageType;
  payload: PayloadType;
}

export type OnPromptChangeFunc = (
  action: string,
  network: WalletNetwork,
) => void;

const createMessage: <T = Record<string, unknown>>(
  type: MessageType,
  payload?: T,
) => Message = (type, payload) => {
  return {
    type,
    ...payload,
  } as Message;
};

const sendMessageToParent: <T = Record<string, unknown>>(
  type: MessageType,
  origins: string[],
  payload?: T,
  id?: string,
) => void = (type, origins, payload, id) => {
  const message = createMessage(type, { ...payload, id });
  window.parent.postMessage(
    JSON.parse(JSON.stringify(message)),
    getTargetOrigin(origins),
  );
};

function sendWalletMessage(message: WalletMessage, origins: string[]): void {
  window.parent.postMessage(
    JSON.parse(JSON.stringify(message)),
    getTargetOrigin(origins),
  );
}

function sendWalletReply(
  payload: { result?: any; error?: EthProviderRpcError },
  origins: string[],
  id?: string,
): void {
  const message: WalletMessageResponse = {
    type: WalletMessageType.MESSAGE_PROXY_RESPONSE,
    // THIS ID IS SUPER IMPORTANT, CANT CHANGE
    id,
    ...payload,
  };
  sendWalletMessage(message, origins);
}

export { sendMessageToParent, sendWalletMessage, sendWalletReply };
