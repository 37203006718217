import { WalletNetwork } from '@moonpay/login-common';
import environment from 'src/environment';
import { NetworkSolChain } from 'src/types/NetworkChain';
import { MoonPayWalletError, SolanaWalletErrors } from 'src/v2/common/errors';
import { PromptManager } from 'src/v2/common/prompts';
import { PromptType } from 'src/v2/common/prompts/prompt-map';
import {
  LegacyController,
  Params,
  PostMessage,
  Response,
} from 'src/v2/lib/decorators';
import { SolanaGetBalanceRequest } from 'src/v2/solana/requests/solana-get-balance.request';
import { SolanaSignMessageRequest } from 'src/v2/solana/requests/solana-sign-message.request';
import { SolanaSwitchNetworkRequest } from 'src/v2/solana/requests/solana-switch-network.request';
import {
  SolanaConnectResponse,
  type ISolanaConnectResponse,
} from 'src/v2/solana/responses/solana-connect.response';
import {
  SolanaGetBalanceResponse,
  type ISolanaBalanceResponse,
} from 'src/v2/solana/responses/solana-get-balance.response';
import {
  ISolanaSignMessageResponse,
  SolanaSignMessageResponse,
} from 'src/v2/solana/responses/solana-sign-message.response';
import {
  ISolanaSwitchNetworkResponse,
  SolanaSwitchNetworkResponse,
} from 'src/v2/solana/responses/solana-switch-network.response';
import { SolanaNetworkEnvironmentManager } from 'src/v2/solana/services/network-environment-manager.service';
import { SolanaWalletConnectionService } from 'src/v2/solana/services/wallet-connection.service';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { formatValueSol } from '../../messages/walletProxy/methods/sendTransaction/utils';
import type { AbstractWallet } from '../../wallet/types/Wallet';
import { SolanaTransferTransactionRequest } from './requests/solana-transfer-transaction.request';
import {
  ISolanaTransferTransactionResponse,
  SolanaTransferTransactionResponse,
} from './responses/solana-transfer-transaction.response';
import { SolanaTransactionService } from './services/solana-transaction.service';
import {
  SolanaMethods,
  SolanaNetworkEnvironment,
  WalletApiSolanaBalanceResponse,
} from './types';

@LegacyController('wallet-message-proxy')
export class LegacySolanaController {
  private readonly network = WalletNetwork.Solana;

  private readonly walletConnectionService: SolanaWalletConnectionService;

  private readonly promptManager: PromptManager;

  private readonly networkEnvironmentManager: SolanaNetworkEnvironmentManager;

  constructor(private readonly walletStorage: WalletStorage) {
    this.walletConnectionService = new SolanaWalletConnectionService(
      this.walletStorage,
    );
    this.promptManager = new PromptManager({ network: this.network });

    this.networkEnvironmentManager = new SolanaNetworkEnvironmentManager(
      this.walletStorage,
    );
  }

  @PostMessage(SolanaMethods.Connect, { legacy: true })
  @Response(SolanaConnectResponse)
  public async connect(
    wallet: AbstractWallet,
  ): Promise<ISolanaConnectResponse> {
    const isConnected =
      this.walletStorage.connections.checkWalletConnectionStatus({
        address: wallet.address,
        chainId: this.walletStorage.activeChainId.getActiveChainIdByNetwork(
          this.network,
        ),
        origin,
        network: this.network,
      });

    if (!isConnected) {
      await this.promptManager.showPrompt(PromptType.SolanaConnectPrompt, {
        onReject: () => {
          this.walletConnectionService.storeRejectedConnection(wallet.address);
        },
      });
    }

    await this.walletConnectionService.storeApprovedConnection(wallet.address);

    return {
      addresses: [wallet.address],
    };
  }

  @Params(SolanaSwitchNetworkRequest)
  @Response(SolanaSwitchNetworkResponse)
  @PostMessage(SolanaMethods.SwitchNetwork, { legacy: true })
  public async switchNetwork(
    wallet: AbstractWallet,
    body: SolanaSwitchNetworkRequest,
  ): Promise<ISolanaSwitchNetworkResponse> {
    const fromNetworkEnvironment =
      this.networkEnvironmentManager.getActiveNetworkEnvironment();

    await this.promptManager.showPrompt(PromptType.SolanaSwitchNetworkPrompt, {
      fromNetworkEnvironment,
      toNetworkEnvironment: body.networkEnvironment,
    });

    this.networkEnvironmentManager.updateActiveChainId(body.networkEnvironment);

    await this.walletConnectionService.storeApprovedConnection(wallet.address);

    return {
      networkEnvironment: body.networkEnvironment,
    };
  }

  @PostMessage(SolanaMethods.TransferTransaction, { legacy: true })
  @Response(SolanaTransferTransactionResponse)
  @Params(SolanaTransferTransactionRequest)
  public async sendTransferTransaction(
    wallet: AbstractWallet,
    body: SolanaTransferTransactionRequest,
  ): Promise<ISolanaTransferTransactionResponse> {
    // TODO: I want to introduce some basic balance validation here before showing the prompt, this can be done after sol_getBalance is implemented
    await this.promptManager.showPrompt(PromptType.SolanaTransferPrompt, {
      fromAddress: wallet.address,
      toAddress: body.toWalletAddress,
      valueInSol: formatValueSol(Number(body.value)),
      cryptocurrencyCode: 'SOL',
    });

    const solanaTransactionService = new SolanaTransactionService(
      wallet,
      this.walletStorage,
    );
    const signature = await solanaTransactionService.sendTransferTransaction(
      body.toWalletAddress,
      Number(body.value),
    );

    return { signature };
  }

  @PostMessage(SolanaMethods.GetBalance, { legacy: true })
  @Params(SolanaGetBalanceRequest)
  @Response(SolanaGetBalanceResponse)
  public async getBalance(
    body: SolanaGetBalanceRequest,
  ): Promise<ISolanaBalanceResponse> {
    const { walletAddress } = body;

    const solChainId =
      this.walletStorage.activeChainId.getActiveChainIdByNetwork(
        WalletNetwork.Solana,
      );

    let chainName = SolanaNetworkEnvironment.Devnet;
    if (solChainId !== NetworkSolChain.Mainnet) {
      chainName = SolanaNetworkEnvironment.Mainnet;
    }
    const resp = await fetch(
      `${environment.moonpayWalletApiUrl}/v1/solana/${chainName}/balance?walletAddress=${walletAddress}`,
    );

    if (resp.status !== 200) {
      throw new MoonPayWalletError(
        SolanaWalletErrors.SOL_WALLET_API_BALANCE_ERROR,
      );
    }
    const data: WalletApiSolanaBalanceResponse = await resp.json();
    return {
      balance: data.data.balance.value,
      unit: data.data.balance.unit,
    };
  }

  @PostMessage(SolanaMethods.SignMessage, { legacy: true })
  @Params(SolanaSignMessageRequest)
  @Response(SolanaSignMessageResponse)
  public async signMessage(
    wallet: AbstractWallet,
    body: SolanaSignMessageRequest,
  ): Promise<ISolanaSignMessageResponse> {
    await this.promptManager.showPrompt(
      PromptType.SolanaSignMessagePrompt,
      body,
    );

    const solanaTransactionService = new SolanaTransactionService(
      wallet,
      this.walletStorage,
    );

    return { signature: solanaTransactionService.signMessage(body.message) };
  }
}
