import { Label, Text } from '@moonpay-widget/ui-kit';
import { FC } from 'react';
import WalletAddressComponent from 'src/components/WalletAddressComponent';
import { formatTransactionCost } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { TokenInfo } from '../erc20/tokenList';

export type Erc20SendProps = {
  value: string;
  to: string;
  networkFee: string;
  token: TokenInfo;
  currencyCode: string;
  nativeCurrency: string;
};

const Erc20Send: FC<Erc20SendProps> = ({
  to,
  value,
  networkFee,
  token,
  currencyCode,
  nativeCurrency,
}) => {
  return (
    <div className="mt-4">
      <WalletAddressComponent
        leftLabel="Send to"
        leftValue={to}
        rightLabel="Amount"
        rightValue={`${value} ${currencyCode}`}
      />
      <div className="flex mt-8 items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xl overflow-auto">
        <Text as="div" variant="footnote" className="flex my-4">
          <div className="float-left w-1/2">Blockchain fee</div>
          <div className="float-right w-1/2 text-right ">{` ${formatTransactionCost(
            networkFee,
          )} ${nativeCurrency}`}</div>
        </Text>
      </div>
    </div>
  );
};

export default Erc20Send;
