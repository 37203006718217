import { MoonPayWalletsApiClient } from 'src/v2/common/clients/moonpay-wallets-api.client';
import { EvmWalletErrors, MoonPayWalletError } from 'src/v2/common/errors';
import { SupportedEvmChain } from 'src/v2/evm/config';
import { EvmGetBalanceResponse } from 'src/v2/evm/responses/evm-get-balance.response';
import { EvmTransactionCountResponse } from 'src/v2/evm/responses/evm-transaction-count.response';
import { EvmSendTransactionResponse } from 'src/v2/evm/responses/evm-transfer-transaction.response';
import {
  MoonPayWalletsApiGetBalanceResponse,
  MoonPayWalletsApiSendTransactionResponse,
  MoonPayWalletsApiTransactionCountResponse,
} from 'src/v2/evm/types';

export class EvmApiService {
  public async getBalance({
    chainName,
    address,
  }: {
    chainName: SupportedEvmChain;
    address: `0x${string}`;
  }): Promise<EvmGetBalanceResponse> {
    const response =
      await MoonPayWalletsApiClient.callApi<MoonPayWalletsApiGetBalanceResponse>(
        'GET',
        `/v1/evm/${chainName}/balance?walletAddress=${address}`,
      );

    if (!response?.data) {
      throw new MoonPayWalletError(
        EvmWalletErrors.EVM_WALLET_API_BALANCE_ERROR,
      );
    }

    return {
      value: response.data.balance.value,
      unit: response.data.balance.unit,
    };
  }

  public async getTransactionCount({
    chainName,
    address,
  }: {
    chainName: SupportedEvmChain;
    address: `0x${string}`;
  }): Promise<EvmTransactionCountResponse> {
    const response =
      await MoonPayWalletsApiClient.callApi<MoonPayWalletsApiTransactionCountResponse>(
        'GET',
        `/v1/evm/${chainName}/transaction-count?walletAddress=${address}`,
      );

    if (!response?.data) {
      throw new MoonPayWalletError(
        EvmWalletErrors.EVM_WALLET_API_TRANSACTION_COUNT_ERROR,
      );
    }

    return {
      count: response.data.count,
    };
  }

  public async sendTransaction({
    chainName,
    signedTransaction,
  }: {
    chainName: SupportedEvmChain;
    signedTransaction: `0x${string}`;
  }): Promise<EvmSendTransactionResponse> {
    const response =
      await MoonPayWalletsApiClient.callApi<MoonPayWalletsApiSendTransactionResponse>(
        'POST',
        `/v1/evm/${chainName}/transaction`,
        JSON.stringify({ signedTransaction }),
      );

    if (!response?.data) {
      throw new MoonPayWalletError(
        EvmWalletErrors.EVM_WALLET_API_SEND_TRANSACTION_ERROR,
      );
    }

    return {
      txHash: response.data.txHash,
    };
  }
}
