import {
  signTypedDataExecute,
  signTypedDataPrompt,
  signTypedDataReject,
  signTypedDataValidate,
} from 'src/messages/walletProxy/methods/signTypedData/signTypedData';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const signTypedData: MethodImplementation = {
  validate: signTypedDataValidate,
  prompt: signTypedDataPrompt,
  execute: signTypedDataExecute,
  reject: signTypedDataReject,
};

export { signTypedData };
