import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  ProviderRequest,
  WalletNetwork,
} from '@moonpay/login-common';
import { ErrorManager } from '../../../../../utils/errorManager';
import { WalletStorage } from '../../../../../wallet/storage/WalletStorage';
import { isBTCAddressValid } from '../../../utils/validateBitcoinAddress';

const errorManager = new ErrorManager(__filename);

export function sendTransactionValidateBtc(
  request: ProviderRequest,
  walletStorage: WalletStorage,
  network: WalletNetwork.Bitcoin,
  to: string,
) {
  const requiredFields = ['to', 'value'];

  const missingFields = requiredFields.filter((field) => {
    // TODO: FIX
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fieldValue = request.params![0][field];
    return fieldValue === undefined || fieldValue === null;
  });

  if (missingFields.length > 0) {
    throw errorManager.getServerError(
      'sendTransactionValidate',
      `Invalid request params[0] missing fields: ${missingFields.join(', ')}`,
    );
  }

  // TODO: FIX
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const optionalFeeField = request.params![0].fee as string;
  if (optionalFeeField) {
    if (Number.isNaN(parseFloat(optionalFeeField))) {
      throw errorManager.getServerError(
        'sendTransactionValidate',
        `Invalid fee field type, this field must be a number`,
        {
          fee: optionalFeeField,
        },
      );
    }

    if (parseFloat(optionalFeeField) <= 0) {
      throw errorManager.getServerError(
        'sendTransactionValidate',
        `Invalid fee field value, this field must be greater than 0`,
        {
          fee: optionalFeeField,
        },
      );
    }
  }

  const activeChainId =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);

  if (!isBTCAddressValid(to, activeChainId)) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      `Invalid to BTC address: ${to}`,
    );
  }

  // check value is a number
  // TODO: FIX
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (typeof request.params![0].value !== 'number') {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      // TODO: FIX
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `Invalid value: ${request.params![0].value}`,
    );
  }
}
