interface FormatWalletAddressProps {
  address: string;
  fullAddress?: boolean;
}
const isAnEnsAddress = (address: string) => {
  return /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/.test(
    address,
  );
};
const isAnEvmAddress = (address: string) => {
  return address.startsWith('0x');
};
const isABtcAddress = (address: string) => {
  return (
    address.startsWith('tb') ||
    address.startsWith('bc1') ||
    address.startsWith('1') ||
    address.startsWith('3')
  );
};
export const formatWalletAddress = ({
  address,
  fullAddress = false,
}: FormatWalletAddressProps) => {
  if (fullAddress) {
    return address;
  }
  if (isAnEnsAddress(address)) {
    return address;
  }
  if (isAnEvmAddress(address)) {
    const match = address.match(/(\w{6}).+(\w{4})/);
    return match ? `${match[1]}…${match[2]}` : address;
  }
  if (isABtcAddress(address)) {
    const match = address.match(/(\w{4}).+(\w{4})/);
    return match ? `${match[1]}…${match[2]}` : address;
  }
  // Matcher for Solana addresses and any other chains we onboard, basically takes first 5 and last 5 characters and inserts an ellipsis in between
  const match = address.match(/(\w{5}).+(\w{5})/);
  return match ? `${match[1]}…${match[2]}` : address;
};
