import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  ProviderRequest,
} from '@moonpay/login-common';
import { utils } from 'ethers';

export function sendTransactionValidateEvm(
  request: ProviderRequest,
  from: string,
  to: string,
): void {
  const requiredFields = ['from', 'to'];

  const missingFields = requiredFields.filter(
    // TODO: FIX
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (field) => !request.params![0][field],
  );

  if (missingFields.length > 0) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      `Invalid request params[0] missing fields: ${missingFields.join(', ')}`,
    );
  }

  if (!utils.isAddress(from)) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      `Invalid from ETH address: ${from}`,
    );
  }
  if (!utils.isAddress(to)) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      `Invalid to ETH address: ${to}`,
    );
  }
}
