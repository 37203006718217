import type { ProviderRequest } from '@moonpay/login-common';
import type { AbstractWallet } from '../../../../../wallet/types/Wallet';
import { fetchEstimatedSmartFeeByMode } from '../bitcoin/utils';

export async function btcEstimateGas(
  request: ProviderRequest,
  abstractWallet: AbstractWallet,
  transaction: any,
  chainId: number,
): Promise<string> {
  // TODO: FIX
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customFee = request.params![0].fee;
  if (customFee) {
    return (parseFloat(customFee) / 100_000_000).toString();
  }
  // TODO: Break this out into all modes and pass it into the prompt for users to select
  const smartFeesByMode = await fetchEstimatedSmartFeeByMode(
    abstractWallet.address,
    transaction.value,
    chainId,
  );

  const calculatedFee = smartFeesByMode.medium.nativeCryptoFee;
  // TODO: FIX
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  request.params![0].fee = calculatedFee; // TODO: We shouldn't be passing info via the params like this, need to re-architect - could be dangerous
  return (parseFloat(calculatedFee) / 100_000_000).toString();
}
