import {
  getBalanceExecute,
  getBalancePrompt,
  getBalanceReject,
  getBalanceValidate,
} from 'src/messages/walletProxy/methods/getBalance/getBalance';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const getBalance: MethodImplementation = {
  validate: getBalanceValidate,
  prompt: getBalancePrompt,
  execute: getBalanceExecute,
  reject: getBalanceReject,
};

export { getBalance };
