import { CreateWalletPayload } from '@moonpay-widget/dhw';
import {
  SECURE_WALLET_SUPPORTED_NETWORKS,
  WalletDetail,
  WalletDetails,
} from '@moonpay/login-common';
import { switchActiveChainId } from 'src/utils/activeChain';
import StorageUtils from 'src/utils/storage';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { KmsWalletProvider } from 'src/wallet/walletProvider/kms/KmsWalletProvider';
import logger from '../../utils/logger';
import addCreateWalletMessageEventListener from './addCreateWalletMessageEventListener';

export type RegisterCreateWalletMessageHandlerParams = {
  walletStorage: WalletStorage;
  onSuccess: (walletAddresses: WalletDetails, id?: string) => void;
  onError: (error: any, id?: string) => void;
  origins: string[];
};

export const createWalletMessageHandler = async (
  params: RegisterCreateWalletMessageHandlerParams,
  payload: CreateWalletPayload,
) => {
  const { walletStorage, onSuccess, onError } = params;

  const customerTokenPayload = payload.customerToken as string;
  const walletTokenPayload = payload.walletToken as string;
  const isMainnet = payload.isMainnet !== undefined ? payload.isMainnet : true;

  const selectedNetworks = payload.networks || SECURE_WALLET_SUPPORTED_NETWORKS;
  const networks = Array.from(new Set(selectedNetworks));

  // Store csrf, customer token, and wallet token to indexeddb, if necessary
  if (customerTokenPayload) StorageUtils.setCustomerToken(customerTokenPayload);
  if (walletTokenPayload) await StorageUtils.setWalletToken(walletTokenPayload);

  const wallets: WalletDetail[] = [];

  for (const network of networks) {
    // Iterate over the list of networks
    let encryptedWallet: string;
    let walletAddress: string;

    try {
      switchActiveChainId(walletStorage, network, isMainnet);

      // TODO: Fix
      // eslint-disable-next-line no-await-in-loop
      const wallet = await WalletService.restoreWallet(network, walletStorage);

      if (wallet) {
        wallets.push({
          address: wallet.address,
          network,
        });

        // eslint-disable-next-line no-continue
        continue;
      }

      const kmsProvider = new KmsWalletProvider();
      // TODO: Fix
      // eslint-disable-next-line no-await-in-loop
      const createResult = await kmsProvider.create(network, walletStorage);
      walletAddress = createResult.walletAddress;
      encryptedWallet = createResult.walletToStore;

      walletStorage.encryptedWallet.set(encryptedWallet);

      wallets.push({
        address: walletAddress,
        network,
      });
    } catch (e: any) {
      logger.error(
        'Error occurred whilst registering the createWalletMessageHandler',
        { message: e.message },
      );
      onError(e);
    }
  }

  if (wallets.length > 0) {
    // Only call onSuccess if wallets were successfully created
    onSuccess({ wallets }, payload.id);
  } else {
    onError(new Error('No wallets were restored or created'), payload.id);
  }
};

const registerCreateWalletMessageHandler = (
  params: RegisterCreateWalletMessageHandlerParams,
) => {
  addCreateWalletMessageEventListener(
    (payload: CreateWalletPayload) =>
      createWalletMessageHandler(params, payload),
    params.origins,
  );
};

export default registerCreateWalletMessageHandler;
