import { SummaryRow, Text } from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';

export interface SwitchNetworkPromptProps {
  chainId: string;
  chainName: string;
  nativeCurrency: any;
  rpcUrls: any[];
  blockExplorerUrls: any[];
}

const treatValue = (value: any) => {
  if (typeof value === 'string') {
    if (value.length > 20) {
      // show full value on hover
      return <span title={value}>{`${value.slice(0, 60)}...`}</span>;
    }

    return <span>{value}</span>;
  }
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

const AddNetworkPrompt: FunctionComponent<SwitchNetworkPromptProps> = ({
  chainId,
  chainName,
  nativeCurrency,
  rpcUrls,
  blockExplorerUrls,
}) => {
  return (
    <div>
      <div>
        <SummaryRow title="Chain ID" value={treatValue(chainId)} />
        <SummaryRow title="Chain Name" value={treatValue(chainName)} />

        <Text variant="footnote" className="text-label-secondary mt-6">
          Native Currency
        </Text>
        <SummaryRow title="Symbol" value={treatValue(nativeCurrency.symbol)} />
        <SummaryRow title="Name" value={treatValue(nativeCurrency.name)} />
        <SummaryRow
          title="Decimals"
          value={treatValue(nativeCurrency.decimals)}
        />

        <Text variant="footnote" className="text-label-secondary mt-6">
          RPC Urls
        </Text>
        {rpcUrls.map((url: string) => (
          <SummaryRow title="RPC Url" value={treatValue(url)} />
        ))}

        <Text variant="footnote" className="text-label-secondary mt-6">
          Block Explorer Urls
        </Text>
        {blockExplorerUrls.map((url: string) => (
          <SummaryRow title="RPC Url" value={treatValue(url)} />
        ))}
      </div>
    </div>
  );
};

export default AddNetworkPrompt;
