import { SecretIcon } from '@moonpay-widget/ui-kit/src/PromptIcons';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { ErrorManager } from 'src/utils/errorManager';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';
import ExportMnemonic from './ExportMnemonicPrompt';

const errorManager = new ErrorManager(__filename);

export async function exportMnemonicValidate(req: PromptRequest) {
  if (req.request.method !== 'export_mnemonic') {
    throw errorManager.getServerError(
      'exportMnemonicValidate',
      `Invalid request method`,
    );
  }

  if (req.request.params && req.request.params.length > 0) {
    throw errorManager.getServerError(
      'exportMnemonicValidate',
      `Invalid request params`,
    );
  }
}

// eslint-disable-next-line no-empty-pattern
export async function exportMnemonicExecute({}: PromptRequest) {
  // This function is purposefully left blank because the approve button is hidden
  // and it does not make sense to `execute` the a exportMnemonic request. No
  // action is taken when the user clicks the approve button.
}

// eslint-disable-next-line no-empty-pattern
export async function exportMnemonicReject({}: PromptRequest) {
  // This function is purposefully left blank because canceling or closing the
  // prompt is the same as rejecting the request, and is the only option available
  // to the user. It also does not make sense to `reject` the a exportMnemonic request.
  // and no action is taken when the user clicks the cancel button.
}

export async function exportMnemonicPrompt({ abstractWallet }: PromptRequest) {
  const address = match<string>(abstractWallet, {
    Bitcoin: (btcWallet: Bitcoin) => btcWallet.address,
    Ethereum: (ethWallet: Ethereum) => ethWallet.wallet.address,
  });

  // if address is an error, throw
  if (address instanceof Error) {
    throw errorManager.getServerError(
      'exportMnemonicPrompt',
      `Invalid network`,
    );
  }

  return {
    title: 'Your recovery phrase',
    partnerLogo: SecretIcon,
    component: <ExportMnemonic mnemonic={abstractWallet.mnemonic.phrase} />,
    cancelButtonDisabled: true,
    notifyPromptClosedOnApprove: true,
    approveButtonText: 'Done',
  };
}
