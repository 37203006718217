import { Button, Icon } from '@moonpay-widget/ui-kit';
import {
  ArrowSmDownIcon,
  PaperAirplaneIcon,
  PlusSmIcon,
} from '@moonpay-widget/ui-kit/src/icons';

type HeroProps = {
  balance: string;
  nativeCurrency: any;
};

export const Hero = ({ balance, nativeCurrency }: HeroProps) => {
  return (
    <div
      className="flex flex-col items-center mb-4 p-8"
      style={{
        background:
          'radial-gradient(44.95% 44.95% at 75.44% 41.28%, rgba(125, 0, 255, 0.12) 0%, rgba(0, 0, 0, 0) 100%, rgba(125, 0, 255, 0) 100%), radial-gradient(73.98% 182.07% at -7.46% -9.4%, rgba(125, 0, 255, 0.12) 0%, rgba(125, 0, 255, 0) 100%), linear-gradient(0deg, #F7F7F8, #F7F7F8)',
        borderRadius: '30px',
      }}
    >
      <div style={{ fontSize: '12pt', fontWeight: 500, color: '#999' }}>
        YOUR BALANCE
      </div>
      <div
        className="font-bold text-label-primary -mt-2"
        style={{ fontSize: '30pt', fontWeight: 900 }}
      >
        {balance} {nativeCurrency.symbol}
      </div>

      <div className="flex space-x-4 -mt-4 w-full">
        {/* three buttons */}
        <Button
          variant="custom"
          style={{
            backgroundColor: '#5500AD',
            width: '100%',
            color: '#fff',
          }}
        >
          <Icon icon={PlusSmIcon} />
          <div className="mr-2">Buy</div>
        </Button>
        <Button
          variant="custom"
          style={{
            backgroundColor: 'rgba(118, 116, 128, 0.30)',
            color: '#fff',
          }}
        >
          <Icon icon={PaperAirplaneIcon} />
          <div className="mr-2">Send</div>
        </Button>
        <Button
          style={{
            backgroundColor: 'rgba(118, 116, 128, 0.30)',
            color: '#fff',
          }}
          variant="custom"
        >
          <Icon icon={ArrowSmDownIcon} />
          <div className="ml-2">Receive</div>
        </Button>
      </div>
    </div>
  );
};
