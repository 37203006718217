import { WalletNetwork } from '@moonpay/login-common';
import { Wallet as EthersWallet } from 'ethers';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { EventTrackingUtils } from '../../../../../utils/eventTracking';
import logger from '../../../../../utils/logger';
import { WalletStorage } from '../../../../../wallet/storage/WalletStorage';
import { AbstractWallet } from '../../../../../wallet/types/Wallet';
import {
  KmsWalletTransactionType,
  createKmsWalletTransaction,
} from '../../../../../wallet/walletProvider/kms/kmsApi';
import { isSegWitAddress } from '../../../utils/validateBitcoinAddress';
import {
  createBtcTransaction,
  fetchEstimatedSmartFeeByMode,
  getBtcUtxos,
  selectUtxos,
  sendRawBtcTransaction,
} from '../bitcoin/utils';

type Params = {
  unsignedTransaction: any;
  walletStorage: WalletStorage;
  network: WalletNetwork.Bitcoin;
  abstractWallet: AbstractWallet;
  origin: string;
  wallet: EthersWallet;
};

export async function executeBtcTransaction({
  unsignedTransaction,
  walletStorage,
  network,
  abstractWallet,
  origin,
  wallet,
}: Params): Promise<string> {
  const { to, value, fee } = unsignedTransaction;

  const amount = Number(value.toString());

  const activeChainId =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);
  const isSegWit = isSegWitAddress(to, activeChainId);
  const { utxos } = await getBtcUtxos(abstractWallet.address, activeChainId);

  let feeInSats = parseFloat(fee);
  // Fallback to auto fee calculation if fee is not provided
  if (Number.isNaN(feeInSats)) {
    const chainId =
      walletStorage.activeChainId.getActiveChainIdByNetwork(network);
    const smartFeesByMode = await fetchEstimatedSmartFeeByMode(
      abstractWallet.address,
      value,
      chainId,
    );
    feeInSats = parseFloat(smartFeesByMode.fast.nativeCryptoFee);
  }

  const { inputs, change } = selectUtxos(
    utxos,
    amount,
    activeChainId,
    feeInSats,
  );

  const tx = createBtcTransaction(
    inputs,
    change,
    to,
    amount,
    abstractWallet,
    activeChainId,
  );

  logger.debug('Sending BTC transaction', {
    transaction: tx,
    origin,
    abstractWalletAddress: abstractWallet.address,
    walletAddress: wallet.address,
    network: WalletNetwork.Bitcoin,
  });
  const txHash = await sendRawBtcTransaction(
    tx,
    activeChainId,
    abstractWallet.address,
  );

  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.approveTransactionCompleted,
    {
      walletAddress: abstractWallet.address,
      walletNetwork: network,
      transactionHash: txHash,
    },
  );

  try {
    logger.debug('Saving Kms Wallet Transaction', {
      transactionHash: txHash,
      origin,
      abstractWalletAddress: abstractWallet.address,
      walletAddress: wallet.address,
      network: WalletNetwork.Bitcoin,
    });
    await createKmsWalletTransaction({
      transactionType: KmsWalletTransactionType.crypto,
      transactionMetadata: {
        baseCurrency: {
          code: 'btc',
          type: 'crypto',
          precision: 8,
        },
        baseCurrencyAmount: amount.toString(), // @dev in sats
      },
      networkCode: WalletNetwork.Bitcoin,
      transactionHash: txHash,
      from: abstractWallet.address,
      to: to ?? null,
      transactionFee: feeInSats.toString(),
      transactionFeeUnits: 'satoshis',
    });
  } catch (err: any) {
    logger.error('Failed to create kms wallet transaction', {
      err: err?.toString(),
      transactionHash: txHash,
      from: abstractWallet.address,
      network: WalletNetwork.Bitcoin,
    });
  }
  return txHash;
}
