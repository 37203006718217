import { GetOpenIdTokenForDeveloperIdentityResponse } from '@aws-sdk/client-cognito-identity';
import {
  DecryptCommand,
  GenerateDataKeyCommand,
  KMSClient,
} from '@aws-sdk/client-kms';
import {
  AssumeRoleWithWebIdentityCommand,
  Credentials,
  STSClient,
} from '@aws-sdk/client-sts';
import { Buffer } from 'buffer';
import { ErrorManager } from 'src/utils/errorManager';
import StorageUtils from 'src/utils/storage';

const errorManager = new ErrorManager(__filename);

const COGNITO_ROLE_ARN = process.env.REACT_APP_COGNITO_ROLE_ARN || '';
const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION || '';
const KMS_KEY_ID = process.env.REACT_APP_KMS_KEY_ID || '';

function getKmsClient(session: Credentials) {
  return new KMSClient({
    region: COGNITO_REGION,
    // Use the AWS credentials that are associated with the user's Cognito identity.
    credentials: {
      // TODO: FIX
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      accessKeyId: session.AccessKeyId!,
      // TODO: FIX
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      secretAccessKey: session.SecretAccessKey!,
      sessionToken: session.SessionToken,
    },
  });
}

async function getDataKey(subject: string, session: Credentials) {
  const kms = getKmsClient(session);
  const params = {
    EncryptionContext: { sub: subject },
    KeyId: KMS_KEY_ID,
    KeySpec: 'AES_256',
  };
  const command = new GenerateDataKeyCommand(params);
  const dataKey = await kms.send(command);
  if (!dataKey.Plaintext || !dataKey.CiphertextBlob) {
    throw errorManager.getServerError(
      'getDataKey',
      `Datakey missing plaintext and ciphertext`,
    );
  }

  return {
    plainText: Buffer.from(dataKey.Plaintext),
    cipherText: Buffer.from(dataKey.CiphertextBlob),
  };
}

async function getCredentials(
  identity: GetOpenIdTokenForDeveloperIdentityResponse,
) {
  if (!identity.IdentityId || !identity.Token) {
    throw errorManager.getServerError(
      'getCredentials',
      `Cognito: No identity provider`,
    );
  }

  const sts = new STSClient({ region: COGNITO_REGION });
  const params = {
    RoleSessionName: identity.IdentityId.replace(':', ''),
    RoleArn: COGNITO_ROLE_ARN,
    WebIdentityToken: identity.Token,
  };
  const command = new AssumeRoleWithWebIdentityCommand(params);
  let result;
  try {
    result = await sts.send(command);
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === 'InvalidSignatureException') {
        const message = `Cognito: KMS token signature is invalid. code=INVALID_KMS_TOKEN_SIGNATURE`;
        throw errorManager.getWarnError('getCredentials', message, {
          message,
        });
      }
    }
  }

  if (!result?.Credentials) {
    throw errorManager.getServerError(
      'getCredentials',
      `Cognito: No credentials available`,
    );
  }

  return { subject: identity.IdentityId, session: result.Credentials };
}

async function decryptCipherText(
  subject: string,
  session: Credentials,
  cipherText: Buffer,
) {
  const kms = getKmsClient(session);
  const params = {
    CiphertextBlob: cipherText,
    // The subject is the user's identity
    EncryptionContext: { sub: subject },
    KeyId: KMS_KEY_ID,
    EncryptionAlgorithm: 'SYMMETRIC_DEFAULT',
  };
  const command = new DecryptCommand(params);
  const decryptResponse = await kms.send(command);

  if (!decryptResponse.Plaintext) {
    await StorageUtils.removeItem('sessionId'); // invalidate session
    throw errorManager.getServerError(
      'getCredentials',
      `No plaintext response was retrieved when decrypting the encryption key`,
    );
  }

  // Retrieve the plaintext encryption key
  const plaintextEncryptionKey = decryptResponse.Plaintext;
  return Buffer.from(plaintextEncryptionKey).toString('hex');
}

export { decryptCipherText, getCredentials, getDataKey };
