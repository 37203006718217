import { MoonPayWalletCoreSDK } from '@moonpay-internal/wallets-core-sdk';
import { AuthorizationTokens } from 'src/v2/common/types';
import Storage from '../../../utils/storage';

export class WalletsCoreFactory {
  private static instance: MoonPayWalletCoreSDK;

  public static async getInstance(
    authorizationTokens?: AuthorizationTokens,
  ): Promise<MoonPayWalletCoreSDK> {
    if (this.instance) {
      this.instance.setAuthorizationTokens(authorizationTokens);

      return this.instance;
    }

    const isLocal = window.location.hostname === 'localhost';

    const instance = new MoonPayWalletCoreSDK({
      kmsConfig: {
        region: process.env.REACT_APP_COGNITO_REGION,
        cognitoRoleArn: process.env.REACT_APP_COGNITO_ROLE_ARN,
        kmsKeyId: process.env.REACT_APP_KMS_KEY_ID,
      },
      storage: Storage,
      environment: isLocal ? 'development' : 'production',
      authorizationTokens,
    });

    await instance.init();

    this.instance = instance;

    return instance;
  }
}
