import { Label, Text } from '@moonpay-widget/ui-kit';
import { FC } from 'react';
import WalletAddressComponent from 'src/components/WalletAddressComponent';

export type GenericNftSendTransactionProps = {
  to: string;
  from: string;
  nfts: {
    name: string;
    price: number;
  }[];
  networkFee: string;
  currencyCode: string;
};

const GenericNftSendTransaction: FC<GenericNftSendTransactionProps> = ({
  to,
  from,
  nfts,
  networkFee,
  currencyCode,
}) => {
  return (
    <>
      <WalletAddressComponent
        leftLabel="From"
        leftValue={from}
        rightLabel="To"
        rightValue={to}
      />
      <div className="flex mt-2 items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xl overflow-auto">
        {nfts.map((nft) => {
          return (
            <Text
              variant="footnote"
              as="div"
              className="flex my-4"
              key={nft.name}
            >
              <div className="float-left w-1/2">NFT to be sent</div>
              <div className="float-right w-1/2 text-right ">{`${nft.name}`}</div>
            </Text>
          );
        })}
        <Text
          as="div"
          variant="footnote"
          className="flex my-4 border-b pb-4 border-divider-nonOpaque"
        >
          <div className="float-left w-1/2">Blockchain fee</div>
          <div className="float-right w-1/2 text-right ">{`${
            networkFee ? '-' : ''
          } ${networkFee || '0'} ${currencyCode}`}</div>
        </Text>
        <Text variant="subhead" as="div" className="flex my-4">
          <div className="float-left w-1/2">
            {`Sending `}
            <b>{`${nfts.length} ${nfts.length > 1 ? 'NFTs' : 'NFT'}`}</b>
            {` for`}
          </div>
          <div className="float-right w-1/2 text-right font-bold">
            {`${networkFee || 0} ${currencyCode}`}
          </div>
        </Text>
      </div>
    </>
  );
};

export default GenericNftSendTransaction;
