import { EvmChainSpec } from 'src/v2/evm/types';

export enum SupportedErc20Function {
  Approve = 'approve',
  Transfer = 'transfer',
}

export enum SupportedEvmChain {
  Polygon = 'polygon',
  Ethereum = 'ethereum',
  Sepolia = 'sepolia',
  Amoy = 'amoy',
  Localhost = 'localhost',
}

export const EVM_CHAINS: { [chainName in SupportedEvmChain]: EvmChainSpec } = {
  [SupportedEvmChain.Ethereum]: {
    name: SupportedEvmChain.Ethereum,
    blockExplorerUrl: '',
    blockGasLimit: 30_000_000,
    chainId: 1,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [SupportedEvmChain.Sepolia]: {
    name: SupportedEvmChain.Sepolia,
    blockExplorerUrl: '',
    blockGasLimit: 30_000_000,
    chainId: 11155111,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [SupportedEvmChain.Amoy]: {
    name: SupportedEvmChain.Amoy,
    blockExplorerUrl: '',
    blockGasLimit: 20_000_000,
    chainId: 80002,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [SupportedEvmChain.Localhost]: {
    name: SupportedEvmChain.Localhost,
    blockExplorerUrl: '',
    blockGasLimit: 30_000_000,
    chainId: 10016,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [SupportedEvmChain.Polygon]: {
    name: SupportedEvmChain.Polygon,
    blockExplorerUrl: '',
    blockGasLimit: 30_000_000,
    chainId: 137,
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
};
