import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { v4 as uuidv4 } from 'uuid';
import logger from './logger';

const errorManager = new ErrorManager(__filename);

export type EventProperties = any;

export class EventTrackingUtils {
  private static SEGMENT_WRITE_KEY =
    process.env.REACT_APP_SECURE_WALLET_SEGMENT_WRITE_KEY;

  private static SEGMENT_URL =
    process.env.NODE_ENV === 'production'
      ? 'https://buy.moonpay.com/segapi/v1/t'
      : 'https://buy-sandbox.moonpay.com/segapi/v1/t';

  public static trackSegmentEvent(
    event: SegmentTrackEvent,
    properties: EventProperties = {},
  ): void {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.SEGMENT_WRITE_KEY}`,
    };

    const eventData = {
      timestamp: this.generateTimestamp(),
      event,
      properties,
      writeKey: this.SEGMENT_WRITE_KEY, // is this redundant with the bearer token?
      messageId: this.generateMessageId(),
    };

    const body = JSON.stringify(eventData);

    fetch(this.SEGMENT_URL, {
      method: 'POST',
      headers,
      body,
    })
      .then((response) => {
        if (!response.ok) {
          throw errorManager.getServerError(
            'trackSegmentEvent',
            `Failed to send Segment event: ${response.statusText}`,
          );
        }
      })
      .catch((error) => {
        logger.debug('Failed to send Segment event', {}, error);
      });
  }

  private static generateMessageId(): string {
    const uniqueId: string = uuidv4();
    const timestamp: number = Math.floor(Date.now() / 1000);
    return `${uniqueId}-${timestamp}`;
  }

  private static generateTimestamp(): string {
    return new Date().toISOString();
  }
}
