import {
  CreateWalletAddressResponse,
  CreateWalletResponse,
  GetEncryptionKeyResponse,
} from '../wallet/types/apiResponses';

const walletEncryptionKeyQuery = () => `
  query {
    walletEncryptionKey {
      id, key, iv, algorithm
    }
  }
`;

const createWalletQuery = () => `
  mutation {
    createWallet {
        walletEncryptionKey {
            id, key, iv, algorithm
        }
    }
  }
`;

const createWalletAddressQuery = (
  queryParameters: [walletAddress: string, chain: string],
) => {
  const [walletAddress, chain] = queryParameters;
  return `
  mutation {
    createWalletAddress(walletAddress: "${walletAddress}", chain: "${chain}") {
      success
    }
  }
`;
};

export type LoggedInCustomerBasicInfoQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    liveMode: boolean;
    address: {
      __typename?: 'Address';
      country?: string | null;
      state?: string | null;
    };
  };
};

const getCustomerQuery = () => `
  query {
    me {
      id
      email
      firstName
      lastName
      liveMode
      address {
        country
        state
      }
    }
  }
`;

export type QueryInfo<TQqueryParameters> = {
  query: (parameters: TQqueryParameters) => string;
  getter: (data: any) => any;
};

const module = {
  walletEncryptionKey: {
    query: walletEncryptionKeyQuery,
    getter: (data: GetEncryptionKeyResponse) => data.walletEncryptionKey,
  } as QueryInfo<[]>,

  createWallet: {
    query: createWalletQuery,
    getter: (data: CreateWalletResponse) =>
      data.createWallet.walletEncryptionKey,
  } as QueryInfo<[]>,

  createWalletAddress: {
    query: createWalletAddressQuery,
    getter: (data: CreateWalletAddressResponse) =>
      data.createWalletAddress.success,
  } as QueryInfo<[string, string]>,

  getCustomer: {
    query: getCustomerQuery,
    getter: (data: LoggedInCustomerBasicInfoQuery) => data.me,
  } as QueryInfo<[]>,
};

export default module;
