import { GetOpenIdTokenForDeveloperIdentityResponse } from '@aws-sdk/client-cognito-identity';
import { Buffer } from 'buffer';
import { ErrorManager } from 'src/utils/errorManager';
import StorageUtils from 'src/utils/storage';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import logger from '../../../utils/logger';

const MOONPAY_API_BASE_URL = process.env
  .REACT_APP_MOONPAY_API_REST_URL as string;

const errorManager = new ErrorManager(__filename);

export interface WalletConnectionInput {
  walletAddress: string;
  phoneNumber?: string;
  email?: string;
}

export interface AccountTermsPrivacyDetails {
  name: string;
  termsLink?: string;
  privacyLink?: string;
}

export interface CustomerAccountConnection {
  connected: boolean;
  tosAccepted: boolean;
  privacyPolicyAccepted: boolean;
}

interface KmsWalletDetail {
  address: string;
  chain: string;
}

export interface KmsWalletData {
  encryptedWallet: string;
  cipherText: Buffer;
  iv: Buffer;
}

interface KmsWalletResponse {
  encryptedWallet: string;
  cipherText: string;
  iv: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  kmsWalletDetails: KmsWalletDetail[];
}

interface CreateKmsWalletTransactionRequest {
  transactionType: KmsWalletTransactionType;
  transactionMetadata: CryptoTransactionMetadata | NftTransactionMetadata;
  networkCode: string;
  transactionHash: string;
  from: string;
  to: string | null;
  transactionFee: string;
  transactionFeeUnits: string;
}

export enum KmsWalletTransactionType {
  crypto = 'crypto',
}

interface CryptoTransactionMetadata {
  baseCurrency: any;
  baseCurrencyAmount: string;
}

interface NftTransactionMetadata {
  contractAddress: string;
  tokenId: number;
  tokenAmount: number;
}

async function getApiCustomerHeaders(walletToken = '') {
  const headers: {
    'Content-Type': string;
    'X-CSRF-Token'?: string;
    Authorization?: string;
    'X-Wallet-Token'?: string;
  } = {
    'Content-Type': 'application/json',
  };

  if (walletToken) {
    headers['X-Wallet-Token'] = walletToken;
  }

  const csrfToken = await WalletHelpers.getCsrfToken();
  const customerToken = await StorageUtils.get('customerToken');
  if (customerToken) {
    headers.Authorization = `Bearer ${customerToken}`;
  } else if (csrfToken) {
    // Set csrfToken if we have it from cookie
    // otherwise, we assume it will rely on Authorization header
    headers['X-CSRF-Token'] = csrfToken;
  }

  return headers;
}

async function fetchApiCustomer(
  path: string,
  method = 'GET',
  body?: any,
  clearOnUnauthorized = true,
): Promise<Response> {
  const headers = await getApiCustomerHeaders();

  logger.info(`Calling MoonPay API`, {
    headerKeys: Object.keys(headers),
    hasWalletToken: false,
    path,
    method,
  });

  const req = { method, headers, credentials: 'include' as const, body };
  const resp = await fetch(`${MOONPAY_API_BASE_URL}${path}`, req);

  logger.info('Received response from MoonPay API call', {
    headerKeys: Object.keys(headers),
    path,
    method,
    respStatus: resp.status,
  });

  if (resp.status === 401 && clearOnUnauthorized) {
    // If we get a 401, it means the customer token is invalid
    // We should clear the customer token and try again
    // we use the clearOnUnauthorized flag to prevent infinite loops
    await StorageUtils.removeItem('customerToken');
    await StorageUtils.removeItem('walletToken');
    logger.info(`Retrying calling MoonPay API after clearing auth tokens`, {
      headerKeys: Object.keys(headers),
      hasWalletToken: false,
      path,
      method,
    });
    return fetchApiCustomer(path, method, body, false);
  }

  return resp;
}

async function fetchWalletApiCustomer(
  path: string,
  method = 'GET',
  walletToken: string,
  body?: any,
  clearOnUnauthorized = true,
): Promise<Response> {
  const headers = await getApiCustomerHeaders(walletToken);

  logger.info(`Calling MoonPay API`, {
    headerKeys: Object.keys(headers),
    hasWalletToken: true,
    path,
    method,
  });

  const req = { method, headers, credentials: 'include' as const, body };
  const resp = await fetch(`${MOONPAY_API_BASE_URL}${path}`, req);

  if (resp.status === 401 && clearOnUnauthorized) {
    // If we get a 401, it means the wallet token is invalid
    // We should clear the customer token and try again
    // we use the clearOnUnauthorized flag to prevent infinite loops
    await StorageUtils.removeItem('customerToken');
    await StorageUtils.removeItem('walletToken');
    return fetchWalletApiCustomer(path, method, '', body, false);
  }

  return resp;
}

async function fetchApiAccount(
  path: string,
  apiKey: string,
  method = 'GET',
  body?: any,
) {
  if (!apiKey) {
    throw errorManager.getServerError(
      'chainfetchApiAccountdValidate',
      `API key is required`,
      {
        path,
        method,
        body,
      },
    );
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Api-Key ${apiKey}`,
  };

  const req = { method, headers, credentials: 'include' as const, body };

  return fetch(`${MOONPAY_API_BASE_URL}${path}`, req);
}

async function fetchApiAccountAndCustomer(
  path: string,
  apiKey: string,
  method = 'GET',
  body?: any,
) {
  if (!apiKey) {
    throw errorManager.getServerError(
      'fetchApiAccountAndCustomer',
      `API key is required`,
      {
        path,
        method,
        body,
      },
    );
  }

  const headers: {
    'Content-Type': string;
    'X-CSRF-Token'?: string;
    Authorization?: string;
  } = {
    'Content-Type': 'application/json',
  };

  const csrfToken = await WalletHelpers.getCsrfToken();
  const customerToken = await StorageUtils.get('customerToken');
  if (customerToken) {
    headers.Authorization = `Bearer ${customerToken}`;
  } else if (csrfToken) {
    // Set csrfToken if we have it from cookie
    // otherwise, we assume it will rely on Authorization header
    headers['X-CSRF-Token'] = csrfToken;
  }

  const queryParams = new URLSearchParams({ apiKey }).toString();
  const req = { method, headers, credentials: 'include' as const, body };
  if (method === 'GET') {
    return fetch(`${MOONPAY_API_BASE_URL}${path}?${queryParams}`, req);
  }
  return fetch(`${MOONPAY_API_BASE_URL}${path}`, req);
}

export const setEncryptedKmsWallet = async (
  cipherText: string,
  iv: string,
  encryptedWallet: string,
) => {
  return fetchApiCustomer(
    '/wallets/v1/user/encrypted',
    'POST',
    JSON.stringify({ cipherText, iv, encryptedWallet }),
  );
};

export const getEncryptedKmsWallet = async (): Promise<
  KmsWalletData | undefined
> => {
  let resp: Response;
  const walletToken = await StorageUtils.get('walletToken');
  // If walletToken is present in indexeddb, use it to fetch wallet
  // otherwise use existing /encrypted API that only requires customerToken
  if (walletToken) {
    resp = await fetchWalletApiCustomer(
      '/wallets/v1/user/encrypted_v2',
      'GET',
      walletToken,
    );
    if (resp.status === 401) {
      resp = await fetchApiCustomer('/wallets/v1/user/encrypted');
    }
  } else {
    resp = await fetchApiCustomer('/wallets/v1/user/encrypted');
  }

  // 404 from api means no wallet, else always throw error
  if (resp.status === 404) {
    return undefined;
  }
  if (resp.status !== 200) {
    // This can be cleaned up, we just need to be able to read the body in our logs
    // We need to clone the response because we can only read the body once
    const responseClone = resp.clone();
    throw errorManager.getServerError(
      'getEncryptedKmsWallet',
      `Failed to get encrypted wallet`,
      {
        response: await responseClone.text(),
        responseStatus: responseClone.status,
      },
    );
  }

  const result = (await resp.json()) as KmsWalletResponse;
  return {
    encryptedWallet: result.encryptedWallet,
    iv: Buffer.from(result.iv, 'hex'),
    cipherText: Buffer.from(result.cipherText, 'hex'),
  };
};

export const shouldUseKms = async (): Promise<boolean> => {
  const useKms = await fetchApiCustomer('/wallets/v1/user/use-kms');
  return (await useKms.json()) as boolean;
};

export const getIdentity = async () => {
  const resp = await fetchApiCustomer('/wallets/v1/cognito/session');
  return (await resp.json()) as GetOpenIdTokenForDeveloperIdentityResponse;
};

export const addKmsWalletDetails = async (
  address: string,
  chain: string,
): Promise<KmsWalletDetail[]> => {
  const resp = await fetchApiCustomer(
    '/wallets/v1/user/wallet-details',
    'POST',
    JSON.stringify({ address, chain }),
  );

  if (!resp.ok) {
    throw errorManager.getServerError(
      'addKmsWalletDetails',
      `Failed to add kms wallet details`,
      {
        response: resp,
        responseStatus: resp.status,
        address,
        chain,
      },
    );
  }

  const result = (await resp.json()) as KmsWalletResponse;

  return result.kmsWalletDetails;
};

export const getKmsWalletDetails = async (): Promise<KmsWalletDetail[]> => {
  let resp;
  try {
    resp = await fetchApiCustomer('/wallets/v1/user/wallet-details');
  } catch (error: any) {
    logger.error(`Failed to fetch user wallet details`, { err: error }, error);
    throw error;
  }

  if (!resp?.ok) {
    throw errorManager.getServerError(
      'getKmsWalletDetails',
      `Failed to get kms wallet details`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as KmsWalletDetail[];
};

export const checkCustomerAccountConnection = async (
  apiKey: string,
): Promise<CustomerAccountConnection> => {
  const resp = await fetchApiAccountAndCustomer('/v1/connection', apiKey);

  if (!resp.ok) {
    throw errorManager.getServerError(
      'checkCustomerAccountConnection',
      `Failed to get wallet connection`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as CustomerAccountConnection;
};

export const getAccountTermsAndPrivacy = async (
  apiKey: string,
): Promise<AccountTermsPrivacyDetails> => {
  const resp = await fetchApiAccountAndCustomer(
    '/v1/connection/account-details',
    apiKey,
  );

  if (!resp.ok) {
    throw errorManager.getServerError(
      'getAccountTermsAndPrivacy',
      `Failed to get account details`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as AccountTermsPrivacyDetails;
};

export const setWalletConnection = async (
  apiKey: string,
  walletConnection: WalletConnectionInput,
) => {
  const walletConnectionWithApiKey = {
    ...walletConnection,
    apiKey,
  };
  return fetchApiAccountAndCustomer(
    '/v1/connection',
    apiKey,
    'POST',
    JSON.stringify(walletConnectionWithApiKey),
  );
};

/*
  We consistently see a large volume of status 0 at this endpoint,
  which indicates a failed preflight request. This could be due to
    - CORS issues
    - Network errors (e.g. DNS resolution)
    - Ad Blockers
*/
export const getAllowedAncestorOrigins = async (
  apiKey: string,
): Promise<string[]> => {
  try {
    const resp = await fetchApiAccount(
      '/wallets/v1/user/allowed-ancestors',
      apiKey,
    );

    if (!resp.ok) {
      throw errorManager.getClientError(
        'getAllowedAncestorOrigins',
        `Failed to get allowed ancestor origins`,
        { response: resp, responseStatus: resp.status, apiKey },
      );
    }

    const result = (await resp.json()) as string;

    return result.split(',').map((origin) => origin.trim());
  } catch (error) {
    throw errorManager.getClientError(
      'getAllowedAncestorOrigins',
      `Failed to get allowed ancestor origins`,
      { error },
    );
  }
};

export const createKmsWalletTransaction = async (
  data: CreateKmsWalletTransactionRequest,
): Promise<void> => {
  try {
    const resp = await fetchApiCustomer(
      '/wallets/v1/kms-wallet-transactions',
      'POST',
      JSON.stringify(data),
    );

    if (!resp.ok) {
      const responseBody = await resp.text();
      logger.error(`Failed to create kms wallet transaction.`, {
        status: resp.status,
        responseBody,
      });
    }
  } catch (error) {
    logger.error(`Unexpected error in create transaction:`, {}, error as Error);
  }
};
