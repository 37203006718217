import type { Env } from '@moonpay/logger';
import { createInSpanDecorator, Logger } from '@moonpay/logger';
import packageVersion from '../../package.json';

const logger = new Logger();

let mode: 'console' | 'standard' | 'silent' =
  process.env.REACT_APP_LOG_PROVIDER === 'console' ? 'console' : 'standard';
if (process.env.NODE_ENV === 'test') {
  mode = 'silent';
}

let env = process.env.REACT_APP_ENVIRONMENT || '';
if (!['dev', 'staging', 'production'].includes(env)) {
  env = 'dev';
}

logger.init({
  packageVersion: packageVersion ? packageVersion.version : undefined,
  service: 'secure-wallet',
  env: env as Env,
  mode,
});

export const InSpan = createInSpanDecorator(logger);

export default logger;
