export enum SolanaNetworkEnvironment {
  Mainnet = 'mainnet',
  Devnet = 'devnet',
  Local = 'local',
}

export interface WalletApiSolanaBalanceResponse {
  data: {
    balance: {
      value: string;
      unit: string;
    };
  };
}

export enum SolanaMethods {
  Connect = 'sol_connect',
  TransferTransaction = 'sol_sendTransferTransaction',
  GetBalance = 'sol_getBalance',
  SignMessage = 'sol_signMessage',
  SwitchNetwork = 'sol_switchNetwork',
}
