import { WalletGetRequestPayload } from '@moonpay-widget/dhw/src/types/messages';
import {
  SECURE_WALLET_SUPPORTED_NETWORKS,
  WalletDetail,
  WalletDetails,
} from '@moonpay/login-common';
import { switchActiveChainId } from 'src/utils/activeChain';
import StorageUtils from 'src/utils/storage';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import logger from '../../utils/logger';
import addGetWalletMessageEventListener from './addGetWalletMessageEventListener';

export type RegisterGetWalletMessageHandlerParams = {
  walletStorage: WalletStorage;
  onSuccess: (wallets: WalletDetails, id?: string) => void; // Change the signature to return a list
  onError: (error: any, id?: string) => void;
  origins: string[];
};

export type HandleGetWalletMessageParams = WalletGetRequestPayload &
  RegisterGetWalletMessageHandlerParams;

export const getWalletMessageHandler = async ({
  onSuccess,
  onError,
  customerToken: customerTokenPayload,
  walletToken: walletTokenPayload,
  networks = SECURE_WALLET_SUPPORTED_NETWORKS,
  walletStorage,
  isMainnet = true,
  id,
}: HandleGetWalletMessageParams) => {
  const wallets: WalletDetail[] = [];
  // Store csrf, customer token, and wallet token to indexeddb, if necessary
  if (customerTokenPayload)
    await StorageUtils.setCustomerToken(customerTokenPayload);
  if (walletTokenPayload) await StorageUtils.setWalletToken(walletTokenPayload);

  for (const network of networks) {
    try {
      switchActiveChainId(walletStorage, network, isMainnet);

      // TODO: fix
      // eslint-disable-next-line no-await-in-loop
      const wallet = await WalletService.restoreWallet(network, walletStorage);
      if (wallet?.address) {
        wallets.push({ address: wallet.address, network });
      }
    } catch (error) {
      logger.error(
        'Error occurred whilst registering the getWalletMessageHandler',
        { error },
      );
      onError(error, id);
    }
  }

  onSuccess({ wallets }, id);
};

/* istanbul ignore next */
// REASON:
// This code is a proxy for other code and should be tested from there or moved.
const registerGetWalletMessageHandler = (
  params: RegisterGetWalletMessageHandlerParams,
) => {
  addGetWalletMessageEventListener(
    (payload: WalletGetRequestPayload) =>
      getWalletMessageHandler({
        ...params,
        ...payload,
      }),
    params.origins,
  );
};

export default registerGetWalletMessageHandler;
