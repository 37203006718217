// These are pseudo-methods that we expose only to our MoonPay clients
const MOONPAY_ONLY_METHODS = ['btc_sellTransaction'];
// These are the domain origins that are allowed to call the methods above
const MOONPAY_DOMAINS = ['moonpay.com', 'moonpay-dev.com'];

interface IsMethodAllowedForOriginProps {
  method: string;
  origin: string;
}
export const isMethodAllowedForOrigin = ({
  method,
  origin,
}: IsMethodAllowedForOriginProps) => {
  /**
   * If the given method is listed in MOONPAY_ONLY_METHODS, then we need to
   * perform an extra check to ensure this method is being called by a known
   * MoonPay origin domain.
   */
  if (!MOONPAY_ONLY_METHODS.includes(method)) {
    return true;
  }
  if (/^https?:\/\/localhost(:\d*)?/.test(origin)) {
    // Always allow localhost as it could have any port attached to it
    return true;
  }
  return !!MOONPAY_DOMAINS.find((x) => origin.endsWith(x));
};
