import axios from 'axios';
import logger from 'src/utils/logger';
import { NetworkEvmChain } from '../../../../../types/NetworkChain';

type ExtensionValue = string | number | boolean | null | undefined;

export interface TokenInfo {
  readonly chainId: number;
  readonly address: string;
  readonly name: string;
  readonly decimals: number;
  readonly symbol: string;
  readonly logoURI?: string;
  readonly tags?: string[];
  readonly extensions?: {
    readonly [key: string]:
      | {
          [key: string]:
            | {
                [key: string]: ExtensionValue;
              }
            | ExtensionValue;
        }
      | ExtensionValue;
  };
}

export interface Version {
  readonly major: number;
  readonly minor: number;
  readonly patch: number;
}

export interface Tags {
  readonly [tagId: string]: {
    readonly name: string;
    readonly description: string;
  };
}

export interface TokenList {
  readonly name: string;
  readonly timestamp: string;
  readonly version: Version;
  readonly tokens: TokenInfo[];
  readonly keywords?: string[];
  readonly tags?: Tags;
  readonly logoURI?: string;
}

const TOKEN_LIST = {
  name: 'MoonPay Token List',
  timestamp: '2023-01-01T00:00:00.000Z',
  version: { major: 1, minor: 0, patch: 0 },
  tokens: [
    {
      chainId: undefined,
      address: undefined,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: NetworkEvmChain.Sepolia,
      address: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: 80001,
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: NetworkEvmChain.Sepolia,
      address: '0xf08A50178dfcDe18524640EA6618a1f965821715',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI:
        'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png',
    },
    {
      chainId: 80001,
      address: '0x1Af2844A588759D0DE58abD568ADD96e4b3daF3A',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI:
        'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png',
    },
    {
      chainId: NetworkEvmChain.Sepolia,
      address: '0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
    {
      name: 'USDCoin',
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      symbol: 'USDC',
      decimals: 6,
      chainId: NetworkEvmChain.Mainnet,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    },
    {
      name: 'Tether USD',
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      symbol: 'USDT',
      decimals: 6,
      chainId: NetworkEvmChain.Mainnet,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
  ],
};

const TOKEN_LIST_URL = 'https://cloudflare-ipfs.com/ipns/tokens.uniswap.org';
const TOKEN_LIST_URL_FALLBACK = 'https://ipfs.io/ipns/tokens.uniswap.org';

let cachedTokenList: TokenList;

// TODO: store in local storage? and call on wallet startup?
export async function getTokenList() {
  if (cachedTokenList) return cachedTokenList;

  let tokenData;
  try {
    // First attempt to fetch from the primary URL
    tokenData = await axios.get(TOKEN_LIST_URL);
  } catch (e: any) {
    logger.warn(
      'Failed to fetch token list from the primary URL',
      {
        error: e,
        responseStatus: e?.response?.status,
        responseData: e?.response?.data,
        responseText: e?.response?.text,
      },
      e,
    );

    // Attempt to fetch from the fallback URL
    try {
      tokenData = await axios.get(TOKEN_LIST_URL_FALLBACK);
    } catch (fallbackError: any) {
      logger.error(
        'Failed to fetch token list',
        {
          error: fallbackError,
          responseStatus: fallbackError?.response?.status,
          responseData: fallbackError?.response?.data,
          responseText: fallbackError?.response?.text,
        },
        fallbackError,
      );

      // Default to hardcoded list if both requests fail
      tokenData = { data: { tokens: TOKEN_LIST } };
    }
  }

  const tokenList = tokenData?.data;
  const tokens = (
    Array.isArray(tokenList?.tokens) ? tokenList?.tokens : []
  ).concat(TOKEN_LIST.tokens);

  cachedTokenList = { ...tokenList, tokens };
  return cachedTokenList;
}
