import { WalletMessageType } from '@moonpay/login-common';
import { createMessageHandler } from '../hooks/useHandleIncomingMessages';
import { HandleWalletMessageProxyMessageParams } from './registerWalletMessageProxyHandler';

const addWalletMessageProxyEventListener = (
  handler: (
    payload: HandleWalletMessageProxyMessageParams,
  ) => void | Promise<void>,
  origins: string[],
) => {
  const walletMessageProxyMessageHandler = createMessageHandler({
    messageType: WalletMessageType.MESSAGE_PROXY,
    messageHandler: handler,
    origins,
  });

  window.addEventListener('message', walletMessageProxyMessageHandler);
};

export default addWalletMessageProxyEventListener;
