import { TokenInfo } from 'src/messages/walletProxy/methods/sendTransaction/erc20/tokenList';
import { ActiveChainsStorage } from 'src/wallet/storage/ActiveChainStorage';
import { BaseStorage } from 'src/wallet/storage/BaseStorage';
import { ChainsStorage } from 'src/wallet/storage/ChainsStorage';
import { ConnectionsStorage } from 'src/wallet/storage/ConnectionsStorage';
import { ConnectionStorageFactory } from 'src/wallet/storage/ConnectionsStorageFactory';

const WALLET_KEY = 'moonpay-wallet';
const TOKEN_LIST_KEY = 'token-list';

export class WalletStorage {
  chains: ChainsStorage;

  encryptedWallet: BaseStorage<string>;

  activeChainId: ActiveChainsStorage;

  connections: ConnectionsStorage;

  tokens: BaseStorage<TokenInfo[]>;

  constructor() {
    this.chains = new ChainsStorage();
    this.connections = ConnectionStorageFactory.getInstance();
    this.encryptedWallet = new BaseStorage({
      key: WALLET_KEY,
      storage: 'sessionStorage',
      defaultValue: '',
      readThrough: true,
    });
    this.activeChainId = new ActiveChainsStorage();
    this.tokens = new BaseStorage({
      key: TOKEN_LIST_KEY,
      defaultValue: [],
    });
  }

  reset() {
    this.chains.reset();
    this.connections.reset();
    this.activeChainId.reset();
  }
}
