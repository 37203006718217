import { Expose } from 'class-transformer';

export interface ISolanaSignMessageResponse {
  signature: string;
}

export class SolanaSignMessageResponse {
  @Expose()
  public readonly signature!: string;

  constructor(data: Partial<ISolanaSignMessageResponse>) {
    Object.assign(this, data);
  }
}
