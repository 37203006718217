export enum NetworkEvmChain {
  Mainnet = 1,
  Sepolia = 11155111,
  Polygon = 137,
  Mumbai = 80001,
  Emulator = 31337,
}

export enum NetworkSolChain {
  Mainnet = 900,
  Devnet = 901,
  Local = 902,
}

export enum NetworkBitcoinChain {
  Mainnet = 0,
  Testnet = 1,
}
