export enum BitcoinNetworkEnvironment {
  Mainnet = 'mainnet',
  Testnet = 'testnet',
}

export const BitcoinNetworkEnvironmentToChainIdMap: Record<
  BitcoinNetworkEnvironment,
  number
> = {
  [BitcoinNetworkEnvironment.Mainnet]: 0,
  [BitcoinNetworkEnvironment.Testnet]: 1,
} as const;

export interface WalletApiBitcoinBalanceResponse {
  data: {
    balance: {
      value: string;
      unit: BitcoinUnit;
    };
  };
}

export enum BitcoinMethods {
  Accounts = 'btc_accounts',
  SwitchNetwork = 'btc_switchNetwork',
  SendTransaction = 'btc_sendTransaction',
  GetBalance = 'btc_getBalance',
}

export enum BitcoinUnit {
  Btc = 'btc',
  Satoshi = 'satoshi',
}
