import { validate as validateBitcoinAddress } from 'bitcoin-address-validation';

import {
  ValidationOptions,
  registerDecorator,
  ValidationArguments,
} from 'class-validator';

export function IsBitcoinAddress(validationOptions?: ValidationOptions) {
  return function validate(object: any, propertyName: string) {
    registerDecorator({
      name: 'IsBitcoinAddress',
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(address: any) {
          if (!address) {
            return false;
          }

          return validateBitcoinAddress(address);
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a valid Bitcoin wallet address`;
        },
      },
    });
  };
}
