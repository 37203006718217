import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import getTargetOrigin from '../getTargetOrigin';
import logger from '../../utils/logger';

type CreateMessageEventSignature<T = void | Promise<void>> = (props: {
  messageType: string;
  messageHandler: (payload: any) => T;
  origins?: string[];
}) => (msg: MessageEvent) => T;

const logHandledMessage = (
  startTime: number,
  logData: Record<string, unknown>,
): void => {
  logger.info('Handled message', {
    ...logData,
    durationInMs: performance.now() - startTime,
  });
};

const logFailedMessage = (
  startTime: number,
  logData: Record<string, unknown>,
  error: any,
): void => {
  logger.info('Message handler failed', {
    ...logData,
    durationInMs: performance.now() - startTime,
    error,
  });
};

export const createMessageHandler: CreateMessageEventSignature = ({
  messageType,
  messageHandler,
  origins = [],
}) => {
  const targetOrigin = getTargetOrigin(origins);

  return (msg: MessageEvent): void | Promise<void> => {
    if (msg.origin !== targetOrigin) {
      return;
    }

    if (msg.data.type !== messageType) {
      return;
    }
    const id = msg.data.id ?? uuidv4();

    const logData = {
      ...msg.data,
      messageId: id,
      messageType,
      origin: msg.origin,
    };
    // eslint-disable-next-line consistent-return
    return logger.withSpan(`Message handler: ${messageType}`, () => {
      logger.info('Received message', logData);

      const startTime = performance.now();

      let response: void | Promise<void>;
      try {
        response = messageHandler({ ...msg.data, id });
      } catch (error) {
        logFailedMessage(startTime, logData, error);
        throw error;
      }
      // check if response is promise
      if (response && typeof response.then === 'function') {
        return response
          .then(() => logHandledMessage(startTime, logData))
          .catch((error) => {
            logFailedMessage(startTime, logData, error);
            throw error;
          });
      }
      return logHandledMessage(startTime, logData);
    });
  };
};

/**
 * Registers a handler to be invoked when a message is received.
 */
const useHandleIncomingMessages: (
  props: Parameters<CreateMessageEventSignature>[0],
) => void = (args) => {
  useEffect(() => {
    const handleIncomingMessages = createMessageHandler(args);

    window.addEventListener('message', handleIncomingMessages);
    return () => window.removeEventListener('message', handleIncomingMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useHandleIncomingMessages;
