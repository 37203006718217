/* eslint-disable no-restricted-globals */
import logger from 'src/utils/logger';
import { MESSAGE_REQUEST_METADATA_KEY } from 'src/v2/lib/constants';
import { ResponseError } from 'src/v2/lib/types';

interface MethodHandler {
  target: any;
  propertyKey: string | symbol;
}

// TODO: DELETE THIS ONCE LEGACY SOLANA CONTROLLER IS NO LONGER USED
export const sendWalletMessageProxyResponse = ({
  result,
  error,
  handler,
}: {
  handler: MethodHandler;
  result?: any;
  error?: ResponseError;
}) => {
  const requestContext = Reflect.getMetadata(
    MESSAGE_REQUEST_METADATA_KEY,
    handler.target,
    handler.propertyKey,
  );

  const data = {
    type: 'wallet-message-proxy-response',
    method: requestContext?.request?.method,
    result,
    error,
    id: requestContext?.id,
  };

  logger.info('Legacy V2 handler sending response to parent', data);

  window.parent.postMessage(data, '*');
};

export const sendMoonPayWalletResponse = ({
  result,
  error,
  handler,
}: {
  handler: MethodHandler;
  result?: any;
  error?: ResponseError;
}) => {
  const requestContext = Reflect.getMetadata(
    MESSAGE_REQUEST_METADATA_KEY,
    handler.target,
    handler.propertyKey,
  );

  const data = {
    type: 'moonpay-wallet-response',
    method: requestContext?.request?.method,
    result,
    error,
    id: requestContext?.id,
  };

  logger.info('V2 handler sending response to parent', data);

  // TODO: add target origin
  window.parent.postMessage(data, '*');
};

export const sendPromptStateChangeResponse = ({
  action,
  network,
}: {
  action: string;
  network: string;
}) => {
  const data = {
    type: 'wallet-prompt-change',
    result: {
      action,
      network,
    },
  };

  logger.info('V2 handler sending prompt change response to parent', data);

  window.parent.postMessage(data, '*');
};
