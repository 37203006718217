import {
  Button,
  ConnectionRequestIcon,
  CurrencyXCurrency,
  Icon,
  Text,
} from '@moonpay-widget/ui-kit';
import { WalletNetwork } from '@moonpay/login-common';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import { SolanaNetworkEnvironment } from 'src/v2/solana/types';

export interface SolanaSwitchNetworkProps {
  onApprove: () => void;
  onReject: () => void;
  fromNetworkEnvironment: SolanaNetworkEnvironment;
  toNetworkEnvironment: SolanaNetworkEnvironment;
}

export const SolanaSwitchNetworkPrompt: FC<SolanaSwitchNetworkProps> = ({
  onApprove,
  onReject,
  fromNetworkEnvironment,
  toNetworkEnvironment,
}) => {
  const { t } = useTranslation();

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={ConnectionRequestIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-2 mb-1 text-center" variant="title1" bold>
          {t(strings.prompts.solana.switchNetwork.title)}
        </Text>

        <Text
          variant="subhead"
          className="justify-center text-center text-label-secondary mt-5 mb-6"
        >
          {t(strings.prompts.solana.switchNetwork.subTitle)}
        </Text>

        <CurrencyXCurrency
          currencyFrom={{
            name: fromNetworkEnvironment,
            icon: getChainIcon(WalletNetwork.Solana, fromNetworkEnvironment),
          }}
          currencyTo={{
            name: toNetworkEnvironment,
            icon: getChainIcon(WalletNetwork.Solana, toNetworkEnvironment),
          }}
        />

        <footer>
          <div className="flex dialog-actions flex-col items-center text-center">
            <div className="flex flex-row items-center justify-between px-2 pt-4 text-center dialog-actions">
              <Text variant="footnote" className="text-label-secondary">
                {t(strings.prompts.solana.switchNetwork.footer.title)}
              </Text>
            </div>

            <div className="flex flex-col-reverse items-center justify-between w-full px-4 pb-4 dialog-actions sm:flex-row">
              <Button
                variant="custom"
                className="mx-2 border-none bg-fill-secondary text-label-tertiary"
                margin="small"
                name="cancel"
                onClick={onReject}
              >
                <Text variant="headline" bold className="text-label-primary">
                  {t(
                    strings.prompts.solana.switchNetwork.footer
                      .cancelButtonText,
                  )}
                </Text>
              </Button>
              <Button
                variant="custom"
                className="mx-2 bg-btn-primary hover:bg-btn-hover disabled:bg-fill-secondary text-btn-label disabled:text-label-tertiary"
                margin="small"
                name="approve"
                onClick={onApprove}
              >
                <Text variant="headline" bold>
                  {t(
                    strings.prompts.solana.switchNetwork.footer
                      .approvalButtonText,
                  )}
                </Text>
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </PromptSheet>
  );
};
