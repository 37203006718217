import { WalletNetwork } from '@moonpay/login-common';
import { ethers, Wallet as EthersWallet } from 'ethers';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from '../../../../../utils/errorManager';
import { EventTrackingUtils } from '../../../../../utils/eventTracking';
import logger from '../../../../../utils/logger';
import { AbstractWallet } from '../../../../../wallet/types/Wallet';
import {
  createKmsWalletTransaction,
  KmsWalletTransactionType,
} from '../../../../../wallet/walletProvider/kms/kmsApi';

const errorManager = new ErrorManager(__filename);

type Params = {
  unsignedTransaction: { from: string; gas: ethers.BigNumberish } & Record<
    string,
    any
  >;
  wallet: EthersWallet;
  abstractWallet: AbstractWallet;
  network: WalletNetwork;
};

export async function executeEvmTransaction({
  unsignedTransaction,
  wallet,
  abstractWallet,
  network,
}: Params): Promise<string> {
  const { from, gas, ...unsignedTransactionWithoutFrom } = unsignedTransaction;
  let populatedTrx = unsignedTransactionWithoutFrom;
  try {
    unsignedTransactionWithoutFrom.gasLimit = gas;
    populatedTrx = await wallet.populateTransaction(
      unsignedTransactionWithoutFrom,
    );
  } catch (err: any) {
    logger.error('Failed to populate transaction', {
      err: err?.toString(),
      unsignedTransaction,
    });
    throw errorManager.getServerError(
      'sendTransactionExecute',
      `Failed to populate transaction`,
    );
  }
  const rawTransaction = await wallet.signTransaction(populatedTrx);
  const transaction = await wallet.provider.sendTransaction(rawTransaction);

  const transactionHash = transaction.hash;

  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.approveTransactionCompleted,
    {
      walletAddress: abstractWallet.address,
      walletNetwork: network,
      transactionHash,
    },
  );

  try {
    await createKmsWalletTransaction({
      transactionType: KmsWalletTransactionType.crypto,
      transactionMetadata: {
        baseCurrency: {
          code: 'eth',
          type: 'crypto',
          precision: 18,
        },
        baseCurrencyAmount: transaction.value.toString(),
      },
      networkCode: WalletNetwork.Ethereum,
      transactionHash,
      from: transaction.from,
      to: transaction.to ?? null,
      transactionFee: transaction.gasPrice?.toString() || '',
      transactionFeeUnits: 'gwei',
    });
  } catch (err: any) {
    logger.error('Failed to create kms wallet transaction', {
      error: err?.toString(),
      transactionHash,
      from,
      network: WalletNetwork.Ethereum,
    });
  }
  return transactionHash;
}
