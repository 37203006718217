import { Expose, Type } from 'class-transformer';

export interface IBitcoinAccountsResponse {
  addresses: string[];
}

export class BitcoinAccountsResponse {
  @Type(() => String)
  @Expose()
  public readonly addresses!: string[];

  constructor(data: Partial<IBitcoinAccountsResponse>) {
    Object.assign(this, data);
  }
}
