// eslint-disable-next-line max-classes-per-file
import logger from './logger';

export enum ErrorType {
  Client,
  Server,
  Database,
  Validation,
  Unknown,
  Ignored,
  Silent,
  Warn,
}

class HandledError extends Error {
  public type: ErrorType;

  public filename: string;

  public functionName: string;

  public message: string;

  public data?: any;

  public statusCode?: number;

  constructor(
    type: ErrorType,
    filename: string,
    functionName: string,
    message: string,
    data?: any,
    statusCode?: number,
  ) {
    super(message);
    this.type = type;
    this.filename = filename;
    this.functionName = functionName;
    this.message = message;
    this.data = data;
    this.statusCode = statusCode;
  }
}

export class ErrorManager {
  private readonly filename: string;

  constructor(filename: string) {
    this.filename = this.getFilename(filename);
  }

  public getClientError(
    functionName: string,
    message: string,
    data?: unknown,
    statusCode?: number,
  ): HandledError {
    return this.getError(
      ErrorType.Client,
      functionName,
      message,
      data,
      statusCode,
    );
  }

  public getServerError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Server, functionName, message, data);
  }

  public getSilentError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Silent, functionName, message, data);
  }

  public getWarnError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Warn, functionName, message, data);
  }

  public getDatabaseError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Database, functionName, message, data);
  }

  public getValidationError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Validation, functionName, message, data);
  }

  private getError(
    type: ErrorType,
    functionName: string,
    message: string,
    data?: any,
    statusCode?: number,
  ): HandledError {
    if (![ErrorType.Silent, ErrorType.Warn].includes(type)) {
      if (type === ErrorType.Client || type === ErrorType.Validation) {
        logger.info(message, data);
      } else {
        logger.error(message, data);
      }
    }
    if (type === ErrorType.Warn) {
      logger.warn(message, data);
    }

    return new HandledError(
      type,
      this.filename,
      functionName,
      message,
      data,
      statusCode,
    );
  }

  private getFilename(filename: string): string {
    let split;

    if (filename.includes('/')) {
      // Mac & Linux
      split = filename.split('/');
    } else if (filename.includes('\\')) {
      // Windows
      split = filename.split('\\');
    } else {
      return filename;
    }

    return split[split.length - 1];
  }
}
