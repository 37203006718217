import {
  Button,
  ConnectionRequestIcon,
  CurrencyXCurrency,
  Icon,
  Text,
} from '@moonpay-widget/ui-kit';
import { WalletNetwork } from '@moonpay/login-common';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import {
  BitcoinNetworkEnvironment,
  BitcoinNetworkEnvironmentToChainIdMap,
} from 'src/v2/bitcoin/types';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import PromptFooter from '../../common/prompts/prompt-footer';

export interface BitcoinSwitchNetworkProps {
  onApprove: () => void;
  onReject: () => void;
  fromNetworkEnvironment: BitcoinNetworkEnvironment;
  toNetworkEnvironment: BitcoinNetworkEnvironment;
}

function toHexString(num: number): string {
  return `0x${num.toString(16)}`;
}

export const BitcoinSwitchNetworkPrompt: FC<BitcoinSwitchNetworkProps> = ({
  onApprove,
  onReject,
  fromNetworkEnvironment,
  toNetworkEnvironment,
}) => {
  const { t } = useTranslation();
  const fromChainId =
    BitcoinNetworkEnvironmentToChainIdMap[fromNetworkEnvironment];
  const fromChainIdHex = toHexString(fromChainId);

  const toChainId = BitcoinNetworkEnvironmentToChainIdMap[toNetworkEnvironment];
  const toChainIdHex = toHexString(toChainId);

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={ConnectionRequestIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-2 mb-1 text-center" variant="title1" bold>
          {t(strings.prompts.bitcoin.switchNetwork.title)}
        </Text>

        <Text
          variant="subhead"
          className="justify-center text-center text-label-secondary mt-5 mb-6"
        >
          {t(strings.prompts.bitcoin.switchNetwork.subTitle)}
        </Text>

        <CurrencyXCurrency
          currencyFrom={{
            name: fromNetworkEnvironment,
            icon: getChainIcon(WalletNetwork.Bitcoin, fromChainIdHex),
          }}
          currencyTo={{
            name: toNetworkEnvironment,
            icon: getChainIcon(WalletNetwork.Bitcoin, toChainIdHex),
          }}
        />

        <PromptFooter
          aboveButtonText={t(
            strings.prompts.bitcoin.switchNetwork.footer.title,
          )}
          approveButtonText={t(
            strings.prompts.bitcoin.switchNetwork.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.bitcoin.switchNetwork.footer.cancelButtonText,
          )}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
