import { WalletNetwork } from '@moonpay/login-common';
import { createElement } from 'react';
import ReactDOM from 'react-dom';
import { SecureWalletCommonErrors } from 'src/v2/common/errors/error-codes';
import { MoonPayWalletError } from 'src/v2/common/errors/moonpay-wallet-error';
import { PromptType, promptMap } from 'src/v2/common/prompts/prompt-map';
import { sendPromptStateChangeResponse } from 'src/v2/lib/utils/response-utils';

// TODO: find a better place for these types and maps

// interface PromptProps {
//   onApprove: () => void;
//   onReject: () => void;
// }

// // TODO: this is just for testing
// interface SolanaConnectPromptProps extends PromptProps {
//   test: string;
// }

// export const promptMap = {
//   [PromptType.SolanaConnectPrompt]: {
//     component: SolanaApproveConnection,
//     props: SolanaConnectPromptProps,
//   },
// };

interface PromptManagerResponse {
  approved: boolean;
}

interface PromptManagerOptions {
  network: WalletNetwork;
}

export class PromptManager {
  private readonly network: WalletNetwork;

  constructor(options: PromptManagerOptions) {
    this.network = options.network;
  }

  public async showPrompt(
    promptName: PromptType,
    props?: any,
  ): Promise<PromptManagerResponse> {
    return new Promise((resolve, reject) => {
      const prompt = promptMap[promptName] as any;

      const root = document.getElementById('root')!;
      const promptContainer = document.createElement('div');
      const promptInner = document.createElement('div');

      promptContainer.id = 'prompt-container';
      promptContainer.style.position = 'fixed';
      promptContainer.style.top = '0';
      promptContainer.style.left = '0';
      promptContainer.style.width = '100vw';
      promptContainer.style.height = '100vh';
      promptContainer.style.display = 'flex';
      promptContainer.style.justifyContent = 'center';
      promptContainer.style.alignItems = 'center';

      root.appendChild(promptContainer);
      promptContainer.appendChild(promptInner);

      const onClose = () => {
        ReactDOM.unmountComponentAtNode(promptContainer);
        root.removeChild(promptContainer);
      };

      const onApprove = () => {
        onClose();

        props?.onApprove?.();

        sendPromptStateChangeResponse({
          action: 'approved',
          network: this.network,
        });

        resolve({ approved: true });
      };

      const onReject = () => {
        onClose();

        props?.onReject?.();

        sendPromptStateChangeResponse({
          action: 'rejected',
          network: this.network,
        });

        reject(
          new MoonPayWalletError(
            SecureWalletCommonErrors.USER_REJECTED_REQUEST,
          ),
        );
      };

      const element = createElement(prompt, {
        ...props,
        onApprove,
        onReject,
      });

      ReactDOM.render(element, promptInner);

      sendPromptStateChangeResponse({
        action: 'ready',
        network: this.network,
      });
    });
  }
}
