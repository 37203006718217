import { Icon, SignatureIcon, Text } from '@moonpay-widget/ui-kit';
import bs58 from 'bs58';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import { MoonPayWalletError, SolanaWalletErrors } from '../../common/errors';
import PromptFooter from '../../common/prompts/prompt-footer';
import PromptSheet from '../../common/prompts/prompt-sheet';

export interface SolanaSigningMessageProps {
  message: string;
  onApprove: () => void;
  onReject: () => void;
}

const getDisplayMessage = (message: string): string => {
  let messageBuffer;

  try {
    messageBuffer = bs58.decode(message);
  } catch (e) {
    throw new MoonPayWalletError(SolanaWalletErrors.SOL_WALLET_DECODE_ERROR);
  }

  return Buffer.from(messageBuffer).toString('utf8');
};

export const SolanaSigningMessagePrompt: FC<SolanaSigningMessageProps> = ({
  message,
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();
  const text = [getDisplayMessage(message)];
  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top" id="helloooo-12369">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={SignatureIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {t(strings.prompts.signatureRequest.title)}
        </Text>

        <Text variant="footnote" className="text-label-secondary mt-6">
          {t(strings.prompts.signatureRequest.subTitle)}
        </Text>
        <div className="px-4 mb-8 mt-2 bg-system-background-secondary h-48 rounded-xl overflow-auto">
          {text.map((textPart) => {
            return (
              <Text
                key={textPart}
                variant="subhead"
                className="my-4 text-label-primary"
              >
                {textPart}
              </Text>
            );
          })}
        </div>

        <PromptFooter
          approveButtonText={t(
            strings.prompts.signatureRequest.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.signatureRequest.footer.cancelButtonText,
          )}
          aboveButtonText={t(strings.prompts.signatureRequest.footer.title)}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
