import type { FC } from 'react';
import { Icon, Label, SendAssetIcon, Text } from '@moonpay-widget/ui-kit';
import { useTranslation } from 'react-i18next';
import WalletAddressComponent from '../../../components/WalletAddressComponent';
import { formatTransactionCost } from '../../../messages/walletProxy/methods/sendTransaction/utils';
import PromptSheet from '../../common/prompts/prompt-sheet';
import { strings } from '../../../i18n';
import PromptFooter from '../../common/prompts/prompt-footer';

export interface BitcoinSendTransactionPromptProps {
  onApprove: () => void;
  onReject: () => void;
  fromAddress: string;
  toAddress: string;
  valueInBtc: string;
  networkFeeInBtc: string;
}

const DECIMAL_PLACES = 8;

export const BitcoinSendTransactionPrompt: FC<
  BitcoinSendTransactionPromptProps
> = ({
  onApprove,
  onReject,
  fromAddress,
  toAddress,
  valueInBtc,
  networkFeeInBtc,
}) => {
  const { t } = useTranslation();

  const totalTransactionCost =
    Number.parseFloat(valueInBtc) + Number.parseFloat(networkFeeInBtc);

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={SendAssetIcon} className="w-16 h-16 rounded-xl" />
        </div>
        <Text className="mt-2 mb-1 text-center" variant="title1" bold>
          {t(strings.prompts.bitcoin.sendTransaction.title)}
        </Text>

        <div className="flex items-center justify-between">
          <Label>{t(strings.prompts.bitcoin.sendTransaction.subtitle)}</Label>
        </div>
        <WalletAddressComponent
          leftLabel="From"
          leftValue={fromAddress}
          rightLabel="To"
          rightValue={toAddress}
        />
        <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
          <Text as="div" variant="footnote" className="flex my-4 mb-5">
            <div className="float-left w-1/2 text-subhead font-light">
              {t(strings.prompts.bitcoin.sendTransaction.amount)}
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{`${valueInBtc} BTC`}</div>
          </Text>
          <Text
            as="div"
            variant="footnote"
            className="flex my-4 border-b pb-4 border-divider-nonOpaque"
          >
            <div className="float-left w-1/2 text-subhead font-light">
              {t(strings.prompts.bitcoin.sendTransaction.blockchainFee)}
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{` ${formatTransactionCost(
              networkFeeInBtc,
              DECIMAL_PLACES,
            )} BTC`}</div>
          </Text>
          <Text variant="callout" as="div" className="flex my-4 mb-5">
            <div className="float-left w-1/2 font-semibold">
              {t(strings.prompts.bitcoin.sendTransaction.totalCost)}
            </div>
            <div className="float-right w-1/2 text-right font-semibold">{`${formatTransactionCost(
              totalTransactionCost,
              DECIMAL_PLACES,
            )} BTC`}</div>
          </Text>
        </div>

        <PromptFooter
          aboveButtonText={t(
            strings.prompts.bitcoin.sendTransaction.footer.title,
          )}
          approveButtonText={t(
            strings.prompts.bitcoin.sendTransaction.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.bitcoin.sendTransaction.footer.cancelButtonText,
          )}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
