import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
} from '@moonpay/login-common';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import AddNetworkPrompt from './AddNetworkPrompt';

const errorManager = new ErrorManager(__filename);

export async function addNetworkValidate({
  request,
  walletStorage,
  network,
}: PromptRequest): Promise<void> {
  if (request.method !== 'wallet_addEthereumChain') {
    throw errorManager.getServerError(
      'addNetworkValidate',
      `Invalid request method`,
    );
  }

  const { params } = request;

  if (!Array.isArray(params) || params.length !== 1) {
    throw errorManager.getServerError(
      'addNetworkValidate',
      `Invalid request params`,
    );
  }

  if (typeof params[0] !== 'object') {
    throw errorManager.getServerError(
      'addNetworkValidate',
      `Invalid request params[0] is not an object`,
    );
  }

  if (typeof params[0].chainId !== 'string') {
    throw errorManager.getServerError(
      'addNetworkValidate',
      `Invalid request params[0].chainId type`,
    );
  }

  const expectedKeys = [
    'chainId',
    'chainName',
    'nativeCurrency',
    'rpcUrls',
    'blockExplorerUrls',
  ];

  const paramsKeys = Object.keys(params[0]);

  if (!expectedKeys.every((key) => paramsKeys.includes(key))) {
    const missingKeys = expectedKeys.filter((key) => !paramsKeys.includes(key));
    throw errorManager.getServerError(
      'addNetworkValidate',
      `Invalid request params[0] missing fields: ${missingKeys.join(', ')}`,
    );
  }

  const chainId = Number(params[0]!.chainId);
  const chain = walletStorage.chains.getChain(chainId.toString(), network);
  if (!chain) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      `Invalid chainId ${chainId}. Wallet does not have this chain enabled.`,
    );
  }
}

export async function addNetworkExecute({
  request,
  walletStorage,
  network,
  abstractWallet,
}: PromptRequest): Promise<void> {
  const newChainId = Number(request.params![0].chainId);

  await walletStorage.connections.updateWalletConnection({
    address: abstractWallet.address,
    chainId: newChainId,
    origin,
    value: true,
    network,
  });

  // set the new chain id for the given network
  walletStorage.activeChainId.setActiveChainIdByNetwork(network, newChainId);

  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.switchNetworkCompleted,
  );
}

export async function addNetworkReject(req: PromptRequest): Promise<void> {
  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.switchNetworkCancelled,
  );
}

export async function addNetworkPrompt({ request }: PromptRequest) {
  const { chainId, chainName, nativeCurrency, rpcUrls, blockExplorerUrls } =
    request.params![0];

  return {
    title: 'Add Ethereum Network',
    component: (
      <AddNetworkPrompt
        chainId={chainId}
        chainName={chainName}
        nativeCurrency={nativeCurrency}
        rpcUrls={rpcUrls}
        blockExplorerUrls={blockExplorerUrls}
      />
    ),
  };
}
