import { Expose } from 'class-transformer';

export interface IEvmSwitchNetworkResponse {
  chainId: string;
}

export class EvmSwitchNetworkResponse {
  @Expose()
  public readonly chainId!: string;

  constructor(data: Partial<IEvmSwitchNetworkResponse>) {
    Object.assign(this, data);
  }
}
