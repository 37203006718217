const MESSAGE_HANDLER_METADATA_KEY = Symbol('message_handler_metadata_key');
const MESSAGE_RESPONSE_METADATA_KEY = Symbol('message_response_metadata_key');
const MESSAGE_REQUEST_METADATA_KEY = Symbol('message_request_metadata_key');
const MESSAGE_REQUEST_PARAMS_METADATA_KEY = Symbol(
  'message_request_params_metadata_key',
);

export {
  MESSAGE_HANDLER_METADATA_KEY,
  MESSAGE_REQUEST_METADATA_KEY,
  MESSAGE_REQUEST_PARAMS_METADATA_KEY,
  MESSAGE_RESPONSE_METADATA_KEY,
};
