import {
  ConnectionRequestIcon,
  CurrencyXCurrency,
  Icon,
  Text,
} from '@moonpay-widget/ui-kit';
import { WalletNetwork } from '@moonpay/login-common';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import PromptFooter from 'src/v2/common/prompts/prompt-footer';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import { EvmChainSpec, toHexString } from 'src/v2/evm/types';

export interface EvmSwitchNetworkProps {
  onApprove: () => void;
  onReject: () => void;
  fromChain: EvmChainSpec;
  toChain: EvmChainSpec;
}

export const EvmSwitchNetworkPrompt: FC<EvmSwitchNetworkProps> = ({
  onApprove,
  onReject,
  fromChain,
  toChain,
}) => {
  const { t } = useTranslation();

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={ConnectionRequestIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-2 mb-1 text-center" variant="title1" bold>
          {t(strings.prompts.evm.switchNetwork.title)}
        </Text>

        <Text
          variant="subhead"
          className="justify-center text-center text-label-secondary mt-5 mb-6"
        >
          {t(strings.prompts.evm.switchNetwork.subTitle)}
        </Text>

        <CurrencyXCurrency
          currencyFrom={{
            name: fromChain.name,
            icon: getChainIcon(
              WalletNetwork.Ethereum,
              toHexString(fromChain.chainId),
            ),
          }}
          currencyTo={{
            name: toChain.name,
            icon: getChainIcon(
              WalletNetwork.Ethereum,
              toHexString(toChain.chainId),
            ),
          }}
        />

        <PromptFooter
          aboveButtonText={t(strings.prompts.evm.switchNetwork.footer.title)}
          approveButtonText={t(
            strings.prompts.evm.switchNetwork.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.evm.switchNetwork.footer.cancelButtonText,
          )}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
