import { SummaryRow, Text, UpdatedSummaryTable } from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';

export interface SignTypedDataProps {
  validTypes: {
    [key: string]: { name: string; type: string }[];
  };
  domain: any;
  message: any;
}

const treatValue = (value: any) => {
  if (typeof value === 'string') {
    if (value.length > 20) {
      // show full value on hover
      return <span title={value}>{`${value.slice(0, 20)}...`}</span>;
    }

    return <span>{value}</span>;
  }
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

const SignTypedDataPrompt: FunctionComponent<SignTypedDataProps> = ({
  validTypes,
  domain,
  message,
}) => {
  const buildMessageRows = () => {
    const fullRender: JSX.Element[] = []; // Declare an array to hold JSX elements

    Object.keys(message).forEach((key: any) => {
      const value = message[key];

      if (!value) {
        return;
      }

      // Case: Object, not array
      if (typeof value === 'object' && !Array.isArray(value)) {
        fullRender.push(
          <div className="mt-4 text-left w-full" key={key}>
            <Text variant="headline" className="capitalize">
              {key}
            </Text>
          </div>,
        );

        Object.entries(value).forEach(([innerKey, innerValue]) => {
          fullRender.push(
            <SummaryRow
              key={`${key}-${innerKey}`}
              title={innerKey}
              value={treatValue(innerValue)}
              titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
              valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
            />,
          );
        });
      }
      // Case: Array
      else if (Array.isArray(value)) {
        fullRender.push(
          <div className="mt-4 text-left w-full" key={key}>
            <Text variant="headline" className="capitalize">
              {key}
            </Text>
          </div>,
        );

        value.forEach((item, index) => {
          if (typeof item === 'object' && item !== null) {
            Object.entries(item).forEach(([innerKey, innerValue]) => {
              fullRender.push(
                <SummaryRow
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${key}-${index}-${innerKey}`}
                  title={innerKey}
                  value={treatValue(innerValue)}
                  titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                  valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                />,
              );
            });
          }
        });
      }
      // Case: String
      else if (typeof value === 'string') {
        fullRender.push(
          <SummaryRow
            key={key}
            title={key}
            value={treatValue(value)}
            titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
            valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
          />,
        );
      }
    });

    return fullRender;
  };

  return (
    <div className="overflow-auto" style={{ height: '300px' }}>
      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="callout">Message</Text>
          </div>
        }
        openByDefault
      >
        <div className="mt-2 bg-system-background-secondary rounded-xl">
          {buildMessageRows()}
        </div>
      </UpdatedSummaryTable>

      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="callout">Types</Text>
          </div>
        }
      >
        {Object.keys(validTypes).map((objectKey) => {
          return (
            <div key={objectKey}>
              <span
                style={{
                  display: 'inline-block',
                  maxWidth: '20ch',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  lineHeight: '1.25',
                  verticalAlign: 'middle',
                  fontWeight: '500',
                }}
              >
                {objectKey}
              </span>
              <div className="px-2 bg-system-background-secondary rounded-xl">
                {validTypes[objectKey].map(
                  (textPart: { name: string; type: string }) => {
                    return (
                      <SummaryRow
                        key={textPart.name}
                        title={textPart.name}
                        value={textPart.type}
                        titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                        valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                      />
                    );
                  },
                )}
              </div>
            </div>
          );
        })}
      </UpdatedSummaryTable>

      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="subhead">Domain</Text>
          </div>
        }
      >
        <div className="px-4 mb-8 mt-2 bg-system-background-secondary rounded-xl">
          {Object.keys(domain).map((key: string) => {
            return (
              <SummaryRow
                key={key}
                title={key}
                value={treatValue(domain[key])}
                titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
              />
            );
          })}
        </div>
      </UpdatedSummaryTable>
    </div>
  );
};

export default SignTypedDataPrompt;
