import {
  AccessCurrencyRequestIcon,
  Icon,
  Label,
  SendAssetIcon,
  Text,
} from '@moonpay-widget/ui-kit';
import { FunctionComponent, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import WalletAddressComponent from 'src/components/WalletAddressComponent';
import { strings } from 'src/i18n';
import { formatTransactionCost } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import PromptFooter from 'src/v2/common/prompts/prompt-footer';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import { SupportedErc20Function } from 'src/v2/evm/config';

export interface ERC20SendTransactionPromptProps {
  to: string;
  value: string;
  networkFee: string;
  tokenName: string;
  tokenSymbol: string;
  functionName: SupportedErc20Function;
  currencyCode: string;
  decimalPlaces: number;
  onApprove: () => void;
  onReject: () => void;
}

export const ERC20SendTransactionPrompt: FC<
  ERC20SendTransactionPromptProps
> = ({
  to,
  value,
  networkFee,
  tokenName,
  tokenSymbol,
  functionName,
  currencyCode,
  decimalPlaces = 8,
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();

  let icon: FunctionComponent;
  let prompt:
    | typeof strings.prompts.evm.sendTransaction.approve
    | typeof strings.prompts.evm.sendTransaction.transfer;

  switch (functionName) {
    case SupportedErc20Function.Approve:
      icon = AccessCurrencyRequestIcon;
      prompt = strings.prompts.evm.sendTransaction.approve;
      break;
    case SupportedErc20Function.Transfer:
      icon = SendAssetIcon;
      prompt = strings.prompts.evm.sendTransaction.transfer;
      break;
    default:
      icon = SendAssetIcon;
      prompt = strings.prompts.evm.sendTransaction.transfer;
  }

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top" id="helloooo-12369">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={icon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {t(prompt.title)} {tokenName}
        </Text>

        {prompt.message && (
          <div className="flex flex-row items-center justify-between px-2 pt-4 text-center dialog-actions">
            <Text variant="footnote" className="text-label-secondary">
              {t(prompt.message)}
            </Text>
          </div>
        )}

        <WalletAddressComponent
          leftLabel={t(prompt.to)}
          leftValue={to}
          rightLabel={t(prompt.amount)}
          rightValue={`${value} ${tokenSymbol}`}
        />
        <div className="flex items-center justify-between">
          <Label>{t(prompt.summary)}</Label>
        </div>
        <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
          <Text
            as="div"
            variant="footnote"
            className="flex my-4 border-b pb-4 border-divider-nonOpaque"
          >
            <div className="float-left w-1/2 text-subhead font-light">
              {t(prompt.blockchainFee)}
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{`${formatTransactionCost(
              networkFee,
              decimalPlaces,
            )} ${currencyCode}`}</div>
          </Text>
        </div>

        <PromptFooter
          approveButtonText={t(prompt.footer.approvalButtonText)}
          cancelButtonText={t(prompt.footer.cancelButtonText)}
          aboveButtonText={t(prompt.footer.title)}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
