import { ethers } from 'ethers';
import {
  OPENSEA_FULFILL_ADVANCED_ORDER,
  OPENSEA_FULFILL_AVAILABLE_ADVANCED_ORDER,
  OPENSEA_FULFILL_BASIC_ORDER,
  OPENSEA_FULFILL_ORDER,
  SEAPORT_CONTRACT_1_1,
  SEAPORT_CONTRACT_1_2,
  SEAPORT_CONTRACT_1_3,
  SEAPORT_CONTRACT_1_4,
} from './constants';
import seaportAbi from './seaport.json';

const seaportContracts = [
  new ethers.Contract(SEAPORT_CONTRACT_1_1, seaportAbi),
  new ethers.Contract(SEAPORT_CONTRACT_1_2, seaportAbi),
  new ethers.Contract(SEAPORT_CONTRACT_1_3, seaportAbi),
  new ethers.Contract(SEAPORT_CONTRACT_1_4, seaportAbi),
];

let seaportContract: ethers.Contract = seaportContracts[0];

export function isOpenseaContractAddress(address: string) {
  return seaportContracts.some((contract) => {
    seaportContract = contract;
    return contract.address.toLowerCase() === address.toLowerCase();
  });
}

type DecodedNftRequestData = {
  nftName: string;
  price: string;
  quantity: number;
};

function decodeBasicOrder(data: string): DecodedNftRequestData {
  const decoded = seaportContract.interface.decodeFunctionData(
    OPENSEA_FULFILL_BASIC_ORDER,
    data,
  );

  const [{ offerAmount: quantity, considerationAmount }] = decoded;
  const price = ethers.utils.formatEther(considerationAmount);

  return {
    nftName: 'NFT',
    price,
    quantity: ethers.BigNumber.from(quantity).toNumber(),
  };
}

function decodeOrder(data: string): DecodedNftRequestData {
  const decoded = seaportContract.interface.decodeFunctionData(
    OPENSEA_FULFILL_ORDER,
    data,
  );

  const [[{ offer, consideration }, totalOriginalConsiderationItems]] = decoded;
  const [{ startAmount }] = offer;
  const [considerationDetails] = consideration;

  const numOffered = ethers.BigNumber.from(startAmount);
  const priceForNumOffered = ethers.BigNumber.from(
    considerationDetails.startAmount,
  );
  const pricePerToken = priceForNumOffered.div(numOffered);

  return {
    nftName: 'NFT',
    price: ethers.utils.formatEther(pricePerToken),
    quantity: totalOriginalConsiderationItems,
  };
}

function decodeAdvancedOrder(data: string): DecodedNftRequestData {
  const decoded = seaportContract.interface.decodeFunctionData(
    OPENSEA_FULFILL_ADVANCED_ORDER,
    data,
  );

  const [[{ offer, consideration }, numerator, denominator]] = decoded;
  const [{ startAmount }] = offer;
  const [considerationDetails] = consideration;

  const numOffered = ethers.BigNumber.from(startAmount);
  const priceForNumOffered = ethers.BigNumber.from(
    considerationDetails.startAmount,
  );
  const pricePerToken = priceForNumOffered.div(numOffered);
  const numeratorNum = ethers.BigNumber.from(numerator).toNumber();
  const denominatorNum = ethers.BigNumber.from(denominator).toNumber();
  const quantity = Math.floor(
    (numeratorNum / denominatorNum) * numOffered.toNumber(),
  );

  return {
    nftName: 'NFT',
    price: ethers.utils.formatEther(pricePerToken),
    quantity,
  };
}

function decodeAvailableAdvancedOrders(data: string): DecodedNftRequestData {
  const decoded = seaportContract.interface.decodeFunctionData(
    OPENSEA_FULFILL_AVAILABLE_ADVANCED_ORDER,
    data,
  );

  const { advancedOrders } = decoded;

  const decodings: DecodedNftRequestData[] = [];
  advancedOrders.forEach((order: any) => {
    const {
      offer: [offerDetails],
      consideration: [considerationDetails],
    } = order.parameters;
    const numOffered = ethers.BigNumber.from(offerDetails.startAmount);

    const priceForNumOffered = ethers.BigNumber.from(
      considerationDetails.startAmount,
    );
    const pricePerToken = priceForNumOffered.div(numOffered);

    const numerator = ethers.BigNumber.from(order.numerator).toNumber();
    const denominator = ethers.BigNumber.from(order.denominator).toNumber();
    const float = (numerator / denominator) * numOffered.toNumber();

    decodings.push({
      nftName: 'NFT',
      price: ethers.utils.formatEther(pricePerToken),
      quantity: Math.floor(float),
    });
  });

  const totalQuantity = decodings.reduce((acc, curr) => acc + curr.quantity, 0);
  const averagePrice =
    decodings.reduce(
      (acc, curr) => acc + parseFloat(curr.price) * curr.quantity,
      0,
    ) / totalQuantity;

  return {
    nftName: 'NFT',
    price: averagePrice.toString(),
    quantity: totalQuantity,
  };
}

const decodersByTransactionType = {
  [OPENSEA_FULFILL_BASIC_ORDER]: decodeBasicOrder,
  [OPENSEA_FULFILL_ADVANCED_ORDER]: decodeAdvancedOrder,
  [OPENSEA_FULFILL_ORDER]: decodeOrder,
  [OPENSEA_FULFILL_AVAILABLE_ADVANCED_ORDER]: decodeAvailableAdvancedOrders,
};

// TODO: write tests for these for data that we know works @guppykang
export function decodeSeaportTx(
  data: string,
  to: string,
): DecodedNftRequestData | null {
  // set the seaport contract to the correct version
  if (!isOpenseaContractAddress(to)) {
    return null;
  }

  let decoded: DecodedNftRequestData | null = null;

  Object.entries(decodersByTransactionType).forEach(([, decoder]) => {
    try {
      decoded = decoder(data);
    } catch (err) {
      //
    }
  });

  return decoded;
}
