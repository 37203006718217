/* eslint-disable @typescript-eslint/no-explicit-any */
import localforage from 'localforage';

let lf: LocalForage;

const proxyLocalForageMethod = <TMethod extends keyof LocalForageDbMethods>(
  method: TMethod,
): LocalForage[TMethod] => {
  return async (...args: any[]) => {
    if (!lf) {
      lf = localforage.createInstance({
        name: 'moonpay_auth',
      });
      await lf.ready();
    }
    return (lf[method] as any)(...args);
  };
};

export const getItem = proxyLocalForageMethod('getItem');
export const setItem = proxyLocalForageMethod('setItem');
export const removeItem = proxyLocalForageMethod('removeItem');
