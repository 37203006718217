/**
 * A function that receives the current data in readonly mode and must return the updated data.
 */
export type UpdateFunction<T> = (data: Readonly<T>) => T;

export type StorageType = 'localStorage' | 'sessionStorage';

export interface BaseStorageOptions<T> {
  // The key to use in the storage
  key: string;
  // The default data to set if storage is empty
  defaultValue: T;
  // What kind of storage to use
  storage?: StorageType;
  // Read through the storage instead of using a local cache - useful if the value may be modified somewhere else
  readThrough?: boolean;
}

export class BaseStorage<T> {
  private key: string;

  private storage: Storage;

  private data: T;

  private readThrough: boolean;

  constructor({
    key,
    defaultValue,
    storage = 'localStorage',
    readThrough = false,
  }: BaseStorageOptions<T>) {
    this.key = key;
    this.storage = window[storage];
    this.readThrough = readThrough;
    this.data = this.readOrSetup(defaultValue);
  }

  /** Returns the data stored in storage or initialises storage with default */
  private readOrSetup(defaultValue: T) {
    const storedValue = this.readStorage();
    if (storedValue) {
      return storedValue;
    }

    this.writeStorage(defaultValue);
    return defaultValue;
  }

  private readStorage(): Readonly<T> | null {
    const raw = this.storage.getItem(this.key);
    if (!raw) {
      return null;
    }
    try {
      return JSON.parse(raw);
    } catch (e) {
      // Failing to parse likely the storage is not JSON and simple text
      return raw as unknown as T;
    }
  }

  private writeStorage(data: T) {
    this.storage.setItem(this.key, JSON.stringify(data));
  }

  public get value(): Readonly<T> {
    return this.readThrough ? (this.readStorage() as T) : this.data;
  }

  /**
   * Updates the state as well as underlying storage
   * @param updateFunction The function to use to update the storage
   */
  update(updateFunction: UpdateFunction<T>) {
    const updated = updateFunction(this.data);
    this.data = updated;
    this.writeStorage(updated);
  }

  /**
   * Sets the storage with the desired value
   * @param value the desired value
   */
  set(value: T) {
    this.update(() => value);
  }
}
