import bs58 from 'bs58';
import {
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from 'class-validator';

export function IsBase58(validationOptions?: ValidationOptions) {
  return function validate(object: any, propertyName: string) {
    registerDecorator({
      name: 'isBase58',
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate(value: any) {
          if (!value) {
            return false;
          }

          try {
            // Should be a valid base58 encoded string
            const decoded = Buffer.from(bs58.decode(value)).toString('utf8'); // Will throw if it can't decode
            return !decoded.includes('�'); // If decoded contains replacement characters, it's probably an invalid base58 string to begin with
          } catch (error) {
            return false;
          }
        },
        defaultMessage(args: ValidationArguments) {
          return `${args.property} must be a base 58 encoded string`;
        },
      },
    });
  };
}
