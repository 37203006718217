import type { Wallet as EthersWallet } from 'ethers';
import { BigNumber } from 'ethers';
import { formatFee } from '../utils';

export async function evmEstimateGas(wallet: EthersWallet, transaction: any) {
  const { gas, maxFeePerGas, maxPriorityFeePerGas } = transaction;
  let gasLimit: BigNumber;
  try {
    if (gas) {
      gasLimit = BigNumber.from(gas.toString());
    } else {
      gasLimit = await wallet.provider.estimateGas(transaction);
    }
  } catch (error) {
    gasLimit = BigNumber.from(100_000); // if estimateGas fails, we fall back to this value
  }

  const feeData = await wallet.provider.getFeeData();
  const gasPrice = maxFeePerGas
    ? BigNumber.from(maxFeePerGas.toString())
    : // TODO: FIX
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      feeData.maxFeePerGas ?? feeData.gasPrice!; // if non EIP-1159, fall back to gasPrice
  return formatFee(
    gasLimit,
    gasPrice,
    maxPriorityFeePerGas
      ? BigNumber.from(maxPriorityFeePerGas.toString())
      : undefined,
  );
}
