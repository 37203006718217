import { WalletNetwork } from '@moonpay/login-common';
import { ErrorManager } from 'src/utils/errorManager';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { NetworkEvmChain, NetworkSolChain } from '../types/NetworkChain';

const errorManager = new ErrorManager(__filename);

export function switchActiveChainId(
  walletStorage: WalletStorage,
  network: WalletNetwork,
  isMainnet: boolean,
) {
  if (isMainnet) {
    switch (network) {
      case WalletNetwork.Ethereum:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 1);
        break;
      case WalletNetwork.Bitcoin:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 0);
        break;
      case WalletNetwork.Solana:
        walletStorage.activeChainId.setActiveChainIdByNetwork(
          network,
          NetworkSolChain.Mainnet,
        );
        break;
      default:
        throw errorManager.getServerError(
          'switchActiveChainId',
          `Invalid network`,
        );
    }
  } else {
    switch (network) {
      case WalletNetwork.Ethereum:
        walletStorage.activeChainId.setActiveChainIdByNetwork(
          network,
          NetworkEvmChain.Sepolia,
        );
        break;
      case WalletNetwork.Bitcoin:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 1);
        break;
      case WalletNetwork.Solana:
        walletStorage.activeChainId.setActiveChainIdByNetwork(
          network,
          NetworkSolChain.Devnet,
        );
        break;

      default:
        throw errorManager.getServerError(
          'switchActiveChainId',
          `Invalid network`,
        );
    }
  }
}
