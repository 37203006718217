import { MESSAGE_RESPONSE_METADATA_KEY } from 'src/v2/lib/constants';

export function Response(type: any) {
  return function (target: any, propertyKey: string | symbol) {
    Reflect.defineMetadata(
      MESSAGE_RESPONSE_METADATA_KEY,
      type,
      target,
      propertyKey,
    );
  };
}
