import { MESSAGE_REQUEST_PARAMS_METADATA_KEY } from 'src/v2/lib/constants';

export function Params(type: any) {
  return function (target: any, propertyKey: string | symbol) {
    Reflect.defineMetadata(
      MESSAGE_REQUEST_PARAMS_METADATA_KEY,
      type,
      target,
      propertyKey,
    );
  };
}
