import {
  ethAccountsExecute,
  ethAccountsPostExecute,
  ethAccountsPrompt,
  ethAccountsReject,
  ethAccountsValidate,
} from 'src/messages/walletProxy/methods/accounts/accounts';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const ethAccounts: MethodImplementation = {
  validate: ethAccountsValidate,
  prompt: ethAccountsPrompt,
  execute: ethAccountsExecute,
  reject: ethAccountsReject,
  postExecute: ethAccountsPostExecute,
};

export { ethAccounts };
