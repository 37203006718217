import { CreateWalletPayload } from '@moonpay-widget/dhw';
import { createMessageHandler } from '../hooks/useHandleIncomingMessages';

const addCreateWalleMessageEventListener = (
  handler: (payload: CreateWalletPayload) => void | Promise<void>,
  origins: string[],
) => {
  const createWalletMessageHandler = createMessageHandler({
    messageType: 'create-wallet',
    messageHandler: handler,
    origins,
  });

  window.addEventListener('message', createWalletMessageHandler);
};

export default addCreateWalleMessageEventListener;
