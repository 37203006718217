import { WalletNetwork } from '@moonpay/login-common';
import { IsArray, IsEnum, IsOptional, IsString } from 'class-validator';

export class GetWalletsRequest {
  @IsArray()
  @IsEnum(WalletNetwork, { each: true })
  public readonly networks!: WalletNetwork[];

  @IsOptional()
  @IsString()
  public readonly csrfToken?: string;
}
