import { Button, Text } from '@moonpay-widget/ui-kit';
import { type FC } from 'react';

export interface PromptFooterProps {
  approveButtonText: string;
  cancelButtonText: string;
  onApprove: () => void;
  onReject: () => void;
  aboveButtonText?: string;
}

const PromptFooter: FC<PromptFooterProps> = ({
  approveButtonText,
  cancelButtonText,
  onApprove,
  onReject,
  aboveButtonText,
}) => {
  return (
    <footer>
      <div className="flex dialog-actions flex-col items-center text-center">
        {aboveButtonText && (
          <div className="flex flex-row items-center justify-between px-2 pt-4 text-center dialog-actions">
            <Text variant="footnote" className="text-label-secondary">
              {aboveButtonText}
            </Text>
          </div>
        )}
        <div className="flex flex-col-reverse items-center justify-between w-full px-4 pb-4 dialog-actions sm:flex-row">
          <Button
            variant="custom"
            className="mx-2 border-none bg-fill-secondary text-label-tertiary"
            margin="small"
            onClick={onReject}
          >
            <Text variant="headline" bold className="text-label-primary">
              {cancelButtonText}
            </Text>
          </Button>
          <Button
            variant="custom"
            className="mx-2 bg-btn-primary hover:bg-btn-hover disabled:bg-fill-secondary text-btn-label disabled:text-label-tertiary"
            margin="small"
            onClick={onApprove}
          >
            <Text variant="headline" bold>
              {approveButtonText}
            </Text>
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default PromptFooter;
