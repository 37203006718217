import { SignatureIcon } from '@moonpay-widget/ui-kit';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { validateWalletSigner } from 'src/messages/walletProxy/utils/validateWalletSigner';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';
import SignTypedDataPrompt from './SignTypedDataPrompt';

const errorManager = new ErrorManager(__filename);

export async function signTypedDataValidate({
  request,
  abstractWallet,
}: PromptRequest): Promise<void> {
  if (!Array.isArray(request.params)) {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params type`,
    );
  }
  if (request.params.length !== 2) {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params length`,
    );
  }
  if (typeof request.params[0] !== 'string') {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params[0] type`,
    );
  }
  if (typeof request.params[1] !== 'string') {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params[1] type`,
    );
  }
  try {
    JSON.parse(request.params[1]);
  } catch (e) {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params[1] JSON`,
    );
  }

  // check has expected keys
  const typedData = JSON.parse(request.params[1]);
  if (!typedData.types || !typedData.domain || !typedData.message) {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid request params[1] JSON`,
    );
  }

  if (!abstractWallet) {
    throw errorManager.getServerError(
      'signTypedDataValidate',
      `Invalid wallet`,
    );
  }

  await match(abstractWallet, {
    Bitcoin: async (_btcWallet: Bitcoin) => {
      throw errorManager.getServerError(
        'signTypedDataValidate',
        `Unsupported wallet`,
      );
    },
    Ethereum: async (ethWallet: Ethereum) => {
      validateWalletSigner(ethWallet.wallet, request.params![0]);
    },
  });
}

export async function signTypedDataExecute({ request, wallet }: PromptRequest) {
  const typedData = request.params![1];
  const typedDataObject =
    typeof typedData === 'string' ? JSON.parse(typedData) : typedData;
  // Note: ethers.js does not support `EIP712Domain` field
  // More info https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
  const { EIP712Domain, ...validTypes } = typedDataObject.types;
  EventTrackingUtils.trackSegmentEvent(SegmentTrackEvent.signTypedDataSigned);
  // eslint-disable-next-line no-underscore-dangle
  return wallet._signTypedData(
    typedDataObject.domain,
    validTypes,
    typedDataObject.message,
  );
}

export async function signTypedDataReject(req: PromptRequest) {
  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.signTypedDataCancelled,
  );
}

export async function signTypedDataPrompt({ request }: PromptRequest) {
  const typedData = request.params![1];

  const parsedTypedData = JSON.parse(typedData);

  const {
    types: { EIP712Domain, ...validTypes },
    domain,
    message,
  } = parsedTypedData;

  return {
    title: 'Signature Request',
    component: (
      <SignTypedDataPrompt
        validTypes={validTypes}
        domain={domain}
        message={message}
      />
    ),
    partnerLogo: SignatureIcon,
    approveButtonText: 'Approve',
    aboveButtonsText:
      'Only approve if you fully understand and trust the requesting site.',
  };
}
