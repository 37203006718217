import { WalletNetwork } from '@moonpay/login-common';
import logger from 'src/utils/logger';
import {
  MESSAGE_HANDLER_METADATA_KEY,
  MESSAGE_REQUEST_METADATA_KEY,
} from 'src/v2/lib/constants';
import { ControllerMethod } from 'src/v2/lib/types';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from '../../../wallet/storage/WalletStorage';
import {
  MoonPayWalletError,
  SecureWalletCommonErrors,
} from '../../common/errors';
import { requestHasBody, sendWalletMessageProxyResponse } from '../utils';

// TODO: DELETE THIS ONCE THE LEGACY SOLANA CONTROLLER IS NO LONGER USED
export function LegacyController(basePath: string) {
  return function (target: any) {
    window.addEventListener('message', (event) => {
      if (event.data.type !== basePath) return;

      // TODO: maybe we could add some validation to give some better feedback to implementors
      // e.g. if an invalid request method is sent we should throw a descriptive error back
      const requestBody = event.data;

      logger.info('V2 LegacyController received message:', requestBody);

      const requestMethod = requestBody?.request?.method;
      const payload = requestBody?.request?.params;

      const methods: ControllerMethod[] =
        Reflect.getMetadata(MESSAGE_HANDLER_METADATA_KEY, target.prototype) ??
        [];

      if (requestMethod) {
        methods.forEach(async (methodData) => {
          if (methodData.method === requestMethod) {
            const walletStorage = new WalletStorage();

            // eslint-disable-next-line new-cap
            const instance = new target(walletStorage);
            const method = instance[methodData.methodName].bind(instance);

            Reflect.defineMetadata(
              MESSAGE_REQUEST_METADATA_KEY,
              requestBody,
              target.prototype,
              methodData.methodName,
            );

            const hasBody = requestHasBody(instance, methodData.methodName);
            const body = Array.isArray(payload) ? payload[0] : payload;

            try {
              const wallet = await WalletService.restoreWallet(
                WalletNetwork.Solana,
                walletStorage,
              );

              if (!wallet) {
                throw new MoonPayWalletError(
                  SecureWalletCommonErrors.WALLET_RESTORE_ERROR,
                );
              }
              method(wallet, hasBody ? body : undefined);
            } catch (e) {
              const handler = {
                target: target.prototype,
                propertyKey: methodData.methodName,
              };
              sendWalletMessageProxyResponse({
                error: SecureWalletCommonErrors.WALLET_RESTORE_ERROR,
                handler,
              });
            }
          }
        });
      }
    });
  };
}
