import { Expose } from 'class-transformer';
import { BitcoinUnit } from 'src/v2/bitcoin/types';

export interface IBitcoinGetBalanceResponse {
  value: string;
  unit: BitcoinUnit;
}

export class BitcoinGetBalanceResponse {
  @Expose()
  public readonly value!: string;

  @Expose()
  public readonly unit!: BitcoinUnit;

  constructor(data: Partial<IBitcoinGetBalanceResponse>) {
    Object.assign(this, data);
  }
}
