import { ChainLinksIcon } from '@moonpay-widget/ui-kit/src/PromptIcons';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { ErrorManager } from 'src/utils/errorManager';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';
import Pill, { PillProps } from './PillPrompt';

const errorManager = new ErrorManager(__filename);

export async function openPillValidate(req: PromptRequest) {
  if (req.request.method !== 'open_pill') {
    throw errorManager.getServerError(
      'openPillValidate',
      `Invalid request method`,
    );
  }

  if (req.request.params && req.request.params.length > 0) {
    throw errorManager.getServerError(
      'openPillValidate',
      `Invalid request params`,
    );
  }
}

// eslint-disable-next-line no-empty-pattern
export async function openPillExecute({}: PromptRequest) {
  // This function is purposefully left blank because the approve button is hidden
  // and it does not make sense to `execute` the a openPill request. No
  // action is taken when the user clicks the approve button.
}

// eslint-disable-next-line no-empty-pattern
export async function openPillReject({}: PromptRequest) {
  // This function is purposefully left blank because canceling or closing the
  // prompt is the same as rejecting the request, and is the only option available
  // to the user. It also does not make sense to `reject` the a openPill request.
  // and no action is taken when the user clicks the cancel button.
}

export async function openPillPrompt({
  abstractWallet,
  walletStorage,
  origin,
  network,
}: PromptRequest) {
  // get active chain's native token symbol
  const activeChain =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);

  const chain = walletStorage.chains.getChain(activeChain.toString(), network);
  // chain is already checked in registerProxy, so this should never happen
  if (!chain) {
    throw errorManager.getServerError('openPillPrompt', `Invalid chainId`);
  }

  if (!abstractWallet) {
    throw errorManager.getServerError('openPillPrompt', `Invalid wallet`);
  }

  const props = match<PillProps>(abstractWallet, {
    Bitcoin: (_btcWallet: Bitcoin) => {
      throw errorManager.getServerError('openPillPrompt', `Invalid wallet`);
    },
    Ethereum: async (ethWallet: Ethereum) => {
      return {
        userWalletInfo: {
          address: ethWallet.wallet.address,
          balance: (await ethWallet.wallet.getBalance()).toString(),
          chainId:
            walletStorage.activeChainId.getActiveChainIdByNetwork(network),
          nativeCurrency: chain.nativeCurrency,
        },
        walletStorage,
        wallet: ethWallet.wallet,
        origin,
        network,
      };
    },
  });

  if (props instanceof Error) {
    throw props;
  }

  return {
    title: 'Wallet Pill',
    partnerLogo: ChainLinksIcon,
    component: <Pill {...props} />,
    aboveButtonsText: '',
    cancelButtonText: 'Close',
    approvalHidden: true,
    fitToContent: true,
  };
}
