import { btcSellTransactionPrompt } from 'src/messages/walletProxy/methods/sellTransaction/btcSellTransactionPrompt';
import { btcSellTransactionValidate } from 'src/messages/walletProxy/methods/sellTransaction/btcSellTransactionValidate';
import {
  sendTransactionExecute,
  sendTransactionReject,
} from 'src/messages/walletProxy/methods/sendTransaction/sendTransaction';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const btcSellTransaction: MethodImplementation = {
  validate: btcSellTransactionValidate,
  prompt: btcSellTransactionPrompt,
  execute: sendTransactionExecute,
  reject: sendTransactionReject,
};

export { btcSellTransaction };
