import { ECPairInterface } from 'ecpair';
import { AbstractWallet } from '../../../wallet/types/Wallet';
import {
  craftBtcTransactionIO,
  getBitcoinWalletSigner,
  selectUtxos,
  Utxo,
} from '../../../messages/walletProxy/methods/sendTransaction/bitcoin/utils';
import { MoonPayWalletsApiClient } from '../../common/clients/moonpay-wallets-api.client';
import { BitcoinNetworkEnvironmentManager } from './network-environment-manager.service';
import { BitcoinNetworkEnvironment } from '../types';
import { BitcoinWalletErrors, MoonPayWalletError } from '../../common/errors';

type BtcGetUtxosApiResponse = {
  data?: {
    utxos: Utxo[];
  };
};

type BtcSendTransactionResponse = {
  data?: {
    transactionHash: string;
  };
};

export class BitcoinTransactionService {
  private readonly signer: ECPairInterface;

  private readonly networkEnvironment: BitcoinNetworkEnvironment;

  private readonly networkChainId: number;

  constructor(
    private readonly abstractWallet: AbstractWallet,
    networkEnvironmentManager: BitcoinNetworkEnvironmentManager,
  ) {
    this.networkChainId = networkEnvironmentManager.getActiveChainId();
    this.signer = getBitcoinWalletSigner(this.networkChainId, abstractWallet);
    this.networkEnvironment =
      networkEnvironmentManager.getActiveNetworkEnvironment();
  }

  public async sendTransaction(
    toAddress: string,
    value: string,
    feeInSats: string,
  ) {
    const { inputs, change } = await this.deriveUtxos(
      Number(value),
      Number(feeInSats),
    );
    const bitcoinTransactionData = this.craftBitcoinTransaction({
      inputs,
      change,
      toAddress,
      amount: Number(value),
    });

    const sendTransactionBody = JSON.stringify({
      transactionData: bitcoinTransactionData,
    });
    const sendTransactionResponse =
      await MoonPayWalletsApiClient.callApi<BtcSendTransactionResponse>(
        'POST',
        `/v1/bitcoin/${this.networkEnvironment}/transaction`,
        sendTransactionBody,
      );

    const transactionHash = sendTransactionResponse.data?.transactionHash;
    if (!transactionHash) {
      throw new MoonPayWalletError(
        BitcoinWalletErrors.BTC_WALLET_API_UTXOS_ERROR,
      );
    }

    return transactionHash;
  }

  private async deriveUtxos(
    value: number,
    feeInSats: number,
  ): Promise<{ inputs: Utxo[]; change: number; networkFeeInSatoshis: number }> {
    const utxosResponse =
      await MoonPayWalletsApiClient.callApi<BtcGetUtxosApiResponse>(
        'GET',
        `/v1/bitcoin/${this.networkEnvironment}/utxos?walletAddress=${this.abstractWallet.address}`,
      );

    const utxos = utxosResponse.data?.utxos;
    if (!utxos) {
      throw new MoonPayWalletError(
        BitcoinWalletErrors.BTC_WALLET_API_UTXOS_ERROR,
      );
    }

    return selectUtxos(utxos, value, this.networkChainId, feeInSats);
  }

  private craftBitcoinTransaction({
    inputs,
    change,
    toAddress,
    amount,
  }: {
    inputs: Utxo[];
    change: number;
    toAddress: string;
    amount: number;
  }): string {
    const partiallySignedBitcoinTransactionInternal = craftBtcTransactionIO(
      inputs,
      change,
      toAddress,
      amount,
      this.abstractWallet,
      this.networkChainId,
    );
    const wallet = getBitcoinWalletSigner(
      this.networkChainId,
      this.abstractWallet,
    );
    partiallySignedBitcoinTransactionInternal.signAllInputs(wallet);
    partiallySignedBitcoinTransactionInternal.finalizeAllInputs();
    return partiallySignedBitcoinTransactionInternal
      .extractTransaction()
      .toHex();
  }
}
