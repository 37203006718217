import { Label, Text } from '@moonpay-widget/ui-kit';
import { FC } from 'react';
import WalletAddressComponent from 'src/components/WalletAddressComponent';
import { formatTransactionCost } from 'src/messages/walletProxy/methods/sendTransaction/utils';

export type GenericSendTransactionProps = {
  value: string;
  to: string;
  from: string;
  networkFee: string;
  currencyCode: string;
};

const GenericSendTransaction: FC<GenericSendTransactionProps> = ({
  to,
  from,
  value,
  networkFee,
  currencyCode,
}) => {
  let decimalPlaces;
  switch (currencyCode) {
    case 'ETH':
      decimalPlaces = 8;
      break;
    case 'BTC':
      decimalPlaces = 8;
      break;
    default:
      decimalPlaces = 5;
  }

  const totalTransactionCost =
    Number.parseFloat(value) + Number.parseFloat(networkFee);

  return (
    <>
      <WalletAddressComponent
        leftLabel="From"
        leftValue={from}
        rightLabel="To"
        rightValue={to}
      />
      <div className="flex items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
        <Text as="div" variant="footnote" className="flex my-4 mb-5">
          <div className="float-left w-1/2 text-subhead font-light">
            Funds to be sent
          </div>
          <div className="float-right w-1/2 text-right text-subhead font-light">{`${value} ${currencyCode}`}</div>
        </Text>
        <Text
          as="div"
          variant="footnote"
          className="flex my-4 border-b pb-4 border-divider-nonOpaque"
        >
          <div className="float-left w-1/2 text-subhead font-light">
            Blockchain fee
          </div>
          <div className="float-right w-1/2 text-right text-subhead font-light">{` ${formatTransactionCost(
            networkFee,
            decimalPlaces,
          )} ${currencyCode}`}</div>
        </Text>
        <Text variant="callout" as="div" className="flex my-4 mb-5">
          <div className="float-left w-1/2 font-semibold">
            Total transaction cost
          </div>
          <div className="float-right w-1/2 text-right font-semibold">{`${formatTransactionCost(
            totalTransactionCost,
            decimalPlaces,
          )} ${currencyCode}`}</div>
        </Text>
      </div>
    </>
  );
};

export default GenericSendTransaction;
