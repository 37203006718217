import { Expose } from 'class-transformer';
import { BitcoinNetworkEnvironment } from 'src/v2/bitcoin/types';

export interface IBitcoinSwitchNetworkResponse {
  networkEnvironment: BitcoinNetworkEnvironment;
}

export class BitcoinSwitchNetworkResponse {
  @Expose()
  public readonly networkEnvironment!: BitcoinNetworkEnvironment;

  constructor(data: Partial<IBitcoinSwitchNetworkResponse>) {
    Object.assign(this, data);
  }
}
