import {
  openPillExecute,
  openPillPrompt,
  openPillReject,
  openPillValidate,
} from 'src/messages/walletProxy/methods/openPill/openPill';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const openPill: MethodImplementation = {
  validate: openPillValidate,
  prompt: openPillPrompt,
  execute: openPillExecute,
  reject: openPillReject,
};

export { openPill };
