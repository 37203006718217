import {
  switchNetworkExecute,
  switchNetworkPrompt,
  switchNetworkValidate,
} from './switchNetwork';
import type { MethodImplementation } from '../types';

const switchNetwork: MethodImplementation = {
  validate: switchNetworkValidate,
  prompt: switchNetworkPrompt,
  execute: switchNetworkExecute,
};

export { switchNetwork };
