import { IsEnum, IsEthereumAddress, IsString } from 'class-validator';
import { SupportedErc20Function } from 'src/v2/evm/config';

export class Erc20TransactionRequest {
  @IsEthereumAddress()
  contractAddress!: string;

  @IsString()
  name!: string;

  @IsString()
  symbol!: string;

  @IsEnum(SupportedErc20Function)
  functionName!: SupportedErc20Function;
}
