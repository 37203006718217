import { createMessageHandler } from '../hooks/useHandleIncomingMessages';
import { HandleGetWalletMessageParams } from './registerGetWalletMessageHandler';

const addGetWalletMessageEventListener = (
  handler: (payload: HandleGetWalletMessageParams) => Promise<void>,
  origins: string[],
) => {
  const getWalletMessageHandler = createMessageHandler({
    messageType: 'get-wallet',
    messageHandler: handler,
    origins,
  });

  window.addEventListener('message', getWalletMessageHandler);
};

export default addGetWalletMessageEventListener;
