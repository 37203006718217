import { Expose, Type } from 'class-transformer';

// TODO: This should be a shared interface in a package
export interface ISolanaConnectResponse {
  addresses: string[];
}

export class SolanaConnectResponse {
  @Type(() => String)
  @Expose()
  public readonly addresses!: string[];

  constructor(data: Partial<ISolanaConnectResponse>) {
    Object.assign(this, data);
  }
}
