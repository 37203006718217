import { WalletNetwork } from '@moonpay/login-common';
import { sendTransactionValidate } from 'src/messages/walletProxy/methods/sendTransaction/sendTransactionValidate';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';

export function btcSellTransactionValidate({
  request,
  walletStorage,
}: PromptRequest) {
  return sendTransactionValidate({
    request: {
      method: 'btc_sendTransaction',
      // `params` payload for sell contains extra data that send does not need
      // We remove it here, otherwise it will cause a validation error
      params: [request.params?.[0]],
    },
    network: WalletNetwork.Bitcoin,
    walletStorage,
  } as PromptRequest);
}
