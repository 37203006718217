import { ethers, Wallet } from 'ethers';
import { TransactionRequest } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import logger from '../../../../../utils/logger';
import erc165Abi from '../abis/erc165.json';
import {
  ERC1155InterfaceId,
  erc1155SafeBatchTransferFromSig,
  erc1155SafeTransferFromSig,
  ERC721InterfaceId,
  erc721SafeTransferFromSig,
} from '../constants';

export async function isTargetNFT(
  wallet: Wallet,
  tx: TransactionRequest,
): Promise<boolean> {
  const { to } = tx;
  let isEOA = false;
  let isNFT = false;

  if (to) {
    try {
      const code = await wallet.provider.getCode(to);
      // ???????????
      // eslint-disable-next-line no-return-assign
      if (code === '0x') return (isEOA = true);
    } catch (error) {
      logger.warn(`Failed calling 'getCode' on ${to}`, { error });
    }

    // do nft interface checks only if 'to' is a contract
    if (!isEOA) {
      const erc165Contract = new ethers.Contract(
        to,
        erc165Abi,
        wallet.provider,
      );
      let isERC1155;
      let isERC721;

      try {
        [isERC1155, isERC721] = await Promise.all([
          erc165Contract.callStatic.supportsInterface(ERC1155InterfaceId),
          erc165Contract.callStatic.supportsInterface(ERC721InterfaceId),
        ]);

        isNFT = isERC1155 || isERC721;
      } catch (error) {
        logger.warn(`Failed calling 'supportsInterface' on ${to}`, {
          error,
        });

        if (
          (error as Error).message.includes(
            'Transaction reverted without a reason string',
          )
        ) {
          isNFT = false; // if fails due to calling on an ERC20 contract
        } else {
          isNFT = true; // if fails due to RPC errors, default to true
        }
      }
    }
  } else {
    isNFT = true; // default to true if 'to' is undefined
  }

  return isNFT;
}

function getContractInfo(data: string) {
  const interface721 = new ethers.utils.Interface(erc721SafeTransferFromSig);
  const interface1155 = new ethers.utils.Interface(erc1155SafeTransferFromSig);
  const interface1155Batch = new ethers.utils.Interface(
    erc1155SafeBatchTransferFromSig,
  );

  // try to decode each interface and return which one is valid
  try {
    return {
      type: 'erc721',
      data: interface721.decodeFunctionData('safeTransferFrom', data),
    };
  } catch (e) {
    //
  }

  try {
    return {
      type: 'erc1155',
      data: interface1155.decodeFunctionData('safeTransferFrom', data),
    };
  } catch (e) {
    //
  }

  try {
    return {
      type: 'erc1155Batch',
      data: interface1155Batch.decodeFunctionData(
        'safeBatchTransferFrom',
        data,
      ),
    };
  } catch (e) {
    //
  }
}

export async function isSafeTransferFrom(
  wallet: Wallet,
  tx: TransactionRequest,
) {
  const { data, to } = tx;
  if (!to || !data) {
    return false;
  }

  const decoded = getContractInfo(data);

  if (!decoded) {
    return;
  }

  switch (decoded.type) {
    case 'erc721':
      return {
        from: decoded.data.from,
        to: decoded.data.to,
        nfts: [
          {
            name: `token ${decoded.data.tokenId}`,
            price: 0,
          },
        ],
      };
    case 'erc1155':
      return {
        from: decoded.data.from,
        to: decoded.data.to,
        nfts: [
          {
            name: `token ${decoded.data.id}`,
            price: 0,
          },
        ],
      };
    case 'erc1155Batch':
      return {
        from: decoded.data.from,
        to: decoded.data.to,
        nfts: decoded.data.ids.reduce(
          (acc: any, id: any, index: string | number) => {
            const amount = decoded.data.amounts[index];
            const nftCopies = Array(amount)
              .fill(0)
              .map(() => ({
                name: `token ${id}`,
                price: 0,
              }));
            return [...acc, ...nftCopies];
          },
          [] as any[],
        ),
      };
    default:
  }
}
