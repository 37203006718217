import { IsPositiveBigIntString } from '../../common/decorators/is-positive-bigint-string';
import { IsBitcoinAddress } from '../decorators/is-bitcoin-address';

export class BitcoinTransactionRequest {
  @IsBitcoinAddress()
  public readonly toAddress!: string;

  @IsPositiveBigIntString()
  public readonly value!: string;

  @IsPositiveBigIntString()
  public readonly fee!: string;
}
