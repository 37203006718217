import { WalletNetwork } from '@moonpay/login-common';
import { BaseStorage } from 'src/wallet/storage/BaseStorage';
import DEFAULT_CHAINS from 'src/wallet/storage/defaultChains';

export interface EthereumChainSpec {
  chainId: string;
  blockExplorerUrls?: string[];
  chainName?: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
}

const STORAGE_KEY = 'chains';

export type ChainsData = {
  [network in WalletNetwork]: {
    [chainId: string]: EthereumChainSpec;
  };
};

export class ChainsStorage extends BaseStorage<Partial<ChainsData>> {
  constructor() {
    super({ key: STORAGE_KEY, defaultValue: DEFAULT_CHAINS });
  }

  getChain(
    chainId: string,
    network: WalletNetwork,
  ): EthereumChainSpec | undefined {
    return this.value[network]?.[chainId];
  }

  getAvailableChains(network: WalletNetwork) {
    return this.value[network];
  }

  addChain(chain: EthereumChainSpec, network: WalletNetwork) {
    const chainId = parseInt(chain.chainId, 16).toString();
    this.update((chains) => {
      return {
        ...chains,
        [network]: {
          ...chains[network],
          [chainId]: chain,
        },
      };
    });
  }

  reset() {
    this.update(() => DEFAULT_CHAINS);
  }
}
