import { Expose } from 'class-transformer';

export interface ISolanaBalanceResponse {
  balance: string;
  unit: string;
}

export class SolanaGetBalanceResponse {
  @Expose()
  public readonly balance!: string;

  @Expose()
  public readonly unit!: string;

  constructor(data: Partial<ISolanaBalanceResponse>) {
    Object.assign(this, data);
  }
}
