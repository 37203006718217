import { WalletNetwork } from '@moonpay/login-common';
import { BigNumber, Contract, Wallet as EthersWallet } from 'ethers';
import { formatValue } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { EvmWalletErrors, MoonPayWalletError } from 'src/v2/common/errors';
import { PromptManager } from 'src/v2/common/prompts';
import { PromptType } from 'src/v2/common/prompts/prompt-map';
import { EvmSendTransactionProps } from 'src/v2/evm/prompts/evm-send-transaction.prompt';
import { EvmSendTransactionRequest } from 'src/v2/evm/requests/evm-send-transaction.request';
import { EvmSwitchNetworkRequest } from 'src/v2/evm/requests/evm-switch-network.request';
import { EvmGetBalanceResponse } from 'src/v2/evm/responses/evm-get-balance.response';
import { EvmSwitchNetworkResponse } from 'src/v2/evm/responses/evm-switch-network.response';
import {
  EvmSendTransactionResponse,
  IEvmSendTransactionResponse,
} from 'src/v2/evm/responses/evm-transfer-transaction.response';
import { EvmApiService } from 'src/v2/evm/services/evm-api.service';
import { EvmChainService } from 'src/v2/evm/services/evm-chain.service';
import { EvmWalletConnectionService } from 'src/v2/evm/services/wallet-connection.service';
import { EvmMethods } from 'src/v2/evm/types';
import {
  Controller,
  Params,
  PostMessage,
  Response,
} from 'src/v2/lib/decorators';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import type { AbstractWallet } from 'src/wallet/types/Wallet';
import { erc20Abi } from 'viem';

@Controller()
export class EvmController {
  private readonly network = WalletNetwork.Ethereum;

  private readonly walletConnectionService: EvmWalletConnectionService;

  private readonly evmApiService: EvmApiService;

  private readonly promptManager: PromptManager;

  private readonly evmChainService: EvmChainService;

  constructor(private readonly walletStorage: WalletStorage) {
    this.walletConnectionService = new EvmWalletConnectionService(
      this.walletStorage,
    );
    this.evmApiService = new EvmApiService();
    this.promptManager = new PromptManager({ network: this.network });
    this.evmChainService = new EvmChainService(this.walletStorage);
  }

  @PostMessage(EvmMethods.GetBalance)
  @Response(EvmGetBalanceResponse)
  public async getBalance(wallet: AbstractWallet) {
    const chainId = this.walletStorage.activeChainId.getActiveChainIdByNetwork(
      this.network,
    );
    if (!chainId) {
      throw new MoonPayWalletError(EvmWalletErrors.NO_EVM_CHAIN_ID_SET);
    }

    const chain = this.evmChainService.getChainByChainId(chainId);

    return this.evmApiService.getBalance({
      chainName: chain.name,
      address: wallet.address as `0x${string}`,
    });
  }

  @PostMessage(EvmMethods.SwitchNetwork)
  @Params(EvmSwitchNetworkRequest)
  @Response(EvmSwitchNetworkResponse)
  public async switchNetwork(
    wallet: AbstractWallet,
    body: EvmSwitchNetworkRequest,
  ): Promise<EvmSwitchNetworkResponse> {
    const activeChainId =
      this.walletStorage.activeChainId.getActiveChainIdByNetwork(this.network);

    const fromChain = this.evmChainService.getChainByChainId(activeChainId);
    const toChain = this.evmChainService.getChainByChainId(
      Number(body.chainId),
    );

    await this.promptManager.showPrompt(PromptType.EvmSwitchNetworkPrompt, {
      fromChain,
      toChain,
    });

    this.evmChainService.updateActiveChainId(Number(body.chainId));
    this.walletConnectionService.storeApprovedConnection(wallet.address);

    return { chainId: body.chainId };
  }

  @PostMessage(EvmMethods.SendTransaction)
  @Response(EvmSendTransactionResponse)
  @Params(EvmSendTransactionRequest)
  public async sendTransaction(
    wallet: AbstractWallet,
    {
      value,
      toWalletAddress,
      gasLimit,
      maxFeePerGas,
      maxPriorityFeePerGas,
      erc20,
    }: EvmSendTransactionRequest,
  ): Promise<IEvmSendTransactionResponse> {
    const activeChainId =
      this.walletStorage.activeChainId.getActiveChainIdByNetwork(this.network);

    const chain = this.evmChainService.getChainByChainId(activeChainId);

    const ethersWallet = wallet.wallet as EthersWallet;

    let data: string | undefined;
    if (erc20) {
      const props = {
        to: toWalletAddress,
        value: formatValue(BigNumber.from(value || 0), this.network).toString(),
        networkFee: formatValue(
          BigNumber.from(maxFeePerGas || 0),
          this.network,
        ),
        tokenName: erc20.name,
        tokenSymbol: erc20.symbol,
        functionName: erc20.functionName,
        currencyCode: chain.nativeCurrency.symbol,
      };

      await this.promptManager.showPrompt(
        PromptType.EvmErc20SendTransactionPrompt,
        props,
      );

      const contract = new Contract(
        erc20.contractAddress,
        erc20Abi,
        ethersWallet.provider,
      );

      // we only support transfer and approve functions, the args are the same
      data = contract.interface.encodeFunctionData(erc20.functionName, [
        toWalletAddress,
        value,
      ]);
    } else {
      const props: EvmSendTransactionProps = {
        value: BigInt(value || 0),
        networkFee: BigInt(maxFeePerGas || 0),
        from: wallet.address as `0x${string}`,
        to: toWalletAddress as `0x${string}`,
        chain,
        onApprove: () => undefined,
        onReject: () => undefined,
      };

      await this.promptManager.showPrompt(
        PromptType.EvmSendTransactionPrompt,
        props,
      );
    }

    const { count: nonce } = await this.evmApiService.getTransactionCount({
      chainName: chain.name,
      address: wallet.address as `0x${string}`,
    });

    const signedTransaction = await ethersWallet.signTransaction({
      type: 2,
      chainId: chain.chainId,
      to: erc20?.contractAddress ?? toWalletAddress,
      value: BigNumber.from(value),
      maxFeePerGas: BigNumber.from(maxFeePerGas),
      maxPriorityFeePerGas: BigNumber.from(maxPriorityFeePerGas),
      nonce,
      gasLimit: BigNumber.from(gasLimit),
      data,
    });

    return this.evmApiService.sendTransaction({
      chainName: chain.name,
      signedTransaction: signedTransaction as `0x${string}`,
    });
  }
}
