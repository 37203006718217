import { plainToInstance } from 'class-transformer';
import { validate } from 'class-validator';
import logger from 'src/utils/logger';

export const validateBody = async (body: any, bodyClass: any) => {
  const bodyObject = plainToInstance(bodyClass, body);
  const errors = await validate(bodyObject, {
    whitelist: true,
    forbidNonWhitelisted: true,
  });

  if (errors.length > 0) {
    logger.error(`Body validation failed: ${JSON.stringify(errors)}`);
    throw new Error(`Validation failed: ${JSON.stringify(errors)}`);
  }

  return bodyObject;
};
