// outsourced for mocking purposes in components
export const storageGetItem = (
  name: string,
  storageType: Storage = localStorage,
): string | null => {
  return storageType.getItem(name);
};

export const storageSetItem = (
  name: string,
  value: string,
  storageType: Storage = localStorage,
): void => {
  storageType.setItem(name, value);
};

export const storageRemoveItem = (
  name: string,
  storageType: Storage = localStorage,
): void => {
  return storageType.removeItem(name);
};
