import { MethodImplementation } from 'src/messages/walletProxy/methods/types';
import {
  addNetworkExecute,
  addNetworkPrompt,
  addNetworkReject,
  addNetworkValidate,
} from './addNetwork';

const addNetwork: MethodImplementation = {
  validate: addNetworkValidate,
  prompt: addNetworkPrompt,
  execute: addNetworkExecute,
  reject: addNetworkReject,
};

export { addNetwork };
