import { WalletNetwork } from '@moonpay/login-common';
import { Wallet } from 'ethers';
import { useState } from 'react';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

const errorManager = new ErrorManager(__filename);

export type SwitchNetworkPillBodyProps = {
  walletStorage: WalletStorage;
  // eslint-disable-next-line react/no-unused-prop-types
  wallet: Wallet;
  // eslint-disable-next-line react/no-unused-prop-types
  origin: string;
  network: WalletNetwork;
  onNetworkChange: (chainId: number) => void;
};

export const SwitchNetworkPillBody = ({
  walletStorage,
  network,
  onNetworkChange,
}: SwitchNetworkPillBodyProps) => {
  // wow do you ever just get coding deja vu? I feel like I've written this code before
  const [activeChainId, setActiveChainId] = useState<number>(
    walletStorage.activeChainId.getActiveChainIdByNetwork(network),
  );

  const availableChains = walletStorage.chains.getAvailableChains(network);

  const handleSwitchNetwork = async (chainId: number) => {
    const newChain = walletStorage.chains.getChain(chainId.toString(), network);
    if (!newChain) {
      throw errorManager.getServerError(
        'SwitchNetworkPillBody',
        `Invalid chainId`,
      );
    }

    walletStorage.activeChainId.setActiveChainIdByNetwork(network, chainId);

    EventTrackingUtils.trackSegmentEvent(
      SegmentTrackEvent.switchNetworkCompleted,
    );

    setActiveChainId(chainId);
    onNetworkChange(chainId);
  };

  return (
    <div className="flex flex-col space-y-2">
      {Object.keys(availableChains!).map((chainId) => {
        const chain = availableChains![chainId];
        const connected = activeChainId === Number(chainId);
        const chainIcon = getChainIcon(network, chainId);
        return (
          <div key={chainId} className="flex flex-col space-y-1 px-2">
            <div
              className="flex flex-row h-16 justify-between px-4 py-2 cursor-pointer"
              onClick={() => handleSwitchNetwork(Number(chainId))}
            >
              <div className="flex flex-row items-center">
                <div className="flex items-center justify-center w-10 h-10">
                  {chainIcon({})}
                </div>

                <div
                  className="font-bold h-10 flex items-center ml-4"
                  style={{
                    fontSize: 20,
                  }}
                >
                  {chain.chainName}
                </div>
              </div>
              <div className="flex flex-row space-x-2 rounded-md align-middle py-1 items-center">
                {connected && (
                  <div
                    className="py-2 px-4 rounded-full font-bold"
                    style={{
                      backgroundColor: 'rgba(118, 118, 128, 0.1)',
                      color: 'rgba(100, 100, 100, 0.8)',
                    }}
                  >
                    Connected
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
