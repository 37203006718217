import { WalletNetwork } from '@moonpay/login-common';
import { BitcoinSwitchNetworkRequest } from 'src/v2/bitcoin/requests/bitcoin-switch-network.request';
import {
  BitcoinAccountsResponse,
  type IBitcoinAccountsResponse,
} from 'src/v2/bitcoin/responses/bitcoin-accounts.response';
import {
  BitcoinGetBalanceResponse,
  IBitcoinGetBalanceResponse,
} from 'src/v2/bitcoin/responses/bitcoin-get-balance.response';
import {
  BitcoinSwitchNetworkResponse,
  IBitcoinSwitchNetworkResponse,
} from 'src/v2/bitcoin/responses/bitcoin-switch-network.response';

import { NetworkBitcoinChain } from 'src/types/NetworkChain';
import { BitcoinNetworkEnvironmentManager } from 'src/v2/bitcoin/services/network-environment-manager.service';
import { BitcoinWalletConnectionService } from 'src/v2/bitcoin/services/wallet-connection.service';
import {
  MoonPayWalletError,
  SecureWalletCommonErrors,
} from 'src/v2/common/errors';
import { PromptManager } from 'src/v2/common/prompts';
import { PromptType } from 'src/v2/common/prompts/prompt-map';
import {
  Controller,
  Params,
  PostMessage,
  Response,
} from 'src/v2/lib/decorators';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { formatValueBtc } from '../../messages/walletProxy/methods/sendTransaction/utils';
import type { AbstractWallet } from '../../wallet/types/Wallet';
import { BitcoinTransactionRequest } from './requests/bitcoin-transaction.request';
import {
  BitcoinTransactionResponse,
  IBitcoinTransactionResponse,
} from './responses/bitcoin-transaction.response';
import { BitcoinTransactionService } from './services/bitcoin-transaction.service';
import {
  BitcoinMethods,
  BitcoinNetworkEnvironment,
  BitcoinNetworkEnvironmentToChainIdMap,
} from './types';

import { BitcoinApiService } from './services/bitcoin-api.service';

@Controller()
export class BitcoinController {
  private readonly network = WalletNetwork.Bitcoin;

  private readonly walletConnectionService: BitcoinWalletConnectionService;

  private readonly bitcoinApiService: BitcoinApiService;

  private readonly promptManager: PromptManager;

  private readonly networkEnvironmentManager: BitcoinNetworkEnvironmentManager;

  constructor(private readonly walletStorage: WalletStorage) {
    this.walletConnectionService = new BitcoinWalletConnectionService(
      this.walletStorage,
    );
    this.bitcoinApiService = new BitcoinApiService();
    this.promptManager = new PromptManager({ network: this.network });

    this.networkEnvironmentManager = new BitcoinNetworkEnvironmentManager(
      this.walletStorage,
    );
  }

  @PostMessage(BitcoinMethods.Accounts)
  @Response(BitcoinAccountsResponse)
  public async accounts(
    wallet: AbstractWallet,
  ): Promise<IBitcoinAccountsResponse> {
    const isConnected =
      this.walletStorage.connections.checkWalletConnectionStatus({
        address: wallet.address,
        chainId: this.walletStorage.activeChainId.getActiveChainIdByNetwork(
          this.network,
        ),
        origin,
        network: this.network,
      });

    if (!isConnected) {
      await this.promptManager.showPrompt(PromptType.BitcoinAccountsPrompt, {
        onReject: () => {
          this.walletConnectionService.storeRejectedConnection(wallet.address);
        },
      });
    }

    await this.walletConnectionService.storeApprovedConnection(wallet.address);

    return {
      addresses: [wallet.address],
    };
  }

  @PostMessage(BitcoinMethods.SwitchNetwork)
  @Params(BitcoinSwitchNetworkRequest)
  @Response(BitcoinSwitchNetworkResponse)
  public async switchNetwork(
    wallet: AbstractWallet,
    body: BitcoinSwitchNetworkRequest,
  ): Promise<IBitcoinSwitchNetworkResponse> {
    const fromNetworkEnvironment =
      this.networkEnvironmentManager.getActiveNetworkEnvironment();

    await this.promptManager.showPrompt(PromptType.BitcoinSwitchNetworkPrompt, {
      fromNetworkEnvironment,
      toNetworkEnvironment: body.networkEnvironment,
    });
    let walletAddress = wallet.address;

    if (body.networkEnvironment === BitcoinNetworkEnvironment.Testnet) {
      const testnetWallet = await WalletService.cryptoWalletFactory(
        WalletNetwork.Bitcoin,
      ).createFromMnemonic(
        wallet.mnemonic.phrase,
        BitcoinNetworkEnvironmentToChainIdMap[
          BitcoinNetworkEnvironment.Testnet
        ],
      );

      if (!testnetWallet) {
        throw new MoonPayWalletError(
          SecureWalletCommonErrors.WALLET_RESTORE_ERROR,
        );
      }
      walletAddress = testnetWallet.data?.address || '';
      if (!walletAddress) {
        throw new MoonPayWalletError(
          SecureWalletCommonErrors.WALLET_RESTORE_ERROR,
        );
      }
    }

    this.networkEnvironmentManager.updateActiveChainId(body.networkEnvironment);
    await this.walletConnectionService.storeApprovedConnection(walletAddress);
    return {
      networkEnvironment: body.networkEnvironment,
    };
  }

  @PostMessage(BitcoinMethods.SendTransaction)
  @Params(BitcoinTransactionRequest)
  @Response(BitcoinTransactionResponse)
  public async sendTransaction(
    wallet: AbstractWallet,
    { toAddress, value, fee }: BitcoinTransactionRequest,
  ): Promise<IBitcoinTransactionResponse> {
    const bitcoinTransactionService = new BitcoinTransactionService(
      wallet,
      this.networkEnvironmentManager,
    );

    await this.promptManager.showPrompt(
      PromptType.BitcoinSendTransactionPrompt,
      {
        fromAddress: wallet.address,
        toAddress,
        valueInBtc: formatValueBtc(Number(value)),
        networkFeeInBtc: formatValueBtc(Number(fee)),
      },
    );

    const transactionHash = await bitcoinTransactionService.sendTransaction(
      toAddress,
      value,
      fee,
    );
    return new BitcoinTransactionResponse({ transactionHash });
  }

  @PostMessage(BitcoinMethods.GetBalance)
  @Response(BitcoinGetBalanceResponse)
  public async getBalance(
    wallet: AbstractWallet,
  ): Promise<IBitcoinGetBalanceResponse> {
    const { address } = wallet;
    const bitcoinChainId =
      this.walletStorage.activeChainId.getActiveChainIdByNetwork(this.network);

    const chainNameMapping = {
      [NetworkBitcoinChain.Mainnet]: BitcoinNetworkEnvironment.Mainnet,
      [NetworkBitcoinChain.Testnet]: BitcoinNetworkEnvironment.Testnet,
    };

    // if we don't have a mapping, default to mainnet
    const chainName =
      chainNameMapping[bitcoinChainId as NetworkBitcoinChain] ||
      BitcoinNetworkEnvironment.Mainnet;

    return this.bitcoinApiService.getBalance({ chainName, address });
  }
}
