import { ChevronRightIcon, Icon } from '@moonpay-widget/ui-kit';

export type MoreActionsPillBodyProps = Record<string, unknown>;

// eslint-disable-next-line no-empty-pattern
export const MoreActionsPillBody = ({}: MoreActionsPillBodyProps) => {
  const availableActions = [
    {
      name: 'Disconect Wallet',
    },
    {
      name: 'Expand Wallet',
    },
    {
      name: 'Export Private Key',
    },
    {
      name: 'Default currency',
    },
    {
      name: 'Support',
    },
  ];

  return (
    <div className="flex flex-col space-y-1 py-4">
      {availableActions.map((action) => {
        return (
          <div key={action.name} className="flex flex-col space-y-1 px-2">
            <div className="flex flex-row h-16 justify-between px-4 py-2 cursor-pointer">
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-center w-full">
                  <div
                    className="flex items-center justify-center w-10 h-10 rounded-full"
                    style={{
                      backgroundColor: '#00000020',
                    }}
                  />
                  <div
                    className="font-bold h-10 flex items-center ml-4"
                    style={{
                      fontSize: 20,
                    }}
                  >
                    {action.name}
                  </div>
                </div>
                <div>
                  <Icon icon={ChevronRightIcon} />
                </div>
              </div>
            </div>
            <hr
              style={{
                marginLeft: '15%',
                width: '70%',
                color: '#EEEEEE',
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
