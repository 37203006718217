import { BigNumber, ethers, Wallet } from 'ethers';
import {
  getTokenList,
  TokenInfo,
} from 'src/messages/walletProxy/methods/sendTransaction/erc20/tokenList';
import { TransactionRequest } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import logger from 'src/utils/logger';
import erc20Abi from './erc20.json';

export enum Erc20TransactionType {
  APPROVAL = 'approval',
  TRANSFER = 'transfer',
  CUSTOM = 'custom',
}

export interface Erc20Action {
  type: Erc20TransactionType;
}

export interface Erc20Approval extends Erc20Action {
  token: TokenInfo;
  spender: string;
  value: BigNumber;
  type: Erc20TransactionType.APPROVAL;
}

export interface Erc20Transfer extends Erc20Action {
  token: TokenInfo;
  to: string;
  value: BigNumber;
  type: Erc20TransactionType.TRANSFER;
}

export interface Erc20Custom extends Erc20Action {
  token: TokenInfo;
  spender: string;
  value: BigNumber;
  type: Erc20TransactionType.CUSTOM;
}

export type Erc20Actions = Erc20Transfer | Erc20Approval | Erc20Custom;

async function getToken(address: string, chainId: number) {
  const tokenList = await getTokenList();
  return tokenList.tokens.find(
    (t) => t.address?.toLowerCase() === address && t.chainId === chainId,
  );
}

// return the first token with the given symbol
async function getTokenBySymbol(symbol: string) {
  const tokenList = await getTokenList();
  const filtered = tokenList.tokens.filter(
    (t) => t.symbol.toLowerCase() === symbol.toLowerCase(),
  );
  if (filtered.length > 0) {
    return filtered[0];
  }
}

async function getErc20Action(
  wallet: Wallet,
  tx: TransactionRequest,
): Promise<Erc20Actions | undefined> {
  const { to, data } = tx;
  if (!to || !data) {
    logger.warn('Missing "to" or "data" in transaction.');
    return;
  }

  const chainId = await wallet.getChainId();
  // TODO: FIX
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = await getToken(tx.to!.toLowerCase(), chainId);
  if (token) {
    const tokenContract = new ethers.Contract(
      token.address,
      erc20Abi,
      wallet.provider,
    );
    let parsed;
    try {
      parsed = tokenContract.interface.parseTransaction({ data });
    } catch {
      // Arbitrary tx for erc20, show normal modal.
      return {
        spender: wallet.address,
        value: BigNumber.from(tx.value || '0'),
        token,
        type: Erc20TransactionType.CUSTOM,
      };
    }
    if (parsed.name === 'approve') {
      return {
        spender: parsed.args.spender as string,
        value: parsed.args.value as BigNumber,
        token,
        type: Erc20TransactionType.APPROVAL,
      };
    }
    if (parsed.name === 'transfer') {
      return {
        to: parsed.args.to as string,
        value: parsed.args.value as BigNumber,
        token,
        type: Erc20TransactionType.TRANSFER,
      };
    }
  }
}

export { getErc20Action, getTokenBySymbol };
