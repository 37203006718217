import { Expose, Type } from 'class-transformer';

export interface ISolanaTransferTransactionResponse {
  signature: string;
}

export class SolanaTransferTransactionResponse {
  @Type(() => String)
  @Expose()
  public readonly signature!: string;

  constructor(data: Partial<ISolanaTransferTransactionResponse>) {
    Object.assign(this, data);
  }
}
