import { uuidV4 } from '@moonpay-widget/common';
import * as LDClient from 'launchdarkly-js-client-sdk';
import environment from 'src/environment';
import StorageUtils from 'src/utils/storage';
import logger from './logger';

const LD_SESSION_ID_COOKIE = 'ld_session_id';
const LD_DEVICE_ID_COOKIE = 'ld_device_id';

// Reference apps/widget/src/hooks/useFeatureFlag/index.tsx
/**
 * Added to LD as "deviceId" custom property and used in "by" targeting section,
 * so that visitors will get the same experience through their whole journey
 * on one device, instead of sometimes seeing two variants of an experiment.
 */
const getDeviceId = async () =>
  StorageUtils.getOrSetItem(LD_DEVICE_ID_COOKIE, uuidV4(), {
    expires: 365 * 10,
  });

/**
 * @deprecated
 * We're keeping the SessionId because we have some Feature Flags that are
 * still using it. But we should use the DeviceId instead.
 */
const getSessionId = async () => {
  const newSessionId = await getDeviceId();
  return StorageUtils.getOrSetItem(LD_SESSION_ID_COOKIE, newSessionId, {
    expires: 365 * 10,
  });
};

export async function launchDarkly() {
  // Attempt to use customer as LD User
  // Reference: apps/widget/src/hooks/useFeatureFlag/index.tsx:122
  const customerId = await StorageUtils.get('customerId');
  const sessionId = await getSessionId();
  const deviceId = await getDeviceId();

  const context = {
    kind: 'user',
    key: customerId || sessionId,
    custom: {
      sessionId,
      deviceId,
    },
  };
  const client = LDClient.initialize(
    environment.launchdarklyClientSideId,
    context,
  );

  try {
    await client.waitForInitialization();
  } catch (error) {
    logger.warn('Launch darkly initialization failed', { error });
  }

  return { client, context };
}
