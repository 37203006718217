export const ERC1155InterfaceId = '0xd9b67a26';

export const ERC721InterfaceId = '0x80ac58cd';

export const erc721SafeTransferFromSig = [
  'function safeTransferFrom(address from, address to, uint256 tokenId)',
];

export const erc1155SafeTransferFromSig = [
  'function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data)',
];

export const erc1155SafeBatchTransferFromSig = [
  'function safeBatchTransferFrom(address from, address to, uint256[] ids, uint256[] amounts, bytes data)',
];
