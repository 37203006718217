import { WalletNetwork } from '@moonpay/login-common';
import {
  Keypair,
  PublicKey,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';
import bs58 from 'bs58';
import nacl from 'tweetnacl';
import { NetworkSolChain } from '../../../types/NetworkChain';
import logger from '../../../utils/logger';
import { WalletStorage } from '../../../wallet/storage/WalletStorage';
import { AbstractWallet } from '../../../wallet/types/Wallet';
import {
  MoonPayWalletsApiClient,
  WalletsApiResponse,
} from '../../common/clients/moonpay-wallets-api.client';
import { MoonPayWalletError, SolanaWalletErrors } from '../../common/errors';
import { SolanaNetworkEnvironment } from '../types';

type SolBlockhashApiResponse = {
  data: { blockhash: string };
};

type SolTransactionApiResponse = {
  data: { signature: string };
};

enum SolanaTransactionApiResponseErrors {
  SOL_INS_FUNDS = '5_WL_SOL_INS_FUNDS',
  SOL_RECIP_INS_RENT = '5_WL_SOL_RECIP_INS_RENT',
  SOL_SENDER_INS_RENT = '5_WL_SOL_SENDER_INS_RENT',
}

export class SolanaTransactionService {
  private readonly signingWallet: Keypair;

  constructor(
    wallet: AbstractWallet,
    private readonly walletStorage: WalletStorage,
  ) {
    const keyPair = wallet.wallet;
    if (!(keyPair instanceof Keypair)) {
      throw new MoonPayWalletError(SolanaWalletErrors.SOL_INVALID_KEYPAIR);
    }

    try {
      this.signingWallet = Keypair.fromSecretKey(keyPair.secretKey);
    } catch (e) {
      throw new MoonPayWalletError(SolanaWalletErrors.SOL_WALLET_DECODE_ERROR);
    }
  }

  public async sendTransferTransaction(
    toAddress: string,
    value: number,
  ): Promise<string> {
    const networkEnvironment = this.getActiveSolanaEnvironment();
    const blockhashResponse =
      await MoonPayWalletsApiClient.callApi<SolBlockhashApiResponse>(
        'GET',
        `/v1/solana/${networkEnvironment}/blockhash`,
      );

    const { blockhash } = blockhashResponse.data;
    if (!blockhash) {
      throw new MoonPayWalletError(SolanaWalletErrors.SOL_UNEXPECTED_RESPONSE);
    }

    const fromWalletPubKey = this.signingWallet.publicKey;
    const toWalletPubKey = new PublicKey(toAddress);

    const instructions = [
      SystemProgram.transfer({
        fromPubkey: fromWalletPubKey,
        toPubkey: toWalletPubKey,
        lamports: value,
      }),
    ];
    const messageV0 = new TransactionMessage({
      payerKey: fromWalletPubKey,
      recentBlockhash: blockhash,
      instructions,
    }).compileToV0Message();
    const transaction = new VersionedTransaction(messageV0);
    transaction.sign([this.signingWallet]);

    const base58VersionedTransaction = bs58.encode(transaction.serialize());
    const sendTransactionBody = JSON.stringify({ base58VersionedTransaction });
    const signatureResponse =
      await MoonPayWalletsApiClient.callApi<SolTransactionApiResponse>(
        'POST',
        `/v1/solana/${networkEnvironment}/transaction`,
        sendTransactionBody,
      );
    this.handleTransactionError(signatureResponse);

    const { signature } = signatureResponse.data;
    // TODO: I'd like to guarantee that the response from the API conforms to the type given in the generic on callApi but couldn't work out a way to do it yet, improvements are welcome so we don't have to keep checking for the existence of data
    if (!signature) {
      throw new MoonPayWalletError(SolanaWalletErrors.SOL_UNEXPECTED_RESPONSE);
    }

    return signature;
  }

  public signMessage(message: string): string {
    let messageBuffer;

    // Decode the base58 encoded message to a Uint8Array
    try {
      messageBuffer = bs58.decode(message);
    } catch (e) {
      throw new MoonPayWalletError(SolanaWalletErrors.SOL_WALLET_DECODE_ERROR);
    }

    // Sign the message using tweetnacl
    const signature = nacl.sign.detached(
      messageBuffer,
      this.signingWallet.secretKey,
    );
    // Encode the signature in base58 to handle it as a string
    return bs58.encode(signature);
  }

  private handleTransactionError(
    signatureResponse: WalletsApiResponse<SolTransactionApiResponse>,
  ) {
    const errorCode = signatureResponse.moonpayErrorCode;
    if (errorCode) {
      switch (errorCode) {
        case SolanaTransactionApiResponseErrors.SOL_INS_FUNDS:
          throw new MoonPayWalletError(SolanaWalletErrors.SOL_INS_FUNDS);
        case SolanaTransactionApiResponseErrors.SOL_RECIP_INS_RENT:
          throw new MoonPayWalletError(
            SolanaWalletErrors.SOL_RECIP_INS_RENT(signatureResponse.error),
          );
        case SolanaTransactionApiResponseErrors.SOL_SENDER_INS_RENT:
          throw new MoonPayWalletError(
            SolanaWalletErrors.SOL_SENDER_INS_RENT(signatureResponse.error),
          );
        default:
          logger.error(
            'Solana transaction failed to broadcast via the wallets API, please investigate',
            {
              signatureResponse,
            },
          );
          throw new MoonPayWalletError(
            SolanaWalletErrors.SOL_TRANSACTION_REQUEST_FAILURE,
          );
      }
    }
  }

  private getActiveSolanaEnvironment(): SolanaNetworkEnvironment {
    const activeChain =
      this.walletStorage.activeChainId.getActiveChainIdByNetwork(
        WalletNetwork.Solana,
      );
    switch (activeChain) {
      case NetworkSolChain.Local:
        return SolanaNetworkEnvironment.Local;
      case NetworkSolChain.Devnet:
        return SolanaNetworkEnvironment.Devnet;
      case NetworkSolChain.Mainnet:
      default:
        return SolanaNetworkEnvironment.Mainnet;
    }
  }
}
