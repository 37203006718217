import { Text } from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { strings } from 'src/i18n';

export interface PersonalSignMessagePromptProps {
  text: string[];
}

const PersonalSignMessage: FunctionComponent<
  PersonalSignMessagePromptProps
> = ({ text }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Text variant="footnote" className="text-label-secondary mt-6">
        {t(strings.prompts.signatureRequest.subTitle)}
      </Text>
      <div className="px-4 mb-8 mt-2 bg-system-background-secondary h-48 rounded-xl overflow-auto">
        {text.map((textPart) => {
          return (
            <Text
              key={textPart}
              variant="subhead"
              className="my-4 text-label-primary"
            >
              {textPart}
            </Text>
          );
        })}
      </div>
    </div>
  );
};

export default PersonalSignMessage;
