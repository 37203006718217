import { Type } from 'class-transformer';
import { IsEthereumAddress, IsOptional, ValidateNested } from 'class-validator';
import { Erc20TransactionRequest } from 'src/v2/evm/requests/erc20-send-transaction.request';
import { IsPositiveBigIntString } from '../../common/decorators/is-positive-bigint-string';

export class EvmSendTransactionRequest {
  @IsEthereumAddress()
  public readonly toWalletAddress!: string;

  @IsPositiveBigIntString()
  public readonly value!: string;

  @IsPositiveBigIntString()
  public readonly gasLimit!: string;

  @IsPositiveBigIntString({ allowZero: true })
  public readonly maxPriorityFeePerGas!: string;

  @IsPositiveBigIntString()
  public readonly maxFeePerGas!: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => Erc20TransactionRequest)
  public readonly erc20?: Erc20TransactionRequest;
}
