import { instanceToPlain, plainToInstance } from 'class-transformer';
import logger from 'src/utils/logger';
import { SecureWalletCommonErrors } from 'src/v2/common/errors/error-codes';
import { MoonPayWalletError } from 'src/v2/common/errors/moonpay-wallet-error';
import {
  MESSAGE_HANDLER_METADATA_KEY,
  MESSAGE_REQUEST_PARAMS_METADATA_KEY,
  MESSAGE_RESPONSE_METADATA_KEY,
} from 'src/v2/lib/constants';
import {
  requestHasBody,
  sendWalletMessageProxyResponse,
  validateBody,
} from 'src/v2/lib/utils';
import { sendMoonPayWalletResponse } from 'src/v2/lib/utils/response-utils';
import { isAbstractWallet } from '../../../wallet/types/Wallet';

const sendResponse = ({
  handler,
  result,
  error,
  legacy,
}: {
  handler: any;
  result?: any;
  error?: any;
  legacy?: boolean;
}) => {
  if (legacy) {
    return sendWalletMessageProxyResponse({
      handler,
      error,
      result,
    });
  }

  return sendMoonPayWalletResponse({
    handler,
    result,
    error,
  });
};

// TODO: make this actually readable - break into smaller, well defined functions
export function PostMessage(method?: string, options?: { legacy?: boolean }) {
  return function postMessageHandler(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ) {
    const existingMethods =
      Reflect.getMetadata(MESSAGE_HANDLER_METADATA_KEY, target) || [];

    existingMethods.push({ methodName: propertyKey, method });

    Reflect.defineMetadata(
      MESSAGE_HANDLER_METADATA_KEY,
      existingMethods,
      target,
    );

    const originalMethod = descriptor.value;

    descriptor.value = async function newValue(...args: any[]) {
      const handler = { target, propertyKey };
      const hasBody = requestHasBody(target, propertyKey);

      logger.info('V2 Post message handler called:', {
        method,
      });

      if (hasBody) {
        // We can safely assume index 1 for params -> this gets set in @Controller in most cases
        // If it's not set, we'll try args[0] as there are some routes that don't require AbstractWallet
        let payload = args[1];
        if (!payload && !isAbstractWallet(args[0])) {
          // eslint-disable-next-line prefer-destructuring
          payload = args[0];
        }

        const bodyClass = Reflect.getMetadata(
          MESSAGE_REQUEST_PARAMS_METADATA_KEY,
          target,
          propertyKey,
        );

        try {
          const body = Array.isArray(payload) ? payload[0] : payload;

          await validateBody(body, bodyClass);
        } catch (error) {
          logger.info('v2 handler body validation failed', {
            method,
            body: payload,
            error,
          });

          const badRequestError = SecureWalletCommonErrors.BAD_REQUEST;

          return sendResponse({
            handler,
            error: badRequestError,
            legacy: options?.legacy,
          });
        }
      }

      try {
        const returnValue = await originalMethod.apply(this, args);

        const ResponseClass = Reflect.getMetadata(
          MESSAGE_RESPONSE_METADATA_KEY,
          target,
          propertyKey,
        );

        // If the developer forgets to implement a response format, we should stop any response being sent
        if (!ResponseClass || !ResponseClass.constructor) {
          logger.error('v2 handler tried to respond with no formatting', {
            method,
          });

          throw new MoonPayWalletError(SecureWalletCommonErrors.INTERNAL_ERROR);
        }

        const sanitisedReturnValue = plainToInstance(
          ResponseClass,
          returnValue,
          {
            excludeExtraneousValues: true,
            strategy: 'excludeAll',
          },
        );

        return sendResponse({
          handler,
          result: instanceToPlain(sanitisedReturnValue),
          legacy: options?.legacy,
        });
      } catch (e) {
        logger.info('Post message handler threw an error:', {
          method,
          error: e,
        });

        const moonpayCastError = e as {
          moonpayErrorCode: string;
          message: string;
        };

        if (moonpayCastError?.moonpayErrorCode) {
          return sendResponse({
            handler,
            error: {
              code: moonpayCastError.moonpayErrorCode,
              message: moonpayCastError.message,
            },
            legacy: options?.legacy,
          });
        }

        if (e instanceof MoonPayWalletError) {
          return sendResponse({
            handler,
            error: e.moonpayError,
            legacy: options?.legacy,
          });
        }

        logger.error('Post message handler threw an unexpected error:', {
          method,
          error: e,
        });

        return sendResponse({
          handler,
          error: SecureWalletCommonErrors.INTERNAL_ERROR,
          legacy: options?.legacy,
        });
      }
    };

    return descriptor;
  };
}
