import { Expose } from 'class-transformer';
import { EvmUnit } from 'src/v2/evm/types';

export interface IEvmGetBalanceResponse {
  value: string;
  unit: EvmUnit;
}

export class EvmGetBalanceResponse {
  @Expose()
  public readonly value!: string;

  @Expose()
  public readonly unit!: EvmUnit;

  constructor(data: Partial<IEvmGetBalanceResponse>) {
    Object.assign(this, data);
  }
}
