import BitcoinIcon from '@moonpay-widget/ui-kit/src/CurrencyIcons/BitcoinIcon';
import {
  GenericSellTransaction,
  GenericSellTransactionProps,
} from 'src/messages/walletProxy/methods/sellTransaction/genericSellTransaction';
import { SellTransactionPromptReturnType } from 'src/messages/walletProxy/methods/sellTransaction/types';
import { btcEstimateGas } from 'src/messages/walletProxy/methods/sendTransaction/networkFeeCalculators/btcNetworkFeeCalculator';
import { formatValueBtc } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';

export const constructBtcSellPromptProps = async (props: PromptRequest) => {
  const { request, walletStorage, network, abstractWallet } = props;

  const networkFee = await btcEstimateGas(
    request,
    abstractWallet,
    request.params![0],
    walletStorage.activeChainId.getActiveChainIdByNetwork(network),
  );
  const { value, to } = request.params![0];
  const sellPayload = request.params![1];
  return {
    currencyCode: 'BTC',
    value: formatValueBtc(value || 0),
    networkFee,
    from: abstractWallet.address,
    to,
    ...sellPayload,
  } as GenericSellTransactionProps;
};

export async function btcSellTransactionPrompt(
  props: PromptRequest,
): Promise<SellTransactionPromptReturnType> {
  const sellProps = await constructBtcSellPromptProps(props);
  return {
    title: `Selling ${sellProps.value} ${sellProps.currencyCode}`,
    accountLogo: BitcoinIcon,
    component: <GenericSellTransaction {...sellProps} />,
    aboveButtonsText:
      'Upon sending your crypto, we will promptly initiate the sale at the current market rate. Your funds will be transferred to you immediately, and may take between a few minutes to 2 business days to arrive.',
    approveButtonText: 'Confirm and sell',
  };
}
