import { SecureWalletErrorCode } from 'src/v2/common/errors/error-codes';

export class MoonPayWalletError extends Error {
  public readonly code: string;

  constructor(public readonly moonpayError: SecureWalletErrorCode) {
    super(moonpayError.message);
    this.code = moonpayError.code;
  }
}
