import { ComponentType, lazy, LazyExoticComponent } from 'react';
import { storageGetItem, storageSetItem } from 'src/utils/browserStorage';
import logger from './logger';

type lazyComponentType<T> = () => Promise<{ default: ComponentType<T> }>;

function componentLoaderWithRetry<T>(
  lazyComponent: lazyComponentType<T>,
  attemptsLeft: number,
): Promise<{ default: ComponentType<T> }> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        // eslint-disable-next-line consistent-return
        setTimeout(() => {
          // This prevents the user from being stuck in a reload loop if the chunk is completely missing
          if (storageGetItem('lazyWithRetry', sessionStorage)) {
            throw error;
          }
          // if all attempts ran out to refetch the chunk, set sessionStorage to true and reload current route
          if (attemptsLeft === 1) {
            storageSetItem('lazyWithRetry', 'true', sessionStorage);
            logger.info(`We are not reloading the widget.`);
          }
          componentLoaderWithRetry(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
          // Interval set to 100 ms
        }, 100);
      });
  });
}

const lazyWithRetry = <T>(
  lazyComponent: lazyComponentType<T>,
  // Default set to 2 attempts
  attemptsLeft = 2,
): LazyExoticComponent<ComponentType<T>> =>
  lazy(() => componentLoaderWithRetry(lazyComponent, attemptsLeft));

export default lazyWithRetry;
