import { WalletNetwork } from '@moonpay/login-common';
import type { ProviderRequest } from '@moonpay/login-common';
import type { Wallet as EthersWallet } from 'ethers';
import type { AbstractWallet } from '../../../../../wallet/types/Wallet';
import { ErrorManager } from '../../../../../utils/errorManager';
import { btcEstimateGas } from './btcNetworkFeeCalculator';
import { evmEstimateGas } from './evmNetworkFeeCalculator';

const errorManager = new ErrorManager(__filename);

export function estimateGas(
  transaction: any,
  network: WalletNetwork,
  wallet: EthersWallet,
  request: ProviderRequest,
  abstractWallet: AbstractWallet,
  chainId: number,
): Promise<string> {
  switch (network) {
    case WalletNetwork.Ethereum:
      return evmEstimateGas(wallet, transaction);
    case WalletNetwork.Bitcoin:
      return btcEstimateGas(request, abstractWallet, transaction, chainId);
    default:
      throw errorManager.getServerError(
        'sendTransactionPrompt',
        `Invalid network`,
      );
  }
}
