import {
  exportMnemonicExecute,
  exportMnemonicPrompt,
  exportMnemonicReject,
  exportMnemonicValidate,
} from 'src/messages/walletProxy/methods/exportMnemonic/exportMnemonic';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const exportMnemonic: MethodImplementation = {
  validate: exportMnemonicValidate,
  prompt: exportMnemonicPrompt,
  execute: exportMnemonicExecute,
  reject: exportMnemonicReject,
};

export { exportMnemonic };
