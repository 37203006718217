import { SupportedEvmChain } from 'src/v2/evm/config';

export enum EvmMethods {
  GetBalance = 'evm_getBalance',
  SendTransaction = 'evm_sendTransaction',
  ChainId = 'evm_chainId',
  SwitchNetwork = 'evm_switchNetwork',
}

export enum EvmUnit {
  Wei = 'wei',
  Gwei = 'gwei',
  Ether = 'ether',
}

export interface MoonPayWalletsApiGetBalanceResponse {
  data: {
    balance: {
      value: string;
      unit: EvmUnit;
    };
  };
}

export interface MoonPayWalletsApiTransactionCountResponse {
  data: {
    count: number;
  };
}

export interface MoonPayWalletsApiSendTransactionResponse {
  data: {
    txHash: `0x${string}`;
  };
}

export interface EvmChainSpec {
  name: SupportedEvmChain;
  blockExplorerUrl?: string;
  blockGasLimit: number;
  chainId: number;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
}

export function toHexString(num: number): string {
  return `0x${num.toString(16)}`;
}
