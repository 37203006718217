import { Icon, Label, SendAssetIcon, Text } from '@moonpay-widget/ui-kit';
import { type FC } from 'react';
import WalletAddressComponent from '../../../components/WalletAddressComponent';
import { formatTransactionCost } from '../../../messages/walletProxy/methods/sendTransaction/utils';
import PromptFooter from '../../common/prompts/prompt-footer';
import PromptSheet from '../../common/prompts/prompt-sheet';

export interface SolanaTransferTransactionProps {
  fromAddress: string;
  toAddress: string;
  valueInSol: string;
  cryptocurrencyCode: string;
  onApprove: () => void;
  onReject: () => void;
}

export const SolanaTransferTransactionPrompt: FC<
  SolanaTransferTransactionProps
> = ({
  fromAddress,
  toAddress,
  valueInSol,
  cryptocurrencyCode,
  onApprove,
  onReject,
}) => {
  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={SendAssetIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          Send {cryptocurrencyCode}
        </Text>

        <WalletAddressComponent
          leftLabel="From"
          leftValue={fromAddress}
          rightLabel="To"
          rightValue={toAddress}
        />
        <div className="flex items-center justify-between">
          <Label>Summary</Label>
        </div>
        <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
          <Text as="div" variant="footnote" className="flex my-4 mb-5">
            <div className="float-left w-1/2 text-subhead font-light">
              Funds to be sent
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{`${formatTransactionCost(
              valueInSol,
              6,
            )}  ${cryptocurrencyCode}`}</div>
          </Text>
          <Text
            as="div"
            variant="footnote"
            className="flex my-4 border-b pb-4 border-divider-nonOpaque"
          >
            <div className="float-left w-1/2 text-subhead font-light">
              Blockchain fee
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">
              {'< $0.01'}
            </div>
          </Text>
          <Text variant="callout" as="div" className="flex my-4 mb-5">
            <div className="float-left w-1/2 font-semibold">
              Recipient will receive
            </div>
            <div className="float-right w-1/2 text-right font-semibold">{`${formatTransactionCost(
              valueInSol,
              6,
            )} ${cryptocurrencyCode}`}</div>
          </Text>
        </div>

        <PromptFooter
          approveButtonText="Confirm and send"
          cancelButtonText="Cancel"
          aboveButtonText="Please review the above before confirming as transactions cannot be reversed."
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
