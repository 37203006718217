import logger from '../../../utils/logger';
import { MoonPayWalletError, SecureWalletCommonErrors } from '../errors';
import environment from '../../../environment';

export type WalletsApiResponse<T> = T & {
  moonpayErrorCode?: string;
  error?: any;
};

export class MoonPayWalletsApiClient {
  private static readonly MOONPAY_WALLET_API_URL =
    environment.moonpayWalletApiUrl;

  public static async callApi<T>(
    method: 'GET' | 'POST',
    path: string,
    body?: BodyInit,
  ): Promise<WalletsApiResponse<T>> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const url = `${MoonPayWalletsApiClient.MOONPAY_WALLET_API_URL}${path}`;
    const res = await fetch(url, { method, headers, body });
    const response = await res.json();
    const responseStatus = res.status;

    if (responseStatus >= 500) {
      logger.error('MoonPay services wallets API request failed', {
        path,
        method,
        statusCode: res.status,
        response,
      });
      throw new MoonPayWalletError(
        SecureWalletCommonErrors.WALLET_API_FETCH_ERROR,
      );
    }

    logger.info(
      `MoonPay services wallets API response received for endpoint ${path}`,
      {
        path,
        method,
        statusCode: res.status,
        response,
      },
    );
    return response as WalletsApiResponse<T>;
  }
}
