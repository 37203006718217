import { WalletNetwork } from '@moonpay/login-common';
import { Keypair } from '@solana/web3.js';
import * as bip39 from 'bip39';
import { ethers } from 'ethers';
import { type Result } from 'src/types/Result';
import { ErrorManager } from 'src/utils/errorManager';
import { AbstractWallet, CryptoWallet } from 'src/wallet/types/Wallet';
import { getKmsWalletDetails } from 'src/wallet/walletProvider/kms/kmsApi';
import { HDKey } from '../../utils/hdKey';
import logger, { InSpan } from '../../utils/logger';

const errorManager = new ErrorManager(__filename);
const DEFAULT_LOCALE = 'en';
const i = 0;
const DEFAULT_PATH = `m/44'/501'/${i}'/0'`;

export class SolanaWallet implements CryptoWallet {
  @InSpan()
  public create(chainId: number): Result<AbstractWallet> {
    try {
      const { mnemonic } = ethers.Wallet.createRandom({
        path: DEFAULT_PATH,
        locale: DEFAULT_LOCALE,
      });

      const seed = bip39.mnemonicToSeedSync(mnemonic.phrase);
      const hd = HDKey.fromMasterSeed(seed.toString('hex'));
      const solWallet = Keypair.fromSeed(hd.derive(DEFAULT_PATH).privateKey);
      const address = solWallet.publicKey.toBase58();
      const publicKey = solWallet.publicKey.toString();

      if (!address) {
        throw errorManager.getServerError(
          'create',
          'Solana address not found during creation',
          {
            address,
            chainId,
            publicKey,
          },
        );
      }

      const wallet: AbstractWallet = {
        address,
        network: WalletNetwork.Solana,
        mnemonic,
        privateKey: solWallet.secretKey.toString(),
        publicKey,
        type: WalletNetwork.Solana,
        wallet: solWallet,
      };

      return {
        data: wallet,
      };
    } catch (error) {
      logger.error('Error occurred during Solana wallet creation', {
        error,
        chainId,
      });
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }

  @InSpan()
  public async createFromMnemonic(
    mnemonicPhrase: string,
    chainId: number,
  ): Promise<Result<AbstractWallet>> {
    try {
      const seed = bip39.mnemonicToSeedSync(mnemonicPhrase);
      let solWallet: Keypair;
      // get wallet details
      const kmsWalletDetailsArray = await getKmsWalletDetails();
      const solanaWallets = kmsWalletDetailsArray.filter(
        (detail) => detail.chain === 'solana',
      );
      const hd = HDKey.fromMasterSeed(seed.toString('hex'));

      // if there is no existing wallets, always derive from a child key with BIP84
      if (solanaWallets.length === 0) {
        logger.info('No existing solana wallets found. Creating a new one...');
        solWallet = Keypair.fromSeed(hd.derive(DEFAULT_PATH).privateKey);
      } else {
        // if there is an existing wallet (assuming there's only one)
        const solAddress = solanaWallets[0].address;
        if (!solAddress) {
          throw errorManager.getServerError(
            'createFromMnemonic',
            'Solana address not found',
          );
        }
        logger.info(`Solana address from existing address ${solAddress}`);
        solWallet = Keypair.fromSeed(hd.derive(DEFAULT_PATH).privateKey);
      }

      if (!solWallet) {
        throw errorManager.getServerError(
          'createFromMnemonic',
          'Failed to create wallet from mnemonic',
        );
      }

      const address = solWallet.publicKey.toBase58();
      logger.info(`Solana address from private keypair ${address}`);
      const publicKey = solWallet.publicKey.toString();

      if (!address) {
        throw errorManager.getServerError(
          'createFromMnemonic',
          'Solana address not found during creation',
          {
            address,
            chainId,
            publicKey,
          },
        );
      }

      const wallet: AbstractWallet = {
        address,
        network: WalletNetwork.Solana,
        mnemonic: {
          phrase: mnemonicPhrase,
        },
        privateKey: solWallet.secretKey.toString(),
        publicKey,
        type: WalletNetwork.Solana,
        wallet: solWallet,
      };
      return {
        data: wallet,
      };
    } catch (error) {
      logger.error(
        'Error occurred during Solana wallet creation from mnemonic',
        {
          error,
          chainId,
        },
      );
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }
}
