import { WalletNetwork } from '@moonpay/login-common';
import {
  BitcoinNetworkEnvironment,
  BitcoinNetworkEnvironmentToChainIdMap,
} from 'src/v2/bitcoin/types';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

export class BitcoinNetworkEnvironmentManager {
  private readonly network = WalletNetwork.Bitcoin;

  constructor(private readonly walletStorage: WalletStorage) {}

  public getChainIdByNetworkEnvironment(
    networkEnvironment: BitcoinNetworkEnvironment,
  ): number {
    return BitcoinNetworkEnvironmentToChainIdMap[networkEnvironment];
  }

  public updateActiveChainId(
    networkEnvironment: BitcoinNetworkEnvironment,
  ): number {
    const chainId = this.getChainIdByNetworkEnvironment(networkEnvironment);
    this.walletStorage.activeChainId.setActiveChainIdByNetwork(
      this.network,
      chainId,
    );

    return chainId;
  }

  public getActiveChainId(): number {
    return this.walletStorage.activeChainId.getActiveChainIdByNetwork(
      this.network,
    );
  }

  public getActiveNetworkEnvironment(
    defaultNetworkEnvironment: BitcoinNetworkEnvironment = BitcoinNetworkEnvironment.Testnet,
  ): BitcoinNetworkEnvironment {
    const activeChainId = this.getActiveChainId();
    const networkEnvironment = (
      Object.keys(
        BitcoinNetworkEnvironmentToChainIdMap,
      ) as BitcoinNetworkEnvironment[]
    ).find(
      (env) => BitcoinNetworkEnvironmentToChainIdMap[env] === activeChainId,
    );
    return networkEnvironment ?? defaultNetworkEnvironment;
  }
}
