import { FillNFTOrderIcon } from '@moonpay-widget/ui-kit';
import {
  ShareIcon,
  ShoppingBagIcon,
} from '@moonpay-widget/ui-kit/src/PromptIcons';
import { Wallet, ethers } from 'ethers';
import GenericNftSendTransaction from '../components/GenericNftSendTransaction';
import NftApproval from '../components/NftApproval';
import NftPurchase from '../components/NftPurchase';
import { HmErc721TransactionType, getHmErc721Action } from '../hmErc721';
import { getNftApproval } from '../nftApproval';
import { decodeSeaportTx, isOpenseaContractAddress } from '../seaport/utils';
import { isSafeTransferFrom, isTargetNFT } from '../sendNFT';
import { formatValueEth } from '../utils';
import type { TransactionRequest } from '../utils';
import type { SendTransactionPromptReturnType } from './types';

export const getNftTransactionPrompt = async ({
  data,
  to,
  networkFee,
  currencyCode,
  wallet,
  transaction,
  value,
}: {
  data: string;
  networkFee: string;
  to: string;
  currencyCode: string;
  wallet: Wallet;
  transaction: TransactionRequest;
  value: ethers.BigNumber;
}): Promise<SendTransactionPromptReturnType | null> => {
  const erc721Action = await getHmErc721Action(wallet, transaction);
  if (erc721Action) {
    if (erc721Action.type === HmErc721TransactionType.BUY) {
      const nftName = 'HyperMint NFT';

      // note: price is the native currency of the chain
      // and its _amount * pricePerToken
      const price = formatValueEth(value);

      const props = {
        nfts: [
          {
            name: nftName,
            price: Number.parseFloat(price),
          },
        ],
        networkFee,
        currencyCode,
      };

      return {
        title: 'Buy HyperMint NFT',
        partnerLogo: ShoppingBagIcon,
        component: <NftPurchase {...props} />,
        aboveButtonsText:
          'Only confirm if you fully understand and trust the requesting site',
      };
    }
  }

  // TODO: extend this for other nft contract interactions
  const isNftPurchase = isOpenseaContractAddress(to);
  // TODO: need to figure out how it is a sell or  purchase
  if (isNftPurchase) {
    // TODO: need to figure out how it is a sell or  purchase
    const decodedTx = decodeSeaportTx(data, to);
    if (decodedTx) {
      const { nftName, price } = decodedTx;
      const props = {
        nfts: [
          {
            name: nftName,
            price: Number.parseFloat(price),
          },
        ],
        networkFee,
        currencyCode,
      };
      return {
        title: 'Purchase NFT',
        partnerLogo: FillNFTOrderIcon,
        component: <NftPurchase {...props} />,
        aboveButtonsText:
          'Only confirm if you fully understand and trust the requesting site',
      };
    }
  }

  const isNFT = await isTargetNFT(wallet, transaction);
  if (isNFT) {
    const nftApproval = await getNftApproval(wallet, transaction);
    if (nftApproval) {
      const props = {
        currencyCode,
        networkFee,
        contractAddress: nftApproval.spender,
        nftName: nftApproval.name,
        nftContractAddress: transaction.to!,
        to: nftApproval.spender,
        tokenId: nftApproval.tokenId,
      };

      return {
        title: `Allow access to your ${nftApproval.name}`,
        message: `Allow access to your ${nftApproval.name} collection?`,
        component: <NftApproval {...props} />,
        aboveButtonsText:
          'Only if you fully understand and trust the requesting site',
      };
    }

    const nftTransfer = await isSafeTransferFrom(wallet, transaction);
    if (nftTransfer) {
      const props = {
        networkFee,
        currencyCode,
        to: nftTransfer.to,
        from: nftTransfer.from,
        nfts: nftTransfer.nfts,
      };

      return {
        title: 'Send NFT',
        partnerLogo: ShareIcon,
        message:
          'By approving this transaction, you are sending the following NFTs to the wallet address below.',
        component: <GenericNftSendTransaction {...props} />,
        aboveButtonsText:
          'Please review the above before confirming as transactions cannot be reversed.',
      };
    }
  }
  return null;
};
