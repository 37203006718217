import { WalletNetwork } from '@moonpay/login-common';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import {
  WalletConnectionInput,
  setWalletConnection,
} from 'src/wallet/walletProvider/kms/kmsApi';

export class SolanaWalletConnectionService {
  private readonly network = WalletNetwork.Solana;

  constructor(private readonly walletStorage: WalletStorage) {}

  public async storeApprovedConnection(walletAddress: string) {
    await this.walletStorage.connections.updateWalletConnection({
      address: walletAddress,
      chainId: this.walletStorage.activeChainId.getActiveChainIdByNetwork(
        this.network,
      ),
      origin,
      value: true,
      network: this.network,
    });

    const { searchParams } = new URL(window.location.href);
    const { apiKey } = WalletHelpers.parseQueryParams(searchParams);

    const walletConnection: WalletConnectionInput = { walletAddress };
    await setWalletConnection(apiKey, walletConnection);
  }

  public async storeRejectedConnection(walletAddress: string) {
    await this.walletStorage.connections.updateWalletConnection({
      address: walletAddress,
      chainId: this.walletStorage.activeChainId.getActiveChainIdByNetwork(
        this.network,
      ),
      origin,
      value: false,
      network: this.network,
    });
  }
}
