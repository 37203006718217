import { Label, Text } from '@moonpay-widget/ui-kit';
import { FC } from 'react';
import WalletAddressComponent from 'src/components/WalletAddressComponent';

export type NftApprovalProps = {
  to: string;
  contractAddress: string;
  nftName: string;
  nftContractAddress: string;
  tokenId?: number;
  networkFee: string;
  currencyCode: string;
};

const NftApproval: FC<NftApprovalProps> = ({
  to,
  contractAddress,
  nftName,
  nftContractAddress,
  tokenId,
  networkFee,
  currencyCode,
}) => {
  return (
    <div className="mt-4">
      <WalletAddressComponent
        leftLabel="Granted to"
        leftValue={to}
        rightLabel="Approved asset(s)"
        rightValue={`All ${nftName}`}
      />
      <div className="flex mt-8 items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xl overflow-auto">
        <Text
          variant="footnote"
          as="div"
          className="flex my-4 border-b pb-4 border-divider-nonOpaque"
        >
          <div className="float-left w-1/2">Blockchain fee</div>
          <div className="float-right w-1/2 text-right">{`${
            networkFee || '0'
          } ${currencyCode}`}</div>
        </Text>
        <Text variant="subhead" as="div" className="flex my-4">
          <div className="float-left w-1/2 font-bold">Total cost</div>
          <div className="float-right w-1/2 text-right font-bold">{`${networkFee} ${currencyCode}`}</div>
        </Text>
      </div>
    </div>
  );
};

export default NftApproval;
