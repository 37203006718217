import ecc from '@bitcoinerlab/secp256k1';
import { WalletNetwork } from '@moonpay/login-common';
import axios, { AxiosError } from 'axios';
import BIP32Factory, { BIP32Interface } from 'bip32';
import * as bip39 from 'bip39';
import * as bitcoin from 'bitcoinjs-lib';
import { ECPairFactory, ECPairInterface } from 'ecpair';
import { ErrorManager } from 'src/utils/errorManager';
import { AbstractWallet } from 'src/wallet/types/Wallet';
import logger from '../../../../../utils/logger';

const errorManager = new ErrorManager(__filename);
const cryptoApiBaseUrl = process.env.REACT_APP_CRYPTO_API_URL || '';

export interface Utxo {
  txid: string;
  vout: number;
  value: number;
  height: number;
  confirmations: number;
  hex: string;
}

export type FeePriceDetails = {
  nativeCryptoFee: string;
  nativeCryptoPriorityFee?: string; // Only applies to EVM
  nativeCryptoUnits: string;
  fiatAmount: string;
  fiatCode: string;
  estimateInMs: number;
  gasLimit?: string; // Only applies to EVM
};

export type FeePriceResponse = {
  slow: FeePriceDetails;
  medium: FeePriceDetails;
  fast: FeePriceDetails;
};

export interface UtxoResponse {
  utxos: Utxo[];
}

export enum FeeEstimatorMode {
  FAST = 'fast',
  MEDIUM = 'medium',
  SLOW = 'slow',
}

export function deriveWallets(
  addressToMatch: string,
  seed: Buffer,
): BIP32Interface {
  const isMainnet = addressToMatch.startsWith('bc');
  const chainId = isMainnet ? 0 : 1;
  const network = isMainnet
    ? bitcoin.networks.bitcoin
    : bitcoin.networks.testnet;

  const bip32 = BIP32Factory(ecc);
  // recover from master extended key
  const rootBtcWallet = bip32.fromSeed(seed);
  const addressFromExtendedKey = bitcoin.payments.p2wpkh({
    pubkey: rootBtcWallet.publicKey,
    network,
  }).address;

  // This is only possible if a wallet was created during a set time period after this PR was merged https://github.com/moonpay/moonpay-buy/pull/7183/files
  // We have to introduce this extra check as part of a support issue where customers had wallets generated with a different derivation path
  // https://linear.app/moonpay/issue/WEB3-852/retry-requested-consumer-web-send-crypto-gbp-currency
  const btcWalletFromBip44Derivation = bip32
    .fromSeed(seed)
    .derivePath("m/44'/0'/0'/0/0");

  const addressFromBip44Derivation = bitcoin.payments.p2wpkh({
    pubkey: btcWalletFromBip44Derivation.publicKey,
    network,
  }).address;

  // recover from child key derived via BIP84
  const btcWalletFromBip84Derivation = bip32
    .fromSeed(seed)
    .derivePath("m/84'/0'/0'/0/0");
  const addressFromBip84Derivation = bitcoin.payments.p2wpkh({
    pubkey: btcWalletFromBip84Derivation.publicKey,
    network,
  }).address;

  let btcWallet: BIP32Interface;
  if (addressToMatch === addressFromExtendedKey) {
    btcWallet = rootBtcWallet;
  } else if (addressToMatch === addressFromBip44Derivation) {
    btcWallet = btcWalletFromBip44Derivation;
  } else if (addressToMatch === addressFromBip84Derivation) {
    btcWallet = btcWalletFromBip84Derivation;
  } else {
    throw errorManager.getServerError(
      'getSigner',
      `Chain id: ${chainId}
      Failed to identify wallet address format for ${addressToMatch}
      root: ${addressFromExtendedKey}, bip84: ${addressFromBip84Derivation}, bip44: ${addressFromBip44Derivation}`,
    );
  }

  return btcWallet;
}

function getBitcoinWalletSigner(
  chainId: number,
  wallet: AbstractWallet,
): ECPairInterface {
  const seed = bip39.mnemonicToSeedSync(wallet.mnemonic.phrase);
  const btcWallet = deriveWallets(wallet.address, seed);

  if (!btcWallet?.privateKey) {
    throw errorManager.getServerError('getSigner', `Private key not found`);
  }

  const ECPair = ECPairFactory(ecc);
  return ECPair.fromPrivateKey(btcWallet.privateKey, {
    network:
      chainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
  });
}

const BTC_DUST_LIMIT = 546;

async function sendRawBtcTransaction(
  rawTransaction: string,
  chainId: number,
  sourceWalletAddress?: string,
): Promise<string> {
  try {
    const url = new URL(`${cryptoApiBaseUrl}/api/v1/transfer`);

    const response = await axios.post<string>(url.toString(), {
      chain: WalletNetwork.Bitcoin,
      network: chainId === 0 ? 'mainnet' : 'testnet',
      transactionData: rawTransaction,
      sourceWalletAddress,
    });

    logger.info(`Succeeded in sending raw BTC transaction: ${response.data}`, {
      sourceWalletAddress,
      chainId,
    });

    return response.data;
  } catch (error: any) {
    logger.error('Failed to send raw BTC transaction', {}, error);
    if (error.response?.status === 422) {
      throw errorManager.getWarnError(
        'sendRawBtcTransaction',
        `Failed to send raw BTC transaction: ${
          error.response?.data?.error || error.message
        }`,
        {
          sourceWalletAddress,
          chainId,
        },
      );
    }
    throw errorManager.getServerError(
      'sendRawBtcTransaction',
      `Failed to send raw BTC transaction: ${
        error.response?.data?.error || error.message
      }`,
      {
        sourceWalletAddress,
        chainId,
      },
    );
  }
}

function craftBtcTransactionIO(
  inputs: Utxo[],
  change: number,
  toAddress: string,
  amount: number,
  abstractWallet: AbstractWallet,
  activeChainId: number,
): bitcoin.Psbt {
  const outputs = [{ address: toAddress, value: amount }];

  if (change >= BTC_DUST_LIMIT) {
    outputs.push({ address: abstractWallet.address, value: change });
  }

  const psbt = new bitcoin.Psbt({
    network:
      activeChainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
  });

  inputs.forEach((input) => {
    psbt.addInput({
      hash: input.txid,
      index: input.vout,
      nonWitnessUtxo: Buffer.from(input.hex, 'hex'),
    });
  });

  outputs.forEach((output) => {
    psbt.addOutput({
      address: output.address,
      value: output.value,
    });
  });

  return psbt;
}

function createBtcTransaction(
  inputs: Utxo[],
  change: number,
  toAddress: string,
  amount: number,
  abstractWallet: AbstractWallet,
  activeChainId: number,
) {
  const partiallySignedBitcoinTransactionInternal = craftBtcTransactionIO(
    inputs,
    change,
    toAddress,
    amount,
    abstractWallet,
    activeChainId,
  );
  const wallet = getBitcoinWalletSigner(activeChainId, abstractWallet);
  partiallySignedBitcoinTransactionInternal.signAllInputs(wallet);
  partiallySignedBitcoinTransactionInternal.finalizeAllInputs();
  return partiallySignedBitcoinTransactionInternal.extractTransaction().toHex();
}

async function getBtcUtxos(
  address: string,
  chainId: number,
): Promise<UtxoResponse> {
  const url = new URL(`${cryptoApiBaseUrl}/api/v1/utxos`);
  url.searchParams.append('address', address);
  url.searchParams.append('chain', WalletNetwork.Bitcoin);
  url.searchParams.append('network', chainId === 0 ? 'mainnet' : 'testnet');

  try {
    const response = await axios.get<UtxoResponse>(url.toString());
    return response.data;
  } catch (error) {
    // throw here as no transactions can be made without UTXOs
    throw errorManager.getServerError(
      'getBtcUtxos',
      `Failed to fetch UTXOs for address ${address} on ${
        chainId === 0 ? 'mainnet' : 'testnet'
      }`,
    );
  }
}

function selectUtxos(
  utxos: Utxo[],
  amount: number,
  chainId: number,
  networkFeeInSats: number,
): { inputs: Utxo[]; change: number; networkFeeInSatoshis: number } {
  logger.info(`Selecting UTXOs for amount ${amount}`, {
    utxos,
    amount,
    chainId,
  });

  let inputSum = 0;
  const selectedUtxos = [];

  utxos = utxos.sort((a, b) => b.value - a.value);

  for (const utxo of utxos) {
    if (inputSum >= amount + networkFeeInSats) {
      break;
    }

    selectedUtxos.push(utxo);
    inputSum += Number(utxo.value);
  }

  logger.info(`Calculated Input Sum: ${inputSum}`, {
    selectedUtxos,
  });

  if (inputSum < amount + networkFeeInSats) {
    throw errorManager.getClientError(
      'selectUtxos',
      `Insufficient funds for the transaction`,
    );
  }

  return {
    inputs: selectedUtxos,
    change: inputSum - amount - networkFeeInSats,
    networkFeeInSatoshis: networkFeeInSats,
  };
}

async function fetchEstimatedSmartFeeByMode(
  from: string,
  amountInSatoshis: number,
  chainId: number,
) {
  const url = new URL(`${cryptoApiBaseUrl}/api/v1/fee-price`);
  url.searchParams.append('chain', WalletNetwork.Bitcoin);
  url.searchParams.append('network', chainId === 0 ? 'mainnet' : 'testnet');
  url.searchParams.append('from', from);
  url.searchParams.append('value', amountInSatoshis.toString());

  try {
    const response = await axios.get<FeePriceResponse>(url.toString());
    return response.data;
  } catch (e) {
    const error = e as AxiosError<{ error: string }>;
    throw errorManager.getServerError(
      'estimateSmartFee',
      `Failed to get fee rate: ${error.response?.data?.error || error}`,
      {
        error,
        url: url.toString(),
        chainId,
        from,
        value: amountInSatoshis.toString(),
      },
    );
  }
}

export {
  craftBtcTransactionIO,
  createBtcTransaction,
  fetchEstimatedSmartFeeByMode,
  getBtcUtxos,
  selectUtxos,
  sendRawBtcTransaction,
  getBitcoinWalletSigner,
};
