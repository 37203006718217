import { WalletNetwork } from '@moonpay/login-common';
import logger from 'src/utils/logger';
import {
  MESSAGE_HANDLER_METADATA_KEY,
  MESSAGE_REQUEST_METADATA_KEY,
} from 'src/v2/lib/constants';
import { ControllerMethod } from 'src/v2/lib/types';
import { sendMoonPayWalletResponse } from 'src/v2/lib/utils/response-utils';
import { WalletService } from '../../../wallet/services/walletService';
import { WalletStorage } from '../../../wallet/storage/WalletStorage';
import { AbstractWallet } from '../../../wallet/types/Wallet';
import {
  MoonPayWalletError,
  SecureWalletCommonErrors,
} from '../../common/errors';
import { CommonControllerMethods } from '../../common/types';
import { requestHasBody } from '../utils';

function getWalletNetworkFromMethod(methodName: string) {
  const prefixNetworkMapping: Record<string, WalletNetwork> = {
    btc: WalletNetwork.Bitcoin,
    eth: WalletNetwork.Ethereum,
    evm: WalletNetwork.Ethereum,
    sol: WalletNetwork.Solana,
  };
  const prefix = methodName.split('_')[0];
  const network = prefixNetworkMapping[prefix];
  if (!network) {
    logger.error(`Unsupported wallet network for method: ${methodName}`);
    throw new MoonPayWalletError(SecureWalletCommonErrors.WALLET_RESTORE_ERROR);
  }
  return network;
}

export async function getWalletFromMethod(
  methodName: string,
  walletStorage: WalletStorage,
) {
  const network = getWalletNetworkFromMethod(methodName);
  let wallet: AbstractWallet | undefined;

  // We only use mnemonic injection for local testing and development, this is very dangerous in production- please don't do it
  const url: URL = new URL(window.location.href);
  const params = url.searchParams;
  const mnemonic = params.get('mnemonic');
  if (mnemonic) {
    wallet = (
      await WalletService.cryptoWalletFactory(network).createFromMnemonic(
        mnemonic,
        walletStorage.activeChainId.getActiveChainIdByNetwork(network),
      )
    ).data;
  } else {
    wallet = await WalletService.restoreWallet(network, walletStorage);
  }

  if (!wallet) {
    throw new MoonPayWalletError(SecureWalletCommonErrors.WALLET_RESTORE_ERROR);
  }
  return wallet;
}

export function Controller() {
  const basePath = 'moonpay-wallet';
  return function (target: any) {
    window.addEventListener('message', (event) => {
      if (event.data.type !== basePath) return;

      // TODO: maybe we could add some validation to give some better feedback to implementors
      // e.g. if an invalid request method is sent we should throw a descriptive error back
      const requestBody = event.data;

      const requestMethod = requestBody?.request?.method;
      const payload = requestBody?.request?.params;

      const methods: ControllerMethod[] =
        Reflect.getMetadata(MESSAGE_HANDLER_METADATA_KEY, target.prototype) ??
        [];

      if (requestMethod) {
        methods.forEach(async (methodData) => {
          if (methodData.method === requestMethod) {
            logger.info('V2 Controller received message:', {
              requestBody,
              requestMethod,
              controller: target.name,
            });

            const walletStorage = new WalletStorage();

            // eslint-disable-next-line new-cap
            const instance = new target(walletStorage);
            const method = instance[methodData.methodName].bind(instance);

            Reflect.defineMetadata(
              MESSAGE_REQUEST_METADATA_KEY,
              requestBody,
              target.prototype,
              methodData.methodName,
            );

            const hasBody = requestHasBody(instance, methodData.methodName);
            const body = Array.isArray(payload) ? payload[0] : payload;

            if (requestMethod === CommonControllerMethods.GetWallets) {
              method(hasBody ? body : undefined);
            } else {
              try {
                const wallet = await getWalletFromMethod(
                  requestMethod,
                  walletStorage,
                );
                method(wallet, hasBody ? body : undefined);
              } catch (e) {
                const handler = {
                  target: target.prototype,
                  propertyKey: methodData.methodName,
                };
                sendMoonPayWalletResponse({
                  error: SecureWalletCommonErrors.WALLET_RESTORE_ERROR,
                  handler,
                });
              }
            }
          }
        });
      }
    });
  };
}
