import { useState } from 'react';
import { ActivityDetails } from './ActivityDetails';
import { CryptoDetails } from './CryptoDetails';
import { NftDetails } from './NftDetails';

export const Bottom = ({ balancesWithLogo }: any) => {
  const [currentView, setCurrentView] = useState('crypto');

  const handleTitleClick = (view: any) => {
    setCurrentView(view);
  };

  return (
    <div>
      <div
        className="flex space-x-4 mb-4"
        style={{ fontWeight: 800, fontSize: 18, marginTop: 4 }}
      >
        <div
          onClick={() => handleTitleClick('crypto')}
          style={{
            cursor: 'pointer',
            color: currentView === 'crypto' ? 'black' : '#999',
          }}
        >
          Crypto
        </div>
        <div
          onClick={() => handleTitleClick('nfts')}
          style={{
            cursor: 'pointer',
            color: currentView === 'nfts' ? 'black' : '#999',
          }}
        >
          NFTs
        </div>
        <div
          onClick={() => handleTitleClick('activity')}
          style={{
            cursor: 'pointer',
            color: currentView === 'activity' ? 'black' : '#999',
          }}
        >
          Activity
        </div>
      </div>

      {/* Render corresponding view based on the currentView state */}
      {currentView === 'crypto' && (
        <CryptoDetails balancesWithLogo={balancesWithLogo} />
      )}
      {currentView === 'nfts' && <NftDetails />}
      {currentView === 'activity' && <ActivityDetails />}
    </div>
  );
};
