import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
} from '@moonpay/login-common';
import { ethers, Wallet } from 'ethers';

function validateWalletSigner(wallet: Wallet, signer: string) {
  // Check that signer and wallet.address match
  const ethSigner = ethers.utils.getAddress(signer);
  if (wallet.address !== ethSigner) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.Unauthorized,
      `The requested account and/or method has not been authorized by the user.`,
    );
  }
}

export { validateWalletSigner };
