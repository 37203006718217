import { SendAssetIcon } from '@moonpay-widget/ui-kit';
import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  WalletNetwork,
} from '@moonpay/login-common';
import { BigNumber } from 'ethers';
import { getErc20Action } from 'src/messages/walletProxy/methods/sendTransaction/erc20';
import { formatValue } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import type { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import GenericSendTransaction from './components/GenericSendTransaction';
import { estimateGas } from './networkFeeCalculators/networkFeeCalculator';
import { erc20TransactionPrompt } from './sendTransactionPrompts/erc20TransactionPrompt';
import { getNftTransactionPrompt } from './sendTransactionPrompts/nftTransactionPrompts';
import type { SendTransactionPromptReturnType } from './sendTransactionPrompts/types';
import { executeBtcTransaction } from './transactionExecutors/btcExecutor';
import { executeEvmTransaction } from './transactionExecutors/evmExecutor';

const errorManager = new ErrorManager(__filename);

export async function sendTransactionExecute({
  request,
  wallet,
  network,
  abstractWallet,
  walletStorage,
  origin,
}: PromptRequest) {
  const unsignedTransaction = request.params![0];
  // remove `from` and `gas` from unsigned transaction as they will
  // be populated by `walletObj.populateTransaction` via the signer
  // and the `gas` will be calculated incorrectly. If these fields
  // are not removed an error will be thrown by populateTransaction.

  try {
    switch (network) {
      case WalletNetwork.Ethereum:
        return await executeEvmTransaction({
          unsignedTransaction,
          network,
          wallet,
          abstractWallet,
        });
      case WalletNetwork.Bitcoin:
        return await executeBtcTransaction({
          unsignedTransaction,
          walletStorage,
          network,
          abstractWallet,
          origin,
          wallet,
        });
      default:
        throw errorManager.getServerError(
          'sendTransactionExecute',
          `Invalid network`,
        );
    }
  } catch (err: any) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      err.message,
    );
  }
}

export async function sendTransactionReject(req: PromptRequest): Promise<void> {
  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.approveTransactionCancelled,
    {
      walletAddress: req.abstractWallet.address,
      walletNetwork: req.network,
    },
  );
}

export async function sendTransactionPrompt({
  request,
  wallet,
  walletStorage,
  network,
  abstractWallet,
}: PromptRequest): Promise<SendTransactionPromptReturnType> {
  const chainId =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);
  const chain = walletStorage.chains.getChain(chainId.toString(), network);
  if (!chain) {
    throw errorManager.getServerError(
      'sendTransactionPrompt',
      `Invalid chainId`,
      { chain, address: abstractWallet.address },
    );
  }
  const currencyCode = chain.nativeCurrency.symbol;

  const transaction = request.params![0];
  const { value, data, to, from } = transaction;

  const networkFee = await estimateGas(
    transaction,
    network,
    wallet,
    request,
    abstractWallet,
    chainId,
  );

  const erc20Action = await getErc20Action(wallet, transaction);

  if (erc20Action) {
    const prompt = erc20TransactionPrompt(erc20Action, {
      networkFee,
      nativeCurrencySymbol: chain.nativeCurrency.symbol,
    });
    if (prompt) {
      return prompt;
    }
  }

  const prompt = await getNftTransactionPrompt({
    data,
    to,
    networkFee,
    currencyCode,
    wallet,
    value,
    transaction,
  });

  if (prompt) {
    return prompt;
  }

  // if all else fails above, show generic send transaction
  const props = {
    currencyCode,
    value: formatValue(value || BigNumber.from(0), network),
    networkFee,
    from,
    to,
    data,
  };
  return {
    title: `Send ${currencyCode}`,
    partnerLogo: SendAssetIcon,
    component: <GenericSendTransaction {...props} />,
    aboveButtonsText:
      'Please review the above before confirming as transactions cannot be reversed.',
    approveButtonText: 'Confirm and send',
  };
}
