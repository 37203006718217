import { Icon, Label, SendAssetIcon, Text } from '@moonpay-widget/ui-kit';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import WalletAddressComponent from 'src/components/WalletAddressComponent';
import { strings } from 'src/i18n';
import { formatTransactionCost } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import PromptFooter from 'src/v2/common/prompts/prompt-footer';
import PromptSheet from 'src/v2/common/prompts/prompt-sheet';
import { EvmChainSpec } from 'src/v2/evm/types';

const EVM_DISPLAY_DECIMALS = 8;

export interface EvmSendTransactionProps {
  from: `0x${string}`;
  to: `0x${string}`;
  value: bigint;
  networkFee: bigint;
  chain: EvmChainSpec;
  onApprove: () => void;
  onReject: () => void;
}

export const EvmSendTransactionPrompt: FC<EvmSendTransactionProps> = ({
  to,
  from,
  value,
  networkFee,
  chain,
  onApprove,
  onReject,
}) => {
  const { t } = useTranslation();
  const currencyCode = chain.nativeCurrency.symbol;

  const totalTransactionCost = value + networkFee;

  const formatted = (wei: bigint) =>
    Number(wei) / 10 ** chain.nativeCurrency.decimals;

  const formattedValue = formatted(value);
  const formattedNetworkFee = formatted(networkFee);
  const formattedTotalTransactionCost = formatted(totalTransactionCost);

  return (
    <PromptSheet>
      <div className="px-4 mt-4 mb-7 justify-top">
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon icon={SendAssetIcon} className="w-16 h-16 rounded-xl" />
        </div>

        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {t(strings.prompts.sendTransaction.send)} {currencyCode}
        </Text>

        <WalletAddressComponent
          leftLabel={t(strings.prompts.sendTransaction.from)}
          leftValue={from}
          rightLabel={t(strings.prompts.sendTransaction.to)}
          rightValue={to}
        />
        <div className="flex items-center justify-between">
          <Label>{t(strings.prompts.sendTransaction.summary)}</Label>
        </div>
        <div className="px-4 mb-2 bg-system-background-secondary rounded-xxl overflow-auto">
          <Text as="div" variant="footnote" className="flex my-4 mb-5">
            <div className="float-left w-1/2 text-subhead font-light">
              {t(strings.prompts.sendTransaction.fundsToBeSent)}
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{`${formattedValue} ${currencyCode}`}</div>
          </Text>
          <Text
            as="div"
            variant="footnote"
            className="flex my-4 border-b pb-4 border-divider-nonOpaque"
          >
            <div className="float-left w-1/2 text-subhead font-light">
              {t(strings.prompts.sendTransaction.blockchainFee)}
            </div>
            <div className="float-right w-1/2 text-right text-subhead font-light">{` ${formatTransactionCost(
              formattedNetworkFee,
              EVM_DISPLAY_DECIMALS,
            )} ${currencyCode}`}</div>
          </Text>
          <Text variant="callout" as="div" className="flex my-4 mb-5">
            <div className="float-left w-1/2 font-semibold">
              {t(strings.prompts.sendTransaction.totalTransactionCost)}
            </div>
            <div className="float-right w-1/2 text-right font-semibold">{`${formatTransactionCost(
              formattedTotalTransactionCost,
              EVM_DISPLAY_DECIMALS,
            )} ${currencyCode}`}</div>
          </Text>
        </div>

        <PromptFooter
          approveButtonText={t(
            strings.prompts.sendTransaction.footer.approvalButtonText,
          )}
          cancelButtonText={t(
            strings.prompts.sendTransaction.footer.cancelButtonText,
          )}
          aboveButtonText={t(strings.prompts.sendTransaction.footer.title)}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PromptSheet>
  );
};
