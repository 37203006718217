import { ConnectionsStorage } from 'src/wallet/storage/ConnectionsStorage';

/**
 * This is eventually throwaway. We need this as v2 initalises an instance of ConnectionStorage
 * and v1 initalises an instance of ConnectionsStorage. There is no 2 way data binding to local storage.
 * ConnectionStorage only knows about the values in its own state. Long term I would like to move to
 * 2 way data binding against localstorage so the class state is always consistent with localstorage.
 */
export class ConnectionStorageFactory {
  private static instance: ConnectionsStorage | null = null;

  public static create() {
    return new ConnectionsStorage();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = this.create();
    }

    return this.instance;
  }
}
