import { WalletNetwork, WalletNetworkToSymbolMap } from '@moonpay/login-common';
import { ErrorManager } from '../../../../utils/errorManager';
import { PromptRequest } from '../types';
import { sendTransactionValidateEvm } from './transactionValidators/evmValidate';
import { sendTransactionValidateBtc } from './transactionValidators/btcValidate';

const errorManager = new ErrorManager(__filename);

export async function sendTransactionValidate({
  request,
  network,
  walletStorage,
}: PromptRequest) {
  const networkSymbol = WalletNetworkToSymbolMap[network];

  if (request.method !== `${networkSymbol}_sendTransaction`) {
    throw errorManager.getClientError(
      'sendTransactionValidate',
      `Invalid request method`,
    );
  }
  if (!request.params || !Array.isArray(request.params)) {
    throw errorManager.getClientError(
      'sendTransactionValidate',
      `Invalid request params`,
    );
  }
  if (request.params.length !== 1) {
    throw errorManager.getClientError(
      'sendTransactionValidate',
      `Invalid request params length`,
    );
  }
  if (typeof request.params[0] !== 'object') {
    throw errorManager.getClientError(
      'sendTransactionValidate',
      `Invalid request params[0] type, must be an object`,
    );
  }

  // ensure that from and to are valid addresses
  const { from, to } = request.params[0];
  switch (network) {
    case WalletNetwork.Ethereum:
      sendTransactionValidateEvm(request, from, to);
      break;
    case WalletNetwork.Bitcoin:
      sendTransactionValidateBtc(request, walletStorage, network, to);
      break;
    default:
      throw errorManager.getServerError(
        'sendTransactionValidate',
        `Invalid network`,
      );
  }
}
