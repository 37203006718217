import { Expose } from 'class-transformer';
import { SolanaNetworkEnvironment } from 'src/v2/solana/types';

export interface ISolanaSwitchNetworkResponse {
  networkEnvironment: SolanaNetworkEnvironment;
}

export class SolanaSwitchNetworkResponse {
  @Expose()
  public readonly networkEnvironment!: SolanaNetworkEnvironment;

  constructor(data: Partial<ISolanaSwitchNetworkResponse>) {
    Object.assign(this, data);
  }
}
