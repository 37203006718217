import { WalletApiBitcoinBalanceResponse } from 'src/v2/bitcoin/types';
import { MoonPayWalletsApiClient } from 'src/v2/common/clients/moonpay-wallets-api.client';
import { BitcoinWalletErrors, MoonPayWalletError } from 'src/v2/common/errors';

export class BitcoinApiService {
  public async getBalance({
    chainName,
    address,
  }: {
    chainName: string;
    address: string;
  }) {
    const response =
      await MoonPayWalletsApiClient.callApi<WalletApiBitcoinBalanceResponse>(
        'GET',
        `/v1/bitcoin/${chainName}/balance?walletAddress=${address}`,
      );

    if (!response.data || response.error || response.moonpayErrorCode) {
      throw new MoonPayWalletError(
        BitcoinWalletErrors.BITCOIN_WALLET_API_BALANCE_ERROR,
      );
    }

    return {
      value: response.data.balance.value,
      unit: response.data.balance.unit,
    };
  }
}
